import { useHistory } from "react-router-dom";
import { getItemData } from "../Hooks/useItemParams"; 
import { Link } from "react-router-dom";
import { isLogin, logout } from "../constants/sessionHelper";
import { message, notification } from "antd";

const AppFooter = () => {
    const history=useHistory();
    const getCategoryItems = (category_id) => {
        let itmData = getItemData();
        itmData.category_id = category_id;
        itmData.is_paid = "paid_item_first";
        // is_paid
        history.push({pathname:"products",itemData:itmData});
    }
    return ( 
        <div>
             <footer className="footer">
                <div className="cont footer-top">

                    <div className="footer-menu">
                        <p>Categoria</p>
                        <ul>
                            <li><a onClick={()=>{getCategoryItems(1);}}>Carros</a></li>
                            <li><a onClick={()=>{getCategoryItems(5);}}>Motocicletas</a></li>
                            <li><a onClick={()=>{getCategoryItems(7);}}>Barcos</a></li>
                            <li><a onClick={()=>{getCategoryItems(10);}}>Outras</a></li>
                        </ul>
                    </div>
                    <div className="footer-menu">
                        <p>Em formação</p>
                        <ul>
                            <li><Link to="/">Casa</Link></li>
                            <li><Link to="/products">Produtos</Link></li>
                            <li><Link to="/add_item">Adicionar Item</Link></li>
                            <li><Link to="/search_item">Pesquisar item</Link></li>
                        </ul>
                    </div>
                    {/* <div className="footer-menu">
                        <p>Conta e pedidos</p>
                        <ul>
                        <li><Link to="/chats">Chats</Link></li>
                            <li><Link to="/profile">Profile</Link></li>
                            {isLogin()&&<li>
                                <a onClick={() => {
                                    let res = logout();
                                    if (res) {
                                        notification.info({ message: "Success", description: "Logged out successfully" })
                                        history.replace('/');
                                    }

                                }} >Log out</a>
                            </li>}
                            {!isLogin()&&<li>
                                <Link to="login">Login</Link>
                            </li>}
                        </ul>
                    </div> */}

                    <div className="footer-info">
                        <p className="footer-msg">Nosso suporte online está disponível </p>
                        {/* <p className="footer-msg p-0"><a className="callback" href="#">envie-nos uma mensagem</a></p> */}
                        {/* <form action="#" className="form-validate">
                            <input data-required="text" data-required-email="email" type="text" placeholder="Endereço de email" name="email1" />
                            <input type="submit" value="Se inscrever" />
                        </form> */}
                        <h3 className="heading-font c-white">Siga-nos</h3>
                        <ul className="footer-social-media">
                            <li>
                                <a rel="nofollow" target="_blank" href="https://www.facebook.com/Infocarros-LDA-154147641319393/">
                                    <i className="fa fa-facebook"></i> Facebook
                                </a>
                            </li>
                            {/* <li>
                                <a rel="nofollow" target="_blank" href="mailto:email@email.com">
                                    <i className="fa fa-paper-plane"></i>
                                </a>
                            </li> */}
                            {/* <li>
                                <a rel="nofollow" target="_blank" href="http://pinterest.com/">
                                    <i className="fa fa-pinterest-p"></i>
                                </a>
                            </li> */}
                            <li>
                                <a rel="nofollow" target="_blank" href="https://www.instagram.com/invites/contact/?i=12cwe0enjia7a&utm_content=43poq2">
                                    <i className="fa fa-instagram"></i> Instagram
                                </a>
                            </li>
                            {/* <li>
                                <a rel="nofollow" target="_blank" href="http://twitter.com/">
                                    <i className="fa fa-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a rel="nofollow" target="_blank" href="http://google.com/">
                                    <i className="fa fa-google-plus"></i>
                                </a>
                            </li>
                            <li>
                                <a rel="nofollow" target="_blank" href="http://twitter.com/">
                                    <i className="fa fa-share-alt"></i>
                                </a>
                            </li> */}
                        </ul>
                        
                    </div>

                </div>
                <div className="copyright">
                    <p className="cont">© 2021 InfoMotores Tudo Bem Recebido</p>
                </div>
            </footer>
           
        </div>
     );
}
 
export default AppFooter;