import { initializeApp } from "@firebase/app";
import { getAuth } from '@firebase/auth';
import {getDatabase} from '@firebase/database'


const firebaseConfig = {
  apiKey: "AIzaSyB3K6EvkMiAQUMCAym_vS1FH83iyIphgBc",
  authDomain: "vehicles-app-dfb44.firebaseapp.com",
  databaseURL: "https://vehicles-app-dfb44-default-rtdb.firebaseio.com",
  projectId: "vehicles-app-dfb44",
  storageBucket: "vehicles-app-dfb44.appspot.com",
  messagingSenderId: "617282208065",
  appId: "1:617282208065:web:b072f9a073fbfdb941e1c0",
  measurementId: "G-F3Y912X1P8"
};

const firebase = initializeApp(firebaseConfig);
const auth = getAuth();
const database = getDatabase(firebase);
auth.languageCode = 'en';

export { firebase, auth,database };