import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import { PARTS } from "../../constants";

const PackageComponent = ({ packageMonths, setPackageMonths, packageAmounts, selectedType, changeSelectedPackage, selectedPackage,itemForm, ...props }) => {
    const getPackageAmount = (type) => {
        try {
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            });
            if (type == 'basic'){}
                // console.log(packageAmounts[type])
            let amount = Number(packageAmounts[type]) * packageMonths;
            return formatter.format(amount).toString().replace('$', '');
        } catch (e) {
            console.log(e);
            return 'Pending';
        }
    }
    let amount = packageAmounts;
    // console.log("amount", amount);
    // getPackageAmount();
    // console.log(itemForm);
    // amount
    //    console.log(pageData);
    return (
        <div className='packages-container'>
            <div className='package-items card p-4 m-4'>
                {<div className="form-group col-sm-12 col-md-6 col-lg-4">
                    <label className="mb-1 mt-1 heading-font">Selecionar meses</label>
                    <div className="col-12">
                        <Select
                            showSearch
                            placeholder="Selecionar meses"
                            optionFilterProp="children"
                            style={{ width: "100%" }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                            value={packageMonths}
                            onChange={(e) => {
                                setPackageMonths(e);
                            }}
                        >
                            <Option value={1}>1 Mês</Option>
                            <Option value={2}>2 Meses</Option>
                            <Option value={3}>3 Meses</Option>
                            <Option value={4}>4 Meses</Option>
                            <Option value={5}>5 Meses</Option>


                        </Select>
                    </div>
                </div>}
                <div className="row">

                    {/* PACKAGE 2 */}
                    <div className='package  col-md-3' onClick={() => {
                        changeSelectedPackage('basic');
                    }}>
                        <div className='item'>
                        {/*Previous CSS -> basic-package-bg */}
                            {selectedPackage == 'basic' && <div className='selected-header package'>
                                <p className='m-0 heading-font'>Selecionado</p>
                            </div>}
                            {/* basic-package-border */}
                            <div className={selectedPackage == 'basic' ? 'package-body pb-2 active' : 'package-body pb-2'}>
                                <div className='text-center'>
                                    <h5 className='heading-font mt-2 mb-2 basic-package-color'>Plano Básico</h5>
                                </div>
                                <div className='text-center'>
                                    <h2 className='m-0'><span className='package-currency basic-package-color'>Kz</span>{getPackageAmount('basic')}</h2>
                                </div>
                                <div className='text-left'>
                                    <h5 className='m-0 pr-2' style={{ textAlign: 'right', paddingRight: 10 }}>Mês {packageMonths}</h5>
                                </div>
                                <div className='package-features pt-2'>
                                    <div className='package-feature'>
                                        <p>
                                            <i className='fa fa-check feature-icon basic-package-color'></i> Página web/Applicativo
                                        </p>
                                    </div>
                                    <div className='package-feature'>
                                        <p>
                                            <i className='fa fa-check feature-icon basic-package-color'></i> Instagram Feed
                                        </p>
                                    </div>
                                    {/* <div className='package-feature'>
                                        <p className='inactive'>
                                            <i className='fa fa-check feature-icon inactive basic-package-color'></i> Instagram Stories
                                        </p>
                                    </div> */}
                                    {/* <div className='package-feature'>
                                        <p className='inactive'>
                                            <i className='fa fa-check inactive feature-icon basic-package-color'></i> Patrocinar no Instragram
                                        </p>
                                    </div> */}
                                    <div className='package-feature inactive'>
                                        <p className=''>
                                            <i className='fa fa-check feature-icon basic-package-color'></i> Facebook
                                        </p>
                                    </div>
                                    {/* <div className='package-feature'>
                                        <p className='inactive'>
                                            <i className='fa fa-check feature-icon inactive basic-package-color'></i> Whatapp Status
                                        </p>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* PACKAGE 2 END */}
                    {/* PACKAGE 1 END */}
                    {/* {(selectedType != PARTS && itemForm.sell_type != 'Rent') && <div className='package col-md-3 col-sm-12' onClick={() => {
                        changeSelectedPackage('pro');
                    }}>
                        <div className='item'>
                            {selectedPackage == 'pro' && <div className='selected-header'>
                                <p className='m-0 heading-font'>Selecionado</p>
                            </div>}
                            <div className={selectedPackage == 'pro' ? 'package-body active pb-2' : 'package-body pb-2'}>
                                <div className='text-center'>
                                    <h5 className='heading-font mt-2 mb-2' style={{ color: '#e74e5f' }}>Plano Padrão</h5>
                                </div>
                                <div className='text-center'>
                                    <h2 className='m-0'><span className='package-currency' style={{ color: '#e74e5f' }}>Kz</span>{getPackageAmount('standard')}</h2>
                                </div>
                                <div className='text-left'>
                                    <h5 className='m-0 pr-2' style={{ textAlign: 'right', paddingRight: 10 }}>Mês {packageMonths}</h5>
                                </div>
                                <div className='package-features pt-2'>
                                    <div className='package-feature'>
                                        <p>
                                            <i className='fa fa-check feature-icon'></i> Página web/Applicativo
                                        </p>
                                    </div>
                                    <div className='package-feature'>
                                        <p>
                                            <i className='fa fa-check feature-icon'></i> Instagram Feed
                                        </p>
                                    </div>
                                    <div className='package-feature'>
                                        <p>
                                            <i className='fa fa-check feature-icon'></i> Instagram Stories
                                        </p>
                                    </div>
                                    
                                    <div className='package-feature inactive'>
                                        <p className='inactive'>
                                            <i className='fa fa-check feature-icon inactive'></i> Patrocinar no  Instragram
                                        </p>
                                    </div>
                                    <div className='package-feature inactive'>
                                        <p className='inactive'>
                                            <i className='fa fa-check feature-icon inactive'></i> Facebook
                                        </p>
                                    </div>
                                    <div className='package-feature'>
                                        <p className='inactive'>
                                            <i className='fa fa-check feature-icon inactive'></i> Whatapp Status
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>} */}
                    {/* PACKAGE 1 END */}
                    {/* PACKAGE 3 START */}
                    {/* <div className='package  col-md-3' onClick={() => {
                        changeSelectedPackage('exc');
                    }}>
                        <div className='item'>
                            {selectedPackage == 'exc' && <div className='selected-header exc-package-bg'>
                                <p className='m-0 heading-font'>Selecionado</p>
                            </div>}
                            <div className={selectedPackage == 'exc' ? 'package-body exc-package-border pb-2 active' : 'package-body exc-package-border pb-2'}>
                                <div className='text-center'>
                                    <h5 className='heading-font mt-2 mb-2 exc-package-color'>Plano Exclusivo</h5>
                                </div>
                                <div className='text-center'>
                                    <h2 className='m-0'><span className='package-currency exc-package-color'>Kz</span>{getPackageAmount('exc')}</h2>
                                </div>
                                <div className='text-left'>
                                    <h5 className='m-0 pr-2' style={{ textAlign: 'right', paddingRight: 10 }}>Mês {packageMonths}</h5>
                                </div>
                                <div className='package-features pt-2'>
                                    <div className='package-feature'>
                                        <p>
                                            <i className='fa fa-check feature-icon exc-package-color'></i> Página web/Applicativo
                                        </p>
                                    </div>
                                    <div className='package-feature'>
                                        <p>
                                            <i className='fa fa-check feature-icon exc-package-color'></i>  Instagram Feed
                                        </p>
                                    </div>
                                    <div className='package-feature'>
                                        <p>
                                            <i className='fa fa-check feature-icon exc-package-color'></i>   Instagram Stories
                                        </p>
                                    </div>
                                  

                                    <div className='package-feature'>
                                        <p className={selectedType == PARTS || itemForm.sell_type =='Rent' ? '' : ''}>
                                            <i className={
                                                selectedType == PARTS || itemForm.sell_type =='Rent' ?
                                                    'fa fa-check feature-icon exc-package-color' :
                                                    'fa fa-check feature-icon exc-package-color'}></i>  Facebook
                                        </p>
                                    </div>
                                    <div className='package-feature'>
                                        <p className=''>
                                            <i className='fa fa-check feature-icon  exc-package-color'></i> Whatapp Status
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* PACKAGE 3 END */}
                    {/* PACKAGE 4 START */}

                    {/* {(selectedType != PARTS && itemForm.sell_type != 'Rent') && <div className='package  col-md-3' onClick={() => {
                        changeSelectedPackage('vip');
                    }}>
                        <div className='item'>
                            {selectedPackage == 'vip' && <div className='selected-header vip-package-bg'>
                                <p className='m-0 heading-font'>Selecionado</p>
                            </div>}
                            <div className={selectedPackage == 'vip' ? 'package-body vip-package-border pb-2 active' : 'package-body vip-package-border pb-2'}>
                                <div className='text-center'>
                                    <h5 className='heading-font mt-2 mb-2 vip-package-color'>Plano VIP</h5>
                                </div>
                                <div className='text-center'>
                                    <h2 className='m-0'><span className='package-currency vip-package-color'>Kz</span>{getPackageAmount('vip')}</h2>
                                </div>
                                <div className='text-left'>
                                    <h5 className='m-0 pr-2' style={{ textAlign: 'right', paddingRight: 10 }}>Mês {packageMonths}</h5>
                                </div>
                                <div className='package-features pt-2'>
                                    <div className='package-feature'>
                                        <p>
                                            <i className='fa fa-check feature-icon vip-package-color'></i> Página web/Applicativo
                                        </p>
                                    </div>
                                    <div className='package-feature'>
                                        <p>
                                            <i className='fa fa-check feature-icon vip-package-color'></i> Instagram Feed
                                        </p>
                                    </div>
                                    <div className='package-feature'>
                                        <p>
                                            <i className='fa fa-check feature-icon vip-package-color'></i> Instagram Stories
                                        </p>
                                    </div>
                                    <div className='package-feature'>
                                        <p>
                                            <i className='fa fa-check feature-icon vip-package-color'></i> Patrocinar no Instragram
                                        </p>
                                    </div>
                                    <div className='package-feature inactive'>
                                        <p className=''>
                                            <i className='fa fa-check feature-icon vip-package-color'></i> Facebook
                                        </p>
                                    </div>
                                    <div className='package-feature'>
                                        <p className=''>
                                            <i className='fa fa-check feature-icon vip-package-color'></i> Whatapp Status
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>} */}
                    {/* PACKAGE 3 END */}
                    {/* PACKAGE 4 START */}
                </div>
            </div>

        </div>
    );
}

export default PackageComponent;