import { Link } from "react-router-dom";

const Err404 = () => {
    return (
        <div>
            <main>

                {/* <!-- Breadcrumbs --> */}
                <div class="b-crumbs-wrap">
                    <div class="cont b-crumbs">
                        <ul>
                            <li>
                                <a href="/">Motor</a>
                            </li>
                            <li>
                                <span>Page Not Found</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="cont maincont">

                    <h1><span>Error</span></h1>
                    <span class="maincont-line1 maincont-line12"></span>
                    <span class="maincont-line2 maincont-line22"></span>

                    {/* <!-- Error 404 --> */}
                    <div class="pagecont err404">
                        <img src="./external_assets/img/err404.png" alt="" class="err404-img" />
                        <p>We are so sorry, but the page you requested is not available</p>
                        <form action="#">
                            {/* <input type="text" placeholder="Search.." />
                            <button type="submit"><i class="fa fa-search"></i></button> */}
                        </form>
                    </div>

                </div>

            </main>
        </div>
    );
}

export default Err404;