import { notification } from "antd";

export function notify(title, message, type) {
    switch (type) {
        case 'info':
            notification.info({
                message: title,
                description: message
            })
            break;
        case 'success':
            notification.success({
                message: title,
                description: message
            })
            break;
        case 'warning':
            notification.warning({
                message: title,
                description: message
            })
            break;
        case 'error':
            notification.error({
                message: title,
                description: message
            })
            break;

        default:
            notification.info({
                message: title,
                description: message
            })
            break;
    }
}