import React from 'react';

const ChatTab = ({name,className}) => {
    return (
        <div className={className}>
            <span>
                {name}
            </span>
        </div>
    );
}

export default ChatTab;
