import { getCurrentUser, getCurrentUserId } from "./sessionHelper";
var id = getCurrentUserId() ? getCurrentUserId() : "nologinuser";


export const BASE_URL = process.env['REACT_APP_API_PREFIX'];
export const PREV_IMG = BASE_URL + "uploads/";
const API_URL = "index.php/rest/";
const API_KEY = "teampsisthebest";
// http://infomotores.com/admin
//Custome Api Url;
const C_API_URL = 'apis/';
export const getCategoriesUrl = (method, limit, offset = 0) => {
    return API_URL + `categories/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}/login_user_id/${id}/order_by/order_by_field`;
};
export const getItemsUrl = (method, limit, offset = 0) => {
    return API_URL + `items/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}/login_user_id/${id}`;
};
export const getAdsUrl = () => {
    return C_API_URL + `getAds`;
};
export const getItemDetailUrl = (method, limit, offset = 0, itmId = 0) => {
    return API_URL + `items/${method}/api_key/${API_KEY}/id/${itmId}/limit/${limit}/offset/${offset}/login_user_id/${id}`;
};
export const getRecentProductsUrl = (method, limit, offset = 0) => {
    return API_URL + `items/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}/login_user_id/${id}`;
};
export const getMenufecturerUrl = (categoryId,method, limit, offset = 0) => {
    return API_URL + `manufacturers/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}/login_user_id/${id}/order_by/name/category_id/${categoryId}`;
};
export const getBlogsUrl = (method, limit, offset = 0) => {
    return API_URL + `feeds/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}/login_user_id/${id}`;
};
export const getLocationsUrl = (method, limit, offset = 0) => {
    return API_URL + `itemlocations/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}/login_user_id/${id}`;
};
// https://mjcoders.com/vehicles/index.php/rest/manufacturers/get/api_key/teampsisthebest/limit/30/offset/14/category_id/null
export const getUploadImageUrl = (method, limit, offset = 0) => {
    return API_URL + `images/upload_item/api_key/teampsisthebest`;
};

// ===========+> ADD ITEM PAGE DATA START ==========================
export const getAddItemModel = (method, limit, offset = 0, menufecturer = null) => {
    return API_URL + `models/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}/order_by/name/manufacturer_id/${menufecturer}`;
}; 
export const getAddItemTransmissions = (method, limit, offset = 0) => {
    return API_URL + `transmissions/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}`;
};
export const getAddItemColors = (method, limit, offset = 0) => {
    return API_URL + `colors/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}`;
};
export const getAddItemFuelTypes = (method, limit, offset = 0) => {
    return API_URL + `fueltypes/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}`;
};
export const getAddItemMenufecturer = (method, limit, offset = 0, category_id = 0) => {
    return API_URL + `manufacturers/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}/category_id/${category_id}`;
};
export const getAddItemBuildTypes = (method, limit, offset = 0) => {
    return API_URL + `buildtypes/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}`;
};
export const getAddItemSellerTypes = (method, limit, offset = 0) => {
    return API_URL + `sellertypes/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}`;
};
export const getAddItemItemTypes = (method, limit, offset = 0) => {
    return API_URL + `itemtypes/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}`;
};
// conditions
export const getAddItemConditions = (method, limit, offset = 0) => {
    return API_URL + `conditions/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}`;
};
// pricetypes
export const getAddItemPriceTypes = (method, limit, offset = 0) => {
    return API_URL + `pricetypes/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}`;
};
// currencies
export const getAddItemCurrencies = (method, limit, offset = 0) => {
    return API_URL + `currencies/${method}/api_key/${API_KEY}/limit/${limit}/offset/${offset}`;
};
// ===========> ADD ITEMS PAGE DATA END ======
export const addItemUrl = () => {
    return API_URL + `items/add/api_key/${API_KEY}`;
}

export const getItemImages = (item_id) => {
    return API_URL + `images/get/api_key/${API_KEY}/img_parent_id/${item_id}/img_type/item/limit/100/offset/0`;
}
export const getChatList = () => {
    return API_URL + `chat_items/get_buyer_seller_list/api_key/${API_KEY}`;
}
export const getChatHistory = () => {
    return API_URL + `chats/get_chat_history/api_key/${API_KEY}`;
}

export const resetChatCountUrl = () => {
    return API_URL + `/chats/reset_count/api_key/${API_KEY}`;
}

export const deleteItemUrl = () => {
    return API_URL + `items/item_delete/api_key/${API_KEY}`;
}

export const getProductListCount = (id) => {
    return C_API_URL + '/get_products_count/'+id;
}
// https://infomotores.com/admin/index.php/rest/users/profile_update/api_key/teampsisthebest
export const editProfile = () => {
    return API_URL + `users/profile_update/api_key/${API_KEY}`;
}

export const sendEmail = (type,id) => {
    return C_API_URL + `/send_mail/${type}/${id}`;
}
export const deleteImages = (type,id) => {
    return API_URL + `images/delete_images/api_key/${API_KEY}`;
}


export const GoogleLoginUrl = API_URL + `users/google_register/api_key/${API_KEY}`;
export const PhoneNumberLoginUrl = API_URL + `users/phone_register/api_key/${API_KEY}`;
export const EmailLoginUrl = API_URL + `users/login/api_key/${API_KEY}`;
export const AddUserUrl = API_URL + `users/add/api_key/${API_KEY}`;
// https://mjcoders.com/vehicles/index.php/rest/users/phone_register/api_key/teampsisthebest
export const SIGNUP = 'user/signup';
export const APPLICANTS_LIST_STATUS = 'list/checks/status/';
export const APPLICANTS_BY_ID = 'list/checks/';
export const APPROVE_OR_DECLINE_APPLICANTS = 'checks/status';
