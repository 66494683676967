import { Redirect, Route } from "react-router";
// Router
const PublicRoute = ({children , ...rest}) => {
    // console.log(children);
    // console.log(rest);
    return ( 
        <Route
        {...rest}
        render={({ location }) =>
           localStorage.getItem('user') ? (
                (
                    <Redirect
                        to={{
                            pathname: "/dashboard",
                            state: { from: location }
                        }}
                    />
                )
            ) : children
        }
    />
     );
}
 
export default PublicRoute;