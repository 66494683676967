import { Link } from "react-router-dom";
import { SERVICES } from "../../constants";
import { PREV_IMG } from "../../constants/endPoints";
import { convertToBase64 } from "../../constants/helper";

const ProductListServiceItem = ({ val, i, loc, ...props }) => {
    return (
        <div className="sectls" key={i}>
            <Link to={"/product_details/" + convertToBase64(val.id)} className="sectls-img">
                <img src={PREV_IMG + val.default_photo.img_path} onError={(e) => { e.target.src = "/assets/images/placeholder.jpeg" }} />
            </Link>
            <div className="sectls-cont">
                <div className="sectls-ttl-wrap">
                    {/* Check if there is menufecturer then show manufacturer as title else show title */}
                    {
                        (val.manufacturer.name || val.manufacturer_id == -1) &&
                        <h3><Link to={"/product_details/" + convertToBase64(val.id)}>
                            {val.manufacturer_id == -1 ? val.manufacturer_name : val.manufacturer.name}
                            &nbsp;{val.model_id == -1 ? val.model_name : val.model.name}
                            &nbsp;{val.year}
                            &nbsp;
                            Serviço
                        </Link></h3>
                    }
                    {
                        (!val.manufacturer.name && val.manufacturer_id != -1) &&
                        <h3><Link to={"/product_details/" + convertToBase64(val.id)}>{val.title}
                            &nbsp;Serviço</Link></h3>
                    }
                </div>
                {
                    <div className="sectls-rating-wrap">
                        <p className="sectls-rating">
                            Descrição
                        </p>
                        <p className="uploaded_txt">{val.description}</p>&nbsp;&nbsp;
                    </div>
                }
            </div>

            <div className="sectls-info">
                <div className="sectls-rating-wrap">
                    <p className="sectls-rating">
                        Área de serviço
                    </p>
                    <p className="uploaded_txt">{val.service_area}</p>
                </div>

               {loc && <div className="sectls-rating-wrap">
                    <p className="sectls-rating">
                        Província
                    </p>
                    <p className="uploaded_txt">{loc.name}</p>
                </div>}

                <div className="sectls-rating-wrap">
                    <p className="sectls-rating">
                        Carregado
                    </p>
                    <p className="uploaded_txt">{val.added_date_str}</p>
                </div>
            </div>
        </div>
    );
}

export default ProductListServiceItem;