import { frontSliderImages } from "../../constants";

const HomeSlider = () => {
    return (<div className="frontslider-wrap">
        <div className="responisve-container">
            <div className="slider" id="front-slider">
                {/* <div className="slide">
                    <img src="/external_assets/img/front-slider/1.png"
                        data-position="45,430" data-in="fade" data-delay="0" data-out="fade" alt="American Legends" />
                    <p className="caption1"
                        data-position="50,0" data-in="right" data-step="0" data-out="fade" data-delay="0">AMERICAN</p>
                    <p className="caption2"
                        data-position="150,0" data-in="right" data-step="0" data-out="fade" data-delay="0">LEGENDS</p>
                </div> */}

                {frontSliderImages.map((val) => {
                    return <div className="slide">
                        <img src={val.image} className="slide-image"
                            data-position="13,300" style={{ width: `${val.width}`, height: `${val.height}` }} width={val.width} height={val.height} data-in="fade" data-delay="0" data-out="fade" alt={val.title} />
                        <div data-position="28,2" data-in="right" data-step="0" data-out="fade" data-delay="0" className="slide-heading">
                            <p className="caption1 m-0"
                            >{val.title} </p>
                            <p className="caption2 m-0" style={{ display: 'flex', whiteSpace: 'nowrap' }}
                            >{val.subTitle}</p>
                        </div>

                    </div>
                })}
                {/* <div className="slide">
                        <img src="/external_assets/img/home-slider/truck.png" className="slide-image"
                            data-position="13,430" style={{ width: "90%", height: "100%" }} width={"50%"} height={"90%"} data-in="fade" data-delay="0" data-out="fade" alt="American Legends" />
                        <div data-position="8,2" data-in="right" data-step="0" data-out="fade" data-delay="0" className="slide-heading">
                            <p className="caption1 m-0"
                            >Compra, </p>
                            <p className="caption2 m-0" style={{ fontSize: 40, display: 'flex', whiteSpace: 'nowrap' }}
                            >Aluguer & Venda</p>
                        </div>

                    </div>
                <div className="slide">
                    <img src="/external_assets/img/home-slider/tractor.png"
                        data-position="45,430" style={{ width: "70%", height: "90%" }} width={"50%"} height={"90%"} data-in="fade" data-delay="0" data-out="fade" alt="American Legends" />
                    <p className="caption1"
                        data-position="50,0" data-in="right" data-step="0" data-out="fade" data-delay="0">Compra, </p>
                    <p className="caption2" style={{ fontSize: 50, display: 'flex', whiteSpace: 'nowrap' }}
                        data-position="150,0" data-in="right" data-step="0" data-out="fade" data-delay="0">Venda & Aluguer</p>
                </div>
                <div className="slide">
                    <img src="/external_assets/img/home-slider/motor-cycle.png"
                        data-position="50,518" data-in="fade" data-delay="0" data-out="fade" alt="Legendary Bike" />
                    <p className="caption1"
                        data-position="50,0" data-in="right" data-step="0" data-out="fade" data-delay="0">Venda, </p>
                    <p className="caption2" style={{ fontSize: 50, display: 'flex', whiteSpace: 'nowrap' }}
                        data-position="150,0" data-in="right" data-step="0" data-out="fade" data-delay="0">Compra & Aluguer</p>
                </div>
                <div className="slide">
                    <img src="/external_assets/img/home-slider/boat.png"
                        data-position="21,480" data-in="fade" data-delay="0" data-out="fade" alt="Dream Car" />
                    <p className="caption1"
                        data-position="50,0" data-in="right" data-step="0" data-out="fade" data-delay="0">Compra, </p>
                    <p className="caption2" style={{ fontSize: 50, display: 'flex', whiteSpace: 'nowrap' }}
                        data-position="150,0" data-in="right" data-step="0" data-out="fade" data-delay="0">Venda & Aluguer</p>
                </div>
                <div className="slide">
                    <img src="/external_assets/img/home-slider/parts.png"
                        data-position="21,480" data-in="fade" data-delay="0" data-out="fade" alt="Dream Car" />
                    <p className="caption1"
                        data-position="50,0" data-in="right" data-step="0" data-out="fade" data-delay="0">Venda, </p>
                    <p className="caption2" style={{ fontSize: 50, display: 'flex', whiteSpace: 'nowrap' }}
                        data-position="150,0" data-in="right" data-step="0" data-out="fade" data-delay="0">Compra & Aluguer</p>
                </div> */}
            </div>
        </div>
    </div>);
}

export default HomeSlider;