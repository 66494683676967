import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { getData, postData } from "../api";
import { getItemsUrl, getProductListCount, PREV_IMG, getLocationsUrl } from "../constants/endPoints";
import { convertFromBase64, convertToBase64, currencyFormat, setLoadingState } from "../constants/helper";
import { getItemData } from "../Hooks/useItemParams";
import {
    slice, concat,
} from 'lodash';
import DataNotFound from "./DataNotFound";
import { CARS, PARTS, SERVICES } from "../constants";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import AppLoader from "./Components/AppLoader";

import { compose } from 'redux';
import { updateLocation } from '../ReduxStore/actions/locationAction';
import { connect } from 'react-redux';
import Search from "antd/lib/transfer/search";
import ProductListItem from "./Components/ProductListItem";
import ProductListServiceItem from "./Components/ProductListServiceItem";
import ProductListRentCarItem from "./Components/ProductListRentCarItem";


const ProductList = ({ ...props }) => {
    // console.log("data",itemData);
    // console.log("props",data);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [products, setProduct] = useState({ isLoading: false, data: [] });
    const [loadMoreTxt, setLoadMoreTxt] = useState("Load More");
    const [totalProducts, setTotalProducts] = useState(0);
    const { data } = useParams();
    // const [isError, setError] = useState(false);
    const location = useLocation();
    // const [itemData,setItemData] = useState(itemData);
    // let itemData = null;
    let reqData = null;
    const [title, setTitle] = useState("PRODUTOS");
    // const history = usePlaceholder

    const getPageData = async () => {
        try {
            console.log("getPageData", products);

            reqData = null;
            try {
                reqData = JSON.parse(convertFromBase64(data));
            } catch (e) {
                console.log("MJ error", e);
            }
            if (!reqData) {
                reqData = getItemData();
            } else {
                if (reqData.category_id == SERVICES) setTitle("SERVIÇOS")
            }
            setProduct((prev) => { return { ...setLoadingState(prev, true) } });
            const res2 = await postData(getItemsUrl("search", limit, offset), reqData);
            let response = res2.data;
            // console.log(response);
            setProduct((prev) => { return { ...setLoadingState(prev, false, response) } });

            // var res = await postData(getItemsUrl("search",limit,offset));
        } catch (e) {
            setProduct((prev) => { return { ...setLoadingState(prev, false) } });
            console.log(e);
        }
    }
    const getTotalProducts = async () => {
        try {
            const res = await getData(getProductListCount(reqData.category_id), {});
            // console.log("totalProducts, ", res);
            if (res) {
                setTotalProducts(res.data.response.count);
            }
        } catch (e) {

        }
    }
    const getLocation = async () => {
        try {
            props.updateLocation({ isLoading: true });
            const res2 = await getData(getLocationsUrl("get", 100, 0));
            if (!res2.data) {
                props.updateLocation({ isLoading: false });
                return;
            }
            props.updateLocation({ isLoading: false, data: res2.data });
            // console.log("Props location data", props);

        } catch (e) {
            console.log(e);
            props.updateLocation({ isLoading: false });
        }
    }
    const loadMoreData = async () => {
        try {
            reqData = null;
            try {
                reqData = JSON.parse(convertFromBase64(data));
            } catch (e) {
                console.log(e);
            }
            if (!reqData) {
                reqData = getItemData();
            }
            setLoadMoreTxt("Loading....");
            // window.addEventListener('scroll', loadMoreTxt);
            // setProduct((prev) => { return { ...setLoadingState(prev, true) } });
            const res2 = await postData(getItemsUrl("search", limit, offset), reqData);
            if (res2.data.status == "error") {
                setLoadMoreTxt("No More Data");
            }
            // console.log(res2);
            let response = res2.data;

            setProduct((prev) => { var ndata = concat(prev.data, response); return { data: ndata, isLoading: false }; });
            if (res2.data.length < 1) {
                setLoadMoreTxt("No More Data");
            }
            // window.scrollTo(0, 0)
            setLoadMoreTxt("Load More");
            // console.log(response);

            // setProduct((prev) => { return { ...setLoadingState(prev, false, response) } });
        } catch (e) {
            setLoadMoreTxt("No More Data");
            console.log(e);
        }

    }
    const handleLoadMore = async () => {
        setOffset(offset + limit);
    }

    useEffect(() => {
        if (offset != 0)
            loadMoreData();
    }, [offset, limit]);

    useEffect(() => {
        window.scrollTo(0, 0);
        // console.log("itemdata", location.itemData);
        reqData = location.itemData;
        if (location.category_name) {
            if (location.category_name.length > 0) {
                setTitle(location.category_name);
            }
        }
        getTotalProducts();
        getPageData();
        getLocation();
    }, []);
    return (
        <main className="productList">

            {/* <!-- Breadcrumbs --> */}
            <div className="b-crumbs-wrap">
                <div className="cont b-crumbs">
                    <ul>
                        <li>
                            <FaArrowAltCircleLeft size={20} onClick={() => {
                                window.history.back();
                            }} />
                        </li>
                        <li>
                            <Link to="/">Motor</Link>
                        </li>

                        <li>
                            <span>Produtos</span>
                        </li>
                    </ul>

                </div>
            </div>
            <div>
                <div className="cont specials">

                    <h2 style={{ position: "relative" }}><span>{title} {
                        totalProducts > 0 && <span>
                            (Total {totalProducts})
                        </span>
                    }</span></h2>


                    {/* <!-- Catalog Sections --> */}

                    {/* <!-- Catalog Filter - end --> */}

                    {/* <!-- Catalog Items - start --> */}


                    <div className="section-list row">
                        {
                            !products.isLoading && products.data.length < 1 ? <DataNotFound hideTopBar={true} /> : <div></div>
                        }
                        {
                            products.isLoading && products.data.length < 1 && <AppLoader />
                        }
                        {

                            products.data && products.data.map((val, i) => {
                                let loc = {};
                                props.location.data.forEach(data => {
                                    if (data.id == val.item_location_id) {
                                        loc = data;
                                    }
                                })
                                if (val.category_id == SERVICES)
                                    return <ProductListServiceItem val={val} i={i} loc={loc} />
                                else
                                    if (val.category_id == CARS && val.sell_type == 'Rent')
                                        return <ProductListRentCarItem val={val} i={i} loc={loc} />
                                    else
                                        return <ProductListItem val={val} i={i} loc={loc} />

                            })
                        }

                    </div>
                    {/* <!-- Catalog Items - end --> */}

                    {/* <!-- Pagination --> */}
                    <div className="lbl-load-more">
                        <label onClick={handleLoadMore}>{loadMoreTxt}</label>
                    </div>

                </div>
            </div>

            {/* <!-- Get a Special Deals --> */}
            <div className="getspec-wrap">
                <div className="cont getspec">
                    <div className="getspec-cont">
                        <h3>O inverno está chegando</h3>
                        <p>Novas peças de snowmobile</p>
                        <form action="#">
                            <input type="text" placeholder="Endereço de email" />
                            <input type="submit" value="Obtenha ofertas especiais" />
                        </form>
                    </div>
                    <a href="#" className="getspec-img">
                        <img src="img/getspec.jpg" alt="" />
                    </a>
                </div>
            </div>

        </main>
    );
}

const mapLocationWithProps = (state) => {
    return {
        location: state.location
    }
}
const mapLocationActionWithProps = (dispatch) => {
    return {
        updateLocation: (_data) => dispatch(updateLocation(_data)),
    }
}

export default compose(
    connect(mapLocationWithProps, mapLocationActionWithProps),
)(ProductList);