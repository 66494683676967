import { convertFromBase64, convertToBase64 } from "../constants/helper";
import { useState, useEffect } from "react";
export const getFromStorage = (key) => {
    let data = '';
    let value = localStorage.getItem(convertToBase64(key));
    data = convertFromBase64(value);
    return JSON.parse(data);
}
const useLocalStorage = (key, val) => {
    const [value, setValue] = useState(() => {
        if (key)
            return getFromStorage(key);
        else
            return null;
    })
    useEffect(() => {
        if (value)
            localStorage.setItem(convertToBase64(key), convertToBase64(JSON.stringify(value)));
    }, [value])

    return ([value, setValue]);
}

export default useLocalStorage;