import { Input, Select, Space, Spin } from "antd";
import { Option } from "antd/lib/mentions";
import { Spinner } from "react-bootstrap";
import { FaCalendar, FaDeviantart, FaMoneyBill } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { BOATS, boatTypes, CARS, carTypes, CRASHED_CARS, kmEndList, kmStartList, machinaryTypes, MACHINERY, MOTOR_BIKES, NAUTICAL, OTHERS, PARTS, rentPriceList, sellEndPriceList1, sellEndPriceList2, sellStartPriceList1, sellStartPriceList2, SERVICES, TRUCKS, serviceAreas } from "../../constants";
import { BASE_URL, getItemsUrl, PREV_IMG } from "../../constants/endPoints";
import { CurrencyInput } from "../Components/CurrencyInput";
import { useEffect, useState } from "react";
import { postData } from "../../api";
import { convertFromBase64, convertNumber, convertToBase64 } from "../../constants/helper";
import AndtLoader from "../Components/AndtLoader";

const SearchForm = ({ setValue, changeSearchCategory, getModels,
    categories, searchManus, searchModels, itemForm, transmissions,
    colors, buildTypes, location,selectedCategory, ...props }) => {
    const history = useHistory();
    const [filterCount, setFilterCount] = useState({
        count: 0,
        isLoading: false
    });
    const [fromYear, setFromYear] = useState([]);
    const [toYear, setToYear] = useState([]);
    const submitSearch = () => {
        // console.log("formData/", convertToBase64(JSON.stringify(itemForm)));
        if (itemForm == null) {
            return;
        }
        // return;
        history.push({ pathname: "products/" + convertToBase64(JSON.stringify(itemForm)) });
    }

    const getFilterCount = async () => {
        try {

            // setFilterCount((prev) => { return { ...prev, isLoading: true }; });
            let payload = itemForm;
            // console.log("filter count in searchForm: ", itemForm);
            const res2 = await postData(getItemsUrl("search", 12, 0) + "?is_request_count=true", payload);

            if (res2.data.status != 0) {
                setFilterCount((prev) => {
                    return { count: res2.data.response.count, isLoading: false }
                });
            } else {
                setFilterCount((prev) => {
                    return { count: 0, isLoading: false }
                });
            }

        } catch (e) {
            // setFilterCount((prev) => {
            //     return { count: 0, isLoading: false }
            // });
        }
    }
    const getYears = () => {
        let year = new Date().getFullYear();
        let years = []
        for (var i = 1990; i <= year; i++) {
            years.push(i);
        }
        setFromYear([...years]);
        setToYear([...years]);

        setTimeout(() => {
            setValue('min_year', 1990);
            setValue("max_year", year);
            // console.log("+==========>", itemForm);
        }, 1000)

    }

    useEffect(() => {
        getFilterCount();
    }, [itemForm]);
    useEffect(() => {
        getYears();
    }, [])

    return <div className='home-search-category-container card'>
        <div className='card-body'>
            <div className='row category-search-items'>
                <div className='col-md-12'>
                    <div className='d-flex category-search-list'>
                        {
                            categories && (categories.isLoading && categories.data.length < 1) &&
                            <div className="d-flex justify-content-center align-items-center w-100 mt-8">
                                <Spinner animation="grow" />
                            </div>
                        }
                        {
                            categories && (!categories.isLoading && categories.data.length < 1) &&
                            <div className="d-flex justify-content-center align-items-center w-100 mt-8">
                                <span className="heading-font">Cannot Load Data</span>
                            </div>
                        }
                        {
                            categories &&
                            categories.data.map((val, i) => {
                                return (itemForm.sell_type == 'Rent'
                                    && (val.category_id.toString() == PARTS.toString() || val.category_id.toString() == CRASHED_CARS.toString() || val.category_id.toString() == SERVICES.toString()))
                                    ? <span></span> : <div className="item"> <div className={selectedCategory == val.category_id ? 'search-category-image search-category-image-active' : 'search-category-image'} onClick={() => {
                                        // console.log("itemForm", itemForm);
                                        setValue('category_id', val.category_id);
                                        changeSearchCategory(val.category_id);
                                        // getFilterCount();
                                    }}>
                                        <div>
                                            <div>
                                                <img src={BASE_URL + val.category_image} alt="" onError={(e) => { e.target.src = "./assets/images/placeholder.jpeg" }} width="80px" />
                                            </div>
                                            <div>
                                                <p className="p-0 m-0 heading-font">{val.category_name}</p>
                                            </div>
                                        </div>

                                    </div></div>
                            })
                        }
                    </div>
                </div>
                {/* <div className='col-md-6'>
                
            </div> */}
            </div>
            {/* <hr/> */}
            {itemForm.category_id != SERVICES && <div className='col-sm-12'>
                <div className='d-flex justify-content-center'>
                    <div className='home-rent-container p-2'>
                        <div className={itemForm.sell_type == 'Sell' ? 'sell active' : 'sell'} onClick={() => {
                            setValue('sell_type', 'Sell');
                            // getFilterCount();
                        }}>
                            <h6>Comprar</h6>
                        </div>
                        {(itemForm.category_id != PARTS && itemForm.category_id != CRASHED_CARS) &&  <div className={itemForm.sell_type == 'Rent' ? 'rent active' : 'rent'}
                            onClick={() => {
                                setValue('sell_type', 'Rent');
                                // getFilterCount();
                            }}>
                            <h6>Aluguer</h6>
                        </div>}

                    </div>
                </div>
            </div>
            }
            { itemForm.category_id != SERVICES && <hr />}
            <div className='col-sm-12 p-2'>
                <div className='row'>
                    {((itemForm.category_id != BOATS || itemForm.sell_type != 'Rent')
                        && (itemForm.category_id != CARS || itemForm.sell_type != 'Rent')
                        && (itemForm.category_id != MOTOR_BIKES || itemForm.sell_type != 'Rent')
                        && (itemForm.category_id != MACHINERY || itemForm.sell_type != 'Rent')  
                        && (itemForm.category_id != SERVICES)) &&  <div className="form-group col-6 pt-2">
                            {/* <label className="mb-1 mt-1 heading">Manufecturer</label> */}
                            <div className="col-12">

                                {<div>
                                    <label className="search-label">Fabricante</label>
                                    {searchManus.isLoading ?
                                        <div style={{ textAlign: 'center', width: "100%" }}>
                                            <AndtLoader />
                                        </div>
                                        : <Select
                                            showSearch
                                            // size="middle"
                                            placeholder={itemForm.category_id == PARTS ? "Selecione o Categoria"
                                                : "Selecione o fabricante"}
                                            optionFilterProp="children"
                                            style={{ width: "100%" }}
                                            filterOption={(input, option) => {
                                                try {
                                                    return option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                } catch (e) { }
                                            }
                                                // console.log(option.children);

                                            }
                                            filterSort={(optionA, optionB) => {
                                                try {
                                                    return optionA.children[1].toLowerCase().localeCompare(optionB.children[1].toLowerCase());
                                                } catch (e) { }
                                            }
                                            }
                                            value={itemForm.manufacturer_id}

                                            onChange={(e) => {
                                                setValue("manufacturer_id", e);
                                                getModels(e);
                                                // getFilterCount();
                                            }}
                                        >

                                            <Option value="">{itemForm.category_id == PARTS ? "Selecione o Categoria"
                                                : "Selecione o fabricante"}</Option>
                                            {
                                                searchManus &&
                                                searchManus.data.map((val, i) => {
                                                    return <Option value={val.id} key={"manufec" + i}>
                                                        <img src={PREV_IMG + val.default_icon.img_path} width="20px" style={{ boxFit: 'cover', paddingRight: 4 }} />
                                                        {val.name}
                                                    </Option>
                                                })
                                            }
                                            <Option value="-1">
                                            <img src={'/external_assets/img/motor_logo.png'} width="20px" style={{ boxFit: 'cover', paddingRight: 4 }} />
                                            Other</Option>
                                        </Select>}
                                </div>}
                            </div>
                        </div>
                    }

                    {(
                        itemForm.category_id != CRASHED_CARS &&
                        ((itemForm.category_id != BOATS || itemForm.sell_type != 'Rent')
                            && itemForm.category_id != TRUCKS
                            && itemForm.category_id != MACHINERY
                            && (itemForm.category_id != CARS || itemForm.sell_type != 'Rent')
                            && (itemForm.category_id != MACHINERY || itemForm.sell_type != 'Rent')
                            && (itemForm.category_id != MOTOR_BIKES || itemForm.sell_type != 'Rent')
                            && (itemForm.category_id != SERVICES)
                            )
                    ) && <div className="form-group col-6 pt-2">
                            {/* <label className="mb-1 mt-1 heading">Manufecturer</label> */}
                            <div className="col-12">
                                {<div>
                                    <label className="search-label">Modelo</label>
                                    {searchModels.isLoading ?
                                        <div style={{ textAlign: 'center', width: "100%" }}>
                                            <AndtLoader />
                                        </div>
                                        : <Select
                                            showSearch
                                            // size="middle"
                                            placeholder={itemForm.category_id == PARTS ? "Selecionar Sub Categoria"
                                                : "Selecionar modelo"}
                                            optionFilterProp="children"
                                            style={{ width: "100%" }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            value={itemForm.model_id}

                                            onChange={(e) => {
                                                setValue("model_id", e);
                                                //getFilterCount();
                                            }}
                                        >
                                            <Option value="">{itemForm.category_id == PARTS ? "Selecionar Sub Categoria"
                                                : "Selecionar modelo"}</Option>
                                            {
                                                searchModels.data && searchModels.data.map((val, i) => {
                                                    return <Option value={val.id} key={"model" + i}>
                                                        {val.name}
                                                    </Option>
                                                })
                                            }
                                            <Option value="-1">Other</Option>
                                        </Select>}
                                </div>}
                            </div>
                        </div>}
                    {/* BUILD TYPE */}
                    {(itemForm.category_id == PARTS) && <div className="form-group col-6 mt-2">
                        {/* <label className="mb-1 mt-1 heading">Manufecturer</label> */}
                        <div className="col-12">
                            <label className="search-label">Tipo de veículo</label>
                            <Select
                                showSearch
                                // size="middle"
                                placeholder="Tipo de veículo"
                                optionFilterProp="children"
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                value={itemForm.build_type_id}

                                onChange={(e) => {
                                    setValue("build_type_id", e);
                                    // getModels(e);
                                    // getFilterCount();
                                }}
                            >

                                <Option value="">Tipo de veículo</Option>
                                {
                                    buildTypes &&
                                    buildTypes.map((val, i) => {
                                        return <Option value={val.id} key={"build_type" + i}>
                                            {val.car_type}
                                        </Option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    }
                    {(itemForm.category_id != PARTS &&
                        itemForm.category_id != BOATS &&
                        itemForm.category_id != TRUCKS &&
                        itemForm.category_id != MACHINERY &&
                        itemForm.sell_type != "Rent") &&
                        (itemForm.category_id != CRASHED_CARS &&
                            itemForm.category_id != NAUTICAL) && 
                            (itemForm.category_id != SERVICES ) &&  <div className="form-group col-lg-6 col-md-6 col-sm-12 pt-2">
                            <label className="search-label">Quilómetros</label>
                            <div className="row">
                                <div className="col-6">
                                    <Select
                                        showSearch
                                        // size="middle"
                                        placeholder="Inicial"
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                        value={itemForm.mileage}

                                        onChange={(e) => {
                                            setValue("max_mileage", '');
                                            setValue("mileage", e);
                                            //getFilterCount();
                                        }}
                                    >
                                        <Option value="">Inicial</Option>
                                        {
                                            kmStartList.map((val, i) => {
                                                return <Option value={val.val} key={"kilometers" + i} >
                                                    {val.text}
                                                </Option>
                                            })
                                        }
                                    </Select>
                                </div>
                                <div className="col-6 ps-0">
                                    <Select
                                        showSearch
                                        // size="middle"
                                        placeholder="Final"
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                        value={itemForm.max_mileage}

                                        onChange={(e) => {
                                            setValue("max_mileage", e);
                                            //getFilterCount();
                                        }}
                                    >
                                        <Option value="">Final</Option>
                                        {
                                            kmEndList.map((val, i) => {
                                                return <Option value={val.val} key={"kilometers2" + i} disabled={convertNumber(itemForm.mileage) > convertNumber(val.val)} >
                                                    {val.text}
                                                </Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                        </div>}

                    {(itemForm.sell_type != "Rent" &&
                        itemForm.category_id != TRUCKS &&
                        itemForm.category_id != MACHINERY) && (itemForm.category_id != PARTS && itemForm.category_id != CRASHED_CARS) && 
                        (itemForm.category_id != SERVICES ) &&  <div className="form-group col-lg-6 col-md-6 col-sm-12 mt-2">
                            <div className="row">
                                <div className="col-6">
                                    {/* YEAR */}
                                    <p className="m-0 search-label">
                                        Ano mínimo
                                    </p>
                                    <Select
                                        showSearch
                                        // size="middle"
                                        placeholder="Ano mínimo"
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                        value={itemForm.min_year}

                                        onChange={(e) => {
                                            if (e) setValue('max_year', e + 1);
                                            else setValue('max_year', '');
                                            // setValue('max_year', e+1);
                                            setValue('min_year', e);
                                            //getFilterCount();
                                        }}
                                    >
                                        <Option value="">Ano mínimo</Option>
                                        {
                                            [...fromYear].map((val, i) => {
                                                return <Option value={val} key={"min_year_" + i}>
                                                    {val}
                                                </Option>
                                            })
                                        }
                                    </Select>
                                </div>
                                <div className="col-6 ps-0">
                                    <p className="m-0 search-label">
                                        Ano Máximo
                                    </p>
                                    <Select
                                        showSearch
                                        // size="middle"
                                        placeholder="Ano Máximo"
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                        value={itemForm.max_year}

                                        onChange={(e) => {
                                            setValue('max_year', e);
                                            //getFilterCount();
                                        }}
                                    >
                                        <Option value="">Ano Máximo</Option>
                                        {
                                            [...toYear].map((val, i) => {
                                                return <Option value={val} key={"max_year_" + i} disabled={convertNumber(itemForm.min_year) > convertNumber(val)}>
                                                    {val}
                                                </Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                            {/* <label className="mb-1 mt-1 heading">Item Name</label> */}
                        </div>}


                    {(itemForm.sell_type != "Rent") &&
                        (itemForm.category_id != PARTS &&
                            itemForm.category_id != TRUCKS &&
                            itemForm.category_id != MACHINERY &&
                            itemForm.category_id != CRASHED_CARS) && 
                            itemForm.category_id != SERVICES && <div className="form-group col-lg-6 col-md-6 col-sm-12 mt-2">
                            <label className="search-label">Preço</label>
                            <div className="row">
                                <div className="col-6">
                                    <Select
                                        showSearch
                                        // size="middle"
                                        placeholder="Inicial"
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                        value={itemForm.min_price}

                                        onChange={(e) => {
                                            setValue("max_price", '');
                                            setValue("min_price", e);
                                            //getFilterCount();
                                        }}
                                    >
                                        <Option value="">Inicial</Option>
                                        {
                                            (itemForm.category_id == CARS || itemForm.category_id == BOATS ?
                                                sellStartPriceList2 :
                                                sellStartPriceList1).map((val, i) => {
                                                    return <Option value={val.val} key={"min_price" + i} >
                                                        {val.text}
                                                    </Option>
                                                })
                                        }
                                    </Select>
                                </div>
                                <div className="col-6 ps-0">
                                    <Select
                                        showSearch
                                        // size="middle"
                                        placeholder="Final"
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                        value={itemForm.max_price}

                                        onChange={(e) => {
                                            setValue("max_price", e);
                                            //getFilterCount();
                                        }}
                                    >
                                        <Option value="">Final</Option>
                                        {
                                            (itemForm.category_id == CARS || itemForm.category_id == BOATS ?
                                                sellEndPriceList2 :
                                                sellEndPriceList1).map((val, i) => {
                                                    return <Option value={val.val} key={"max_price" + i} disabled={convertNumber(itemForm.min_price) > convertNumber(val.val)} >
                                                        {val.text}
                                                    </Option>
                                                })
                                        }
                                    </Select>
                                </div>
                            </div>
                            {/* <div className="row">
                            <label className="col-12 search-label">Preço</label>
                            <div className="col-6">
                                <CurrencyInput type={'text'} size="medium" className='currency-input' placeholder="Preço inicial"
                                    onChange={(event) => {

                                    }}
                                    onBlur={(event) => {
                                        let val = event.target.value.replace('Kz', '');
                                        val = val.replace(',', '');
                                        setValue('min_price', val);
                                    }} />
                            </div>
                            <div className="col-6">
                                <CurrencyInput type={'text'} className='currency-input' size="medium" placeholder="Preço final" onChange={(event) => {

                                }}
                                    onBlur={(event) => {
                                        let val = event.target.value.replace('Kz', '');
                                        val = val.replace(',', '');
                                        setValue('max_price', val);
                                    }}
                                />
                            </div>
                        </div> */}
                        </div>}
                    {
                        (itemForm.sell_type == "Rent") && <div className="form-group col-12 pt-2">
                            <div className="col-12">
                                {itemForm.category_id == CARS && <div className="form-group col-12">
                                    {/* <label className="mb-1 mt-1 heading">Car Type*</label> */}
                                    <div className="col-12">
                                        <label className="search-label">Tipo de item</label>
                                        <Select
                                            showSearch
                                            placeholder="Selecione o tipo de item"
                                            optionFilterProp="children"
                                            style={{ width: "100%" }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            value={itemForm.item_type}
                                            onChange={(e) => {
                                                setValue("item_type", e);
                                                //getFilterCount();
                                            }}
                                        >
                                            <Option value="">Selecione o tipo de carro</Option>
                                            {
                                                carTypes.map((e, i) => {
                                                    return <Option value={e.key} key={"item_type__" + i}>{e.value}</Option>
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                                }
                                {/* NAUTICAL TYPEs */}
                                {itemForm.category_id == BOATS && <div className="form-group col-12">
                                    {/* <label className="mb-1 mt-1 heading">Nautical Type*</label> */}
                                    <div className="col-12">
                                        <label className="search-label">Tipo de item</label>
                                        <Select
                                            showSearch
                                            placeholder="Selecione o tipo de item"
                                            optionFilterProp="children"
                                            style={{ width: "100%" }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            value={itemForm.item_type}
                                            onChange={(e) => {
                                                setValue("item_type", e);
                                                //getFilterCount();
                                            }}
                                        >
                                            <Option value="">Selecione o tipo de barco</Option>
                                            {
                                                boatTypes.map((e, i) => {
                                                    return <Option value={e.key} key={"item_type__" + i}>{e.value}</Option>
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    }
                    {itemForm.category_id == MACHINERY && <div className="form-group col-6 pt-2">
                        <div className="col-12">
                            <label className="search-label">Tipo de item</label>
                            <Select
                                showSearch
                                placeholder="Selecione o tipo de item"
                                optionFilterProp="children"
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                value={itemForm.item_type}
                                onChange={(e) => {
                                    setValue("item_type", e);
                                    //getFilterCount();
                                }}
                            >
                                <Option value="">Selecione o tipo de barco</Option>
                                {
                                    machinaryTypes.map((e, i) => {
                                        return <Option value={e.key} key={"item_type__" + i}>{e.value}</Option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    }
                    {(itemForm.sell_type == "Rent") && <div className="form-group col-12 pt-2">
                        {/* <label className="mb-1 mt-1 heading">Manufecturer</label> */}
                        <div className="col-12">
                            <label className="search-label">Preço {itemForm.sell_type == 'Rent' ? "(por dia)" : ''}</label>
                            <Select
                                showSearch
                                // size="middle"
                                placeholder="Selecionar preço"
                                optionFilterProp="children"
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                value={itemForm.mileage}

                                onChange={(e) => {
                                    if (e == '-1') {
                                        setValue('max_price', '1000000000');
                                        setValue('min_price', e)
                                    } else {
                                        setValue('max_price', e)
                                        setValue("min_price", 0);
                                    }
                                    //getFilterCount();
                                }}
                            >
                                <Option value="">Selecionar preço</Option>
                                {
                                    rentPriceList.map((val, i) => {
                                        return <Option value={val.val} key={"price_range_" + i}>
                                            {val.text}
                                        </Option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>}


                    {itemForm.category_id == SERVICES && <div className="form-group col-6 pt-2">
                                            
                                            <div className="col-12">
                                            <label className="search-label">Área de serviço</label>
                                            <Select
                                                        showSearch
                                                        size="middle"
                                                        placeholder={"Selecione a área de serviço"}
                                                        optionFilterProp="children"
                                                        style={{ width: "100%" }}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                        value={itemForm.service_area}
                                                        onChange={(e) => {
                                                            setValue("service_area", e);
                                                        }}
                                                    >
                                                        {
                                                            serviceAreas.map((val) => {
                                                                return <Option value={val.key}>{val.value}</Option>
                                                            })
                                                        }
                                                    </Select>
                                            </div>
                                        </div>}

                    {(itemForm.sell_type != "Rent") && <div className="form-group col-6 pt-2">
                        {/* <label className="mb-1 mt-1 heading">Manufecturer</label> */}
                        <div className="col-12">
                            <label className="search-label">Provincia</label>
                            <Select
                                showSearch
                                // size="middle"
                                placeholder="Selecionar Província"
                                optionFilterProp="children"
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                value={itemForm.item_location_id}

                                onChange={(e) => {
                                    setValue('item_location_id', e);
                                    //getFilterCount();
                                }}
                            >
                                <Option value="">Selecionar Provincia</Option>
                                {
                                    [
                                        ...location.data

                                    ].map((val, i) => {
                                        return <Option value={val.id} key={"item_location_" + i}>
                                            {val.name}
                                        </Option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>}

                   

                    {/* {(itemForm.category_id != MOTOR_BIKES &&
                        itemForm.category_id != TRUCKS &&
                        itemForm.category_id != PARTS &&
                        itemForm.category_id != CRASHED_CARS &&
                        itemForm.category_id != BOATS &&
                        itemForm.category_id != OTHERS &&
                        itemForm.category_id != NAUTICAL) && <div className="form-group col-6 mt-2">
                           
                            <Input type={'number'} size="medium" placeholder="Year" onChange={(event) => {
                                let val = event.target.value;
                                setValue('year', val);
                            }} prefix={<FaCalendar />} />
                        </div>}
                   */}

                    <div className='col-12 pt-3 search-btns'>
                        <div className='row'>
                            <div className='col-6'>
                                {(itemForm.sell_type != "Rent" && itemForm.category_id != TRUCKS) && itemForm.category_id != SERVICES && <Link to={location=>{
                                    // console.log("item",location);
                                    return ({...location, pathname:'search_item/'+itemForm.category_id})} }>
                                    <button style={{ width: "100%", color: "#1370b8", fontWeight: 'bold' }} className='btn' onClick={() => {
                                        // console.log("MJ Here here");
                                        // history.goForward('/search_item');
                                        // history.push({ pathname: "search_item/" + convertToBase64(JSON.stringify(itemForm.category_id)) });
                                    }}>
                                        <i className='fa fa-sliders'></i>
                                        Filtro Avançado
                                    </button>
                                </Link>}
                            </div>
                            <div className='col-6'>
                                <button style={{ width: "100%", fontWeight: "bold" }} className='btn btn-primary'
                                    onClick={() => {
                                        submitSearch();
                                    }}>
                                    <i className='fa fa-search'></i>

                                    Ver {filterCount.isLoading ?
                                        <span className="m-2">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </span>
                                        : filterCount.count} Anúncios
                                    {/* {filterCount.isLoading && } */}

                                </button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>;
}

export default SearchForm;