import { useEffect, useState } from "react";
import AppFooter from "../Theme/AppFooter";
import AppHeader from "../Theme/AppHeader";
import FooterContactUsForm from "../Theme/FooterContactUsForm";
import { createNotification } from "./AppNotification";

const AppLayout = ({ component: Page, ...rest }) => {
    // console.log(Component);
    // console.log(...rest);
    let check = 0;
    useEffect(() => {
        // console.log('here');
        // if (check == 0) {
        //     window.genralPageLoad();
        //     console.log(check);
        //     check = 1;
        // }

    }, []);
    return (
        <div>
            {/* <button onClick={()=>{
                // NotificationManager.info("info","hello",12000,()=>{});
            }}></button> */}
            <AppHeader />
            <div>
                <div className="app-page-body">
                    {Page}
                </div>
            </div>

            <AppFooter />
            <FooterContactUsForm />

        </div>
    );
}

export default AppLayout;