import { Link } from "react-router-dom";
import { SERVICES } from "../../constants";
import { PREV_IMG } from "../../constants/endPoints";
import { convertNumber, convertToBase64, currencyFormat } from "../../constants/helper";

const ProductListItem = ({ val, i, loc, ...props }) => {
    return (
        <div className="sectls" key={i}>
            <Link to={"/product_details/" + convertToBase64(val.id)} className="sectls-img">
                <img src={PREV_IMG + val.default_photo.img_path} onError={(e) => { e.target.src = "/assets/images/placeholder.jpeg" }} />
            </Link>
            <div className="sectls-cont">
                <div className="sectls-ttl-wrap">
                    {/* Check if there is menufecturer then show manufacturer as title else show title */}

                    {
                        (val.manufacturer.name || val.manufacturer_id == -1) &&
                        <h3>
                            <Link to={"/product_details/" + convertToBase64(val.id)}>
                                {val.manufacturer_id == -1 ? val.manufacturer_name : val.manufacturer.name}
                                &nbsp;{val.model_id == -1 ? val.model_name : val.model.name}
                                &nbsp;{val.year}
                                &nbsp;
                                {/* {val.sell_type == "Rent" ? "Para alugar" : "Para venda"} */}

                            </Link>
                        </h3>
                    }
                    {
                        (!val.manufacturer.name && val.manufacturer_id != -1) &&
                        <h3><Link to={"/product_details/" + convertToBase64(val.id)}>{val.title}
                            &nbsp;({val.sell_type == "Rent" ? "Para alugar" : "Para venda"})</Link></h3>
                    }

                </div>
                <div className="sectls-price-wrap">
                    <p>Preço</p>
                    <p className="sectls-price">{currencyFormat(val.price)} {val.item_currency && val.item_currency.currency_symbol}</p>
                </div>
                {
                    val.fuel_type.fuel_name &&
                    <div className="sectls-qnt-wrap">
                        <p>Tipo de combustível</p>
                        <p className="sectls-price">{val.fuel_type.fuel_name}</p>

                    </div>
                }
                {
                    !val.fuel_type.fuel_name && val.category_id != SERVICES &&
                    <div className="sectls-qnt-wrap">
                        <p>Doença</p>
                        <p className="sectls-price">{val.condition_of_item.name}</p>

                    </div>
                }
                {
                    val.mileage && convertNumber(val.mileage) > 0 &&
                    <div className="sectls-total-wrap">
                        <p>Quilômetros</p>
                        <p className="sectls-total">{currencyFormat(val.mileage)} Km</p>
                    </div>
                }
            </div>
            <div className="sectls-info">
              
                <div className="sectls-rating-wrap">
                    <p className="sectls-rating">
                        Carregado
                    </p>
                    <p className="uploaded_txt">{val.added_date_str}</p>
                </div>
                { loc && loc.name && <div className="sectls-rating-wrap">
                    <p className="sectls-rating">
                        Província
                    </p>
                    <p className="uploaded_txt">{loc.name}</p>
                </div>}
                {
                    val.color.color_value &&
                    <p className="sectls-id">Cor <span className="badge bg-primary bg-sn badge_pos"> {val.color.color_value}</span></p>
                }
                

            </div>
        </div>
    );
}

export default ProductListItem;