import { Link } from "react-router-dom";

const DataNotFound = ({ hideTopBar }) => {
    return (
        <div>
            <main>

                {/* <!-- Breadcrumbs --> */}
                {
                    !hideTopBar && <div class="b-crumbs-wrap">
                        <div class="cont b-crumbs">
                            <ul>
                                <li>
                                    <a href="/">Motor</a>
                                </li>
                                <li>
                                    <span>No Data Found</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                }


                <div class="cont maincont">

                    <h1><span>Empty</span></h1>
                    <span class="maincont-line1 maincont-line12"></span>
                    <span class="maincont-line2 maincont-line22"></span>

                    {/* <!-- Error 404 --> */}
                    <div class="pagecont err404">
                        <img src="/external_assets/img/err404.png" alt="" class="err404-img" />
                        <p>We are so sorry, we cannot find related data</p>
                        <form action="#">
                            {/* <input type="text" placeholder="Search.." />
                            <button type="submit"><i class="fa fa-search"></i></button> */}
                        </form>
                    </div>

                </div>

            </main>
        </div>
    );
}

export default DataNotFound;