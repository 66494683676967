import { Space, Spin } from "antd";

const AndtLoader = ({size = 'middle', ...props}) => {
    return (
        <Space size={size} style={{ textAlign: 'center', color: '#c90000' }}>
            <Spin size='default' style={{color:'#c90000'}}  />
        </Space>
    );
}

export default AndtLoader;