const EmptyLayout = ({ message, ...props }) => {
    return (
        <div className="empty-layout">
            <div className="content">
                <div>
                    <img src="./assets/images/broken_file.png" />
                </div>
                <h6 className="heading-font message">{message}</h6>
            </div>
        </div>
    );
}

export default EmptyLayout;