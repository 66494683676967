import { getFromStorage } from "../Hooks/useLocalStorage";
import { convertToBase64 } from "./helper";

export function isLogin(){
    try{
        let user = getFromStorage("user");
        if(user.user_id){
            return true;
        }else{
            return false;
        }
    }catch(e){
        return false;
    }
}
export function logout(){
    try{
        localStorage.removeItem(convertToBase64("user"));
        return true;
    }catch(e){
        return false;
    }
}
export function getCurrentUser(){
    try{
        let user = getFromStorage("user");
        if(isLogin){
            return user;
        }else{
            logout();
            return null;
        }
    }catch(e){
        logout();
        return null;
        
    }
}
export function getCurrentUserId(){
    try{
        let user = getFromStorage("user");
        // console.log(user);
        if(isLogin()){
            return user.user_id;
        }else{
            return null;
        }
    }catch(e){
        // console.log("id error",e);
        return null;
    }
}
