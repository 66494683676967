const initalState = {
    blog: { isLoading: false, data: [] },
};
export const blogReducer = (state = initalState.blog, action) => {
    switch (action.type) {
        case 'DATA':
            return {
                ...state,
                ...action.blog
            };
        case 'ERROR':
            return state;
        default:
            return state;
    }
}
