const initalState = {
    ads: { isLoading: false, data: [] },
};
export const adsReducer = (state = initalState.ads, action) => {
    switch (action.type) {
        case 'DATA':
            return {
                ...state,
                ...action.ads
            };
        case 'ERROR':
            return state;
        default:
            return state;
    }
}
