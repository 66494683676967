import { BOATS, NAUTICAL, OTHERS, PARTS, SERVICES } from ".";

const SALT = "mjcoders.com(motor)";
const crypt = (text) => {
    const salt = SALT;
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
};
const decrypt = (encoded) => {
    const salt = SALT;
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
};
export function convertToBase64(str) {
    try {
        let encode = window.btoa(unescape(encodeURIComponent(crypt(str))));
        return encode;
    } catch (e) {
        console.log(e);
        return null;
    }
}
export function convertFromBase64(str) {
    try {
        let decode = decrypt(decodeURIComponent(escape(window.atob((str)))));
        return decode;
    } catch (e) {
        return null;
    }
}
export function setLoadingState(mdata, state, res = null) {
    mdata.isLoading = state;
    if (res != null) {
        mdata.data = res;
    }
    return mdata;
}
// CHECK IF PRODUCT IS BOAD OR other or nautical or parts
export function chkBOPN(id) {
    if (id == BOATS || id == OTHERS || id == NAUTICAL || id == PARTS || id == SERVICES) {
        return true;
    } else {
        return false;
    }
}
// CHECK IF PRODUCT IS other or nautical or parts
export function chkOPN(id) {
    if (id == OTHERS || id == NAUTICAL || id == PARTS || id == SERVICES) {
        return true;
    } else {
        return false;
    }
}

export function sortingUserId(loginUserId, itemAddedUserId) {
    if (loginUserId.localeCompare(itemAddedUserId) === 1) {
        return `${itemAddedUserId}_${loginUserId}`;
    } else if (loginUserId.localeCompare(itemAddedUserId) == -1) {
        return `${loginUserId}_${itemAddedUserId}`;
    } else {
        return `${loginUserId}_${itemAddedUserId}`;
    }
}
export function currencyFormat(val){
    try{
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        let amount = Number(val);
        return formatter.format(amount).toString().replace('$', '');
    }catch(e){
        return 0;
    }  
}
export const convertNumber = (string) => {
    try{
        const number = parseInt(string);
        return number ? number : 0;
    }catch(e){
        console.log(e);
        return 0;
    }
}
export const convertDouble = (string) =>{
    try{
        const number = parseFloat(string);
        return number ? number : 0;
    }catch(e){
        console.log(e);
        return 0;
    }
}
export const createItemUrl = (val) => {
    let title = '';
    let sprationChar = '-';
    if (val.manufacturer.name || val.manufacturer_id == -1) {
        let menuTitle = val.manufacturer_id == -1 ? val.manufacturer_name : val.manufacturer.name;
        if (menuTitle) { title += menuTitle; title += sprationChar; }
    }
    if (val.model.name || val.model_id == -1) {
        let _model = val.model_id == -1 ? val.model_name : val.model.name;
        if (_model) { title += _model; title += sprationChar; };
    }
    if (val.year) title += val.year;
    if(val.title) title+= sprationChar+val.title;
    title = title.replaceAll(' ','_');
    if(title.length > 0) return "/"+title;
    return title;
    
}
