import { Link, useHistory } from "react-router-dom";
import { SERVICES } from "../../constants";
import { PREV_IMG } from "../../constants/endPoints";
import { convertToBase64, currencyFormat } from "../../constants/helper";
const ProductListRentCarItem = ({ val, i, loc, ...props }) => {
    const history = useHistory();
    const goToProductDetail = () => {
        history.push("/product_details/" + convertToBase64(val.id));
    }
    return (<div className="sectls cursor-pointer" key={i} onClick={goToProductDetail}>
        <div className="sectls-img">
            <img src={PREV_IMG + val.default_photo.img_path} onError={(e) => { e.target.src = "/assets/images/placeholder.jpeg" }} />
        </div>
        <div className="sectls-cont">
            <div className="ps-3">
                {/* Check if there is menufecturer then show manufacturer as title else show title */}

                {
                    <div className="row">
                        {(val.manufacturer.name || val.manufacturer_id == -1) &&
                            <div className="sectls-itm col-auto" >
                                <label className="ttl">Marca</label>
                                <p className="sectls-val">{val.manufacturer_id == -1 ? val.manufacturer_name : val.manufacturer.name}</p>
                            </div>}
                        {(val.manufacturer.name || val.manufacturer_id == -1) &&
                            <div className="sectls-itm col-auto" >
                                <label className="ttl">Modelo</label>
                                <p className="sectls-val">{val.model_id == -1 ? val.model_name : val.model.name}</p>
                            </div>}
                        {(val.transmission.name) &&
                            <div className="sectls-itm col-auto" >
                                <label className="ttl">Caixa</label>
                                <p className="sectls-val">{val.transmission.name}</p>
                            </div>}
                        {(val.fuel_type.fuel_name) &&
                            <div className="sectls-itm col-auto" >
                                <label className="ttl">Tipo de Combustivel</label>
                                <p className="sectls-val">{val.fuel_type.fuel_name}</p>
                            </div>}
                        {(false) &&
                            <div className="sectls-itm col-auto" >
                                <label className="ttl">Lugares</label>
                                <p className="sectls-val">{val.fuel_type.fuel_name}</p>
                            </div>}
                        {(val.is_driver) &&
                            <div className="sectls-itm col-auto" >
                                <label className="ttl">Motorista</label>
                                <p className="sectls-val">{val.is_driver}</p>
                            </div>}
                        {(val.price) &&
                            <div className="sectls-itm col-auto" >
                                <label className="ttl">Preço Diario</label>
                                <p className="sectls-val">{currencyFormat(val.price)} {val.item_currency && val.item_currency.currency_symbol}</p>
                            </div>}
                            {(val.max_passengers) && val.max_passengers > 0 &&
                            <div className="sectls-itm col-auto" >
                                <label className="ttl">Lugares</label>
                                <p className="sectls-val">{val.max_passengers}</p>
                            </div>}
                    </div>

                }
            </div>
        </div>
        <div className="sectls-info">
            <div className="sectls-rating-wrap">
                <p className="sectls-rating">
                    Carregado
                </p>
                <p className="uploaded_txt">{val.added_date_str}</p>
            </div>
            {val.phone_number && <div className="sectls-rating-wrap">
                <p className="sectls-rating">
                    Tel:
                </p>
                <p className="uploaded_txt">{val.phone_number}</p>
            </div>}
            {loc && loc.name && <div className="sectls-rating-wrap">
                <p className="sectls-rating">
                    Provincia
                </p>
                <p className="uploaded_txt">{loc.name}</p>
            </div>}
            <div className="sectls-rating-wrap">

            <p className="sectls-rating">Cor <span className="badge bg-primary bg-sn badge_pos"> {val.color.color_value}</span></p>
               
            </div>
            {
                val.description &&
                <p className="sectls-id sectls-desc">Descrição: {val.description} </p>
            }
            

        </div>
    </div>);
}

export default ProductListRentCarItem;