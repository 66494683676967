const initalState = {
    mjglobal: {
        selectedCategory: 1
    },
};
export const generalReducer = (state = initalState.mjglobal, action) => {
    // console.log(state, action);
    switch (action.type) {
        case 'selectedCategory':
            return {
                ...state,
                selectedCategory: action.selectedCategory

            };
        default:
            return state;
    }
}
