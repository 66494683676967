const initalState = {
    category: { isLoading: false, data: [] },
};
export const categoryReducer = (state = initalState.category, action) => {
    switch (action.type) {
        case 'DATA':
            return {
                ...state,
                ...action.category
            };
        case 'ERROR':
            return state;
        default:
            return state;
    }
}
