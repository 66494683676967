import { PREV_IMG } from "../../constants/endPoints";

const MotorAd = ({ ad, ...props }) => {
    return (<div className="getspec-wrap cursor-pointer" onClick={() => {
        window.open(
            ad.url,
            '_blank' // <- This is what makes it open in a new window.
        );
    }}>
        <div className="cont getspec">
            <div className="getspec-cont">
                <h3>{ad.name}</h3>
                <p>{ad.description}</p>
                {/* <form action="#" className="form-validate">
                <input data-required="text" data-required-email="email" type="text" placeholder="Email address" name="email3" />
                <input type="submit" value="Get a special deals" />
            </form> */}
            </div>
            <div href="#" className="getspec-img">
                {
                    ad.image && <img src={PREV_IMG + ad.image.img_path} alt="" />

                }
            </div>
        </div>
    </div>);
}

export default MotorAd;