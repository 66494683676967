
import React, { useState, useEffect, useCallback } from 'react';
import { BASE_URL, editProfile, getAddItemBuildTypes, getAddItemColors, getAddItemModel, getAddItemTransmissions, getAdsUrl, getBlogsUrl, getCategoriesUrl, getItemsUrl, getLocationsUrl, getMenufecturerUrl, getRecentProductsUrl, PREV_IMG } from '../../constants/endPoints';
import { getData, postData } from '../../api';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { getItemData } from '../../Hooks/useItemParams';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faMapMarked, faMapPin, faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import useConsole from '../../Hooks/useConsole';
import BlogSlider from './BlogSlider';
import { convertFromBase64, convertToBase64 } from '../../constants/helper';
import { Link } from 'react-router-dom';
import ProductItem from './ProductItem';
import { Drawer, Input, message, Modal, notification, Select, Space, Spin } from 'antd';
import { FaUserAlt, FaCalendar } from 'react-icons/fa';
import { Option } from 'antd/lib/mentions';
import HomeSlider from './HomeSlider';
import SearchForm from './SearchForm';
import IntroSlider from './IntroSlider';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateHomePopularProducts, updateHomeRecentProducts } from '../../ReduxStore/actions/productActions'
import { updateCategories } from '../../ReduxStore/actions/categoryActions';
import { updateBlogs } from '../../ReduxStore/actions/blogAction';
import { updateMenufecturers, updateSearchMenufecturers } from '../../ReduxStore/actions/menufacturerAction';
import { updateLocation } from '../../ReduxStore/actions/locationAction'
import { CARS, CRASHED_CARS, NO_ITEM_FOUND } from '../../constants';
import EmptyLayout from '../EmptyLayout';
import MotorAd from './MotorAd';
import { updateAds } from '../../ReduxStore/actions/adsAction';
import { isLogin } from '../../constants/sessionHelper';
import useLocalStorage from '../../Hooks/useLocalStorage';
import { getCurrentUser } from '../../constants/sessionHelper';
import NoMotorAd from '../Components/NoMotorAd';
import { updateSelectedCategory } from '../../ReduxStore/actions/generalActions';
// import { Spinner } from 'bootstrap';
const Home = (props) => {
    const [categories, setCategories] = useState({ data: [], isLoading: false });
    const [recentProducts, setRecentProducts] = useState({ data: [], isLoading: false });
    const [popularProducts, setPopularProducts] = useState({ data: [], isLoading: false });
    const [menufecturers, setMenufecturers] = useState({ data: [], isLoading: false });
    const [blogs, setBlogs] = useState({ data: [], isLoading: false });
    const history = useHistory();
    // let navigate = useNav
    const [drawer, setDrawer] = useState(false);
    const [type, setType] = useState('');

    const [priceRange, setPriceRange] = useState([]);
    const [searchManus, setSearchManu] = useState([]);
    const [searchModels, setSearchModel] = useState({ isLoading: false, data: [] });
    const [transmissions, setTransmissions] = useState([]);
    // transmissions
    const [searchCategory, setSearchCategory] = useState(null);
    const [searchManufecturers, setSearchManufecturers] = useState([]);
    const [buildTypes, setBuildTypes] = useState([]);
    const [colors, setColors] = useState([]);
    const [localStorageUser, setLocalStorageUser] = useLocalStorage('user');
    const [userDialog, setUserDialog] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [editUser, setEditUser] = useState(getCurrentUser());

    // const [searchType, setSearchType] = useState(1);
    const formData = getItemData();
    const [userName, setUserName] = useState('');
    const [itemForm, setItemForm] = useState({ ...formData, sell_type: "Sell", category_id: null });
    // props.updateSelectedCategory(1);
    const setValue = (key, val) => {
        setItemForm((prev) => {
            return {
                ...prev,
                [key]: val
            }
        }
        );
    }
    const getCategories = async () => {
        try {
            props.updateCategory({ isLoading: true });
            const res = await getData(getCategoriesUrl("get", 30, 0));
            props.updateCategory({ isLoading: false, data: res.data });
        } catch (e) {
            props.updateCategory({ isLoading: false });
        }
    }
    const getBlogs = async () => {
        try {
            props.updateBlog({ isLoading: true });
            const res = await getData(getBlogsUrl("get", 30, 0));
            props.updateBlog({ isLoading: false, data: res.data });
        } catch (e) {
            props.updateBlog({ isLoading: false });
        }
    }
    const getMenufecturers = async (categoryId) => {
        try {
            setValue("manufacturer_id", "");
            props.updateMenufecturer({ isLoading: true });
            props.updateSearchMenufecturer({ isLoading: true });
            const res = await getData(getMenufecturerUrl(categoryId, "get", 1000, 0));
            props.updateMenufecturer({ data: res.data, isLoading: false });
            props.updateSearchMenufecturer({ isLoading: false, data: res.data });
            // changeSearchCategory(itemForm.category_id);
        } catch (e) {
            props.updateSearchMenufecturer({ data: [], isLoading: false });
        }
    }
    const getBuidType = async () => {
        try {
            //2
            let res = await getData(getAddItemBuildTypes("get", 500, 0));
            if (res.data) {
                setBuildTypes(res.data);
            }
        } catch (e) {
        }
    }
    const getTransmissions = async () => {

    }

    const getPopularProducts = async (catId = null) => {
        try {
            props.updateHomePopularProduct({ isLoading: true });
            let itemParams = getItemData();
            itemParams.order_by = "touch_count";
            itemParams.is_paid = "";
            if (catId) itemParams.category_id = catId;
            // console.log(item Params);
            const res2 = await postData(getItemsUrl("search", 12, 0), itemParams);
            if (!res2.data) {
                props.updateHomePopularProduct({ data: [], isLoading: false });
                return;
            }
            props.updateHomePopularProduct({ isLoading: false, data: res2.data });
        } catch (e) {
            props.updateHomePopularProduct({ isLoading: false });
        }
    }
    const getLocation = async () => {
        try {
            props.updateLocation({ isLoading: true });
            const res2 = await getData(getLocationsUrl("get", 100, 0));
            if (!res2.data) {
                notification.error({
                    message: 'Error',
                    description: 'cannot get location data'
                });
                props.updateLocation({ isLoading: false });
                return;
            }
            props.updateLocation({ isLoading: false, data: res2.data });
        } catch (e) {
            props.updateLocation({ isLoading: false });
        }
    }
    const getRecentProducts = async (catId = null) => {
        try {
            props.updateHomeRecentProduct({ isLoading: true });
            let itemParams = getItemData();
            itemParams.order_by = "added_date";
            itemParams.is_paid = "paid_item_first";
            if (catId) itemParams.category_id = catId;
            const res2 = await postData(getItemsUrl("search", 12, 0), itemParams);
            if (!res2.data) {
                props.updateHomeRecentProduct({ data: [], isLoading: false });
                return;
            }
            props.updateHomeRecentProduct({ isLoading: false, data: res2.data });
        } catch (e) {
            props.updateHomeRecentProduct({ isLoading: false });

        }
    }
    const getAds = async () => {
        try {
            props.updateAds({ isLoading: true });
            const res2 = await getData(getAdsUrl());
            if (!res2.data) {
                props.updateAds({ data: [], isLoading: false });
                return;
            }
            props.updateAds({ isLoading: false, data: res2.data.response });
        } catch (e) {
            props.updateAds({ isLoading: false });

        }
    }
    const getCategoryItemsByType = (fnData) => {
        let itmData = getItemData();
        itmData.category_id = fnData.category_id;
        itmData.item_type = type;
        history.push({ pathname: "products", itemData: itmData, category_name: fnData.category_name });
    }


    const getModels = async (id) => {
        try {
            setSearchModel({ ...searchModels, isLoading: true });
            let res = await getData(getAddItemModel("get", 500, 0, id));
            if (res.data) {
                setSearchModel({ isLoading: false, data: res.data });
            }
        } catch (e) {
            setSearchModel({ isLoading: false, data: [] });
        }
    }

    const changeSearchCategory = (categoryId) => {
        props.updateSelectedCategory(categoryId);
        // RESET FORM ON EVERY CATEGORY UPDATE FOR BETTER SEARCH RESULTS
        setItemForm({...formData, category_id: categoryId, sell_type: itemForm.sell_type});
        // RESET FORM END
        if (categoryId != itemForm.category_id) {
            getPopularProducts(categoryId);
            getRecentProducts(categoryId);
        }
        if (categoryId) {
            let mList = [];
            if (!props.menufecturer) {
                // console.log("here");
                return;
            }
            // if (props.menufecturer.data.length < 1) {
            //     console.log("here3");
            //     return;
            // }
            if (categoryId == CRASHED_CARS) {
                categoryId = CARS;
            }
            getMenufecturers(categoryId);
        }
    }

    // const getColors = async () => {
    //     try {
    //         // 3
    //         let res = await getData(getAddItemColors("get", 500, 0));
    //         if (res.data) {
    //             setColors(res.data);
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    const getPageData = useCallback(
        () => {
            checkLogin();
            getCategories();
            console.log(props.mjglobal.selectedCategory);
            // changeSearchCategory(props.mjglobal.selectedCategory);
            // getMenufecturers(props.mjglobal.selectedCategory);
            // getRecentProducts(props.mjglobal.selectedCategory);
            // getPopularProducts(props.mjglobal.selectedCategory);
            getBlogs();
            // getColors();
            getTransmissions();
            getBuidType();
            getLocation();
            getAds();
            setTimeout(() => {
                window.onHome();
            });

        },
        [],
    )
    const checkLogin = () => {
        if (isLogin()) {
            if (getCurrentUser()) {
                let mUser = getCurrentUser();
                setCurrentUser(mUser);
                setEditUser(mUser);
            }
        }
    }
    const showProfileModel = () => {
        if (currentUser.user_id == null) {
            return;
        }
        setUserDialog(true);
    }
    const hideProfileModel = () => {
        setUserDialog(false);
    }
    const updateProfile = async () => {
        setEditUser(state => { return { ...state, is_loading: true } });
        let payload = { ...editUser, "is_show_email": "1", "is_show_phone": "1", "user_address": "", "city": "" }
        let response = await postData(editProfile("search", 40, 0), { ...payload }, true);
        setEditUser(state => { return { ...state, is_loading: false } });

        // setIsUpdating(false);
        if (response) {
            if (response.data) {
                hideProfileModel();
                let _user = response.data;
                setLocalStorageUser(_user);
            }
        }

    }
    useEffect(() => {
        if (!currentUser.user_name) {
            showProfileModel();
            return;
        }
        if (currentUser.user_name.length < 1) {
            showProfileModel();
            return;
        }
    }, [currentUser])
    // useEffect(() => {
    //     if (props.menufecturer.data.length > 0)
    //         changeSearchCategory(props.mjglobal.selectedCategory);
    // }, [props.menufecturer])
    useEffect(() => {
        if (props.mjglobal.selectedCategory) {
            // console.log("category_id", props.mjglobal.selectedCategory);
            setValue("category_id", props.mjglobal.selectedCategory);
            changeSearchCategory(props.mjglobal.selectedCategory);
        }
    }, [props.mjglobal.selectedCategory])
    const onDrawerClose = () => {
        setDrawer(false);
        setType('');
    }

    useEffect(async () => {
        getPageData();
        // console.log(props.mjglobal.selectedCategory);
        // changeSearchCategory(props.mjglobal.selectedCategory);
    }, [])
    return (
        <div className="home-page">
            <Modal title="Please enter you name" visible={userDialog} onOk={() => {
                if (!editUser.user_name) {
                    message.error('name cannot be empty');
                    return;
                }
                if (!editUser.user_email) {
                    message.error('email cannot be empty');
                    return;
                }
                if (!editUser.user_about_me) {
                    message.error('tell us somthing about you!');
                    return;
                }
                updateProfile();
            }} okButtonProps={{ disabled: editUser ? editUser.is_loading : false }} okText={"Submit"} onCancel={hideProfileModel}>
                <div>
                    {currentUser.user_id && <form onSubmit={(e) => { e.preventDefault() }}>
                        <h2>Tell us about yourself? </h2>
                        {currentUser.user_name}
                        {!currentUser.user_name &&
                            <Input placeholder='Full Name' className='mb-1' value={editUser.user_name} onChange={(ev) => {
                                setEditUser({ ...editUser, user_name: ev.target.value });
                            }} required={true} />}

                        {!currentUser.email &&
                            <Input placeholder='Email' className='mb-1' value={editUser.email} onChange={(ev) => {
                                setEditUser({ ...editUser, user_email: ev.target.value });
                            }} required={true} />}
                        {!currentUser.email &&
                            <Input placeholder='About you' className='mb-1' value={editUser.user_about_me} onChange={(ev) => {
                                setEditUser({ ...editUser, user_about_me: ev.target.value });
                            }} required={true} />}
                    </form>}
                </div>
            </Modal>
            <main>
                <div style={
                    {
                        position: 'fixed',
                        bottom: 40,
                        right: 40,
                        display: 'flex',
                        cursor: 'pointer',
                        zIndex: 3,
                        border: "none"
                    }
                }>
                    <Link className='fab-submit-btn' to="/add_item">Enviar um anúncio?</Link>
                </div>
                <HomeSlider />
                {/* HERE Search */}
                <div className='search-div container mcontainer mt-4 mb-4'>
                    <div className="row">
                        {/* <div className='col-md-2'></div> */}
                        <div className='col-md-6 '>
                            <SearchForm itemForm={itemForm}
                                setValue={setValue}
                                categories={props.category}
                                changeSearchCategory={changeSearchCategory}
                                selectedCategory={props.mjglobal.selectedCategory}
                                getModels={getModels}
                                searchModels={searchModels}
                                searchManus={props.search_menufecturer}
                                colors={colors}
                                buildTypes={buildTypes}
                                location={props.location}
                                // isLoading={props.cat}
                                // submitSearch={submitSearch} 
                                transmissions={transmissions} />
                                <div>
                                    <span className='payment-note'>Atenção: Informamos que não devem ser feitos pagamento sem antes confirmarem a veracidade do produto bem como a documentação do mesmo. Não nos responsabilizamos pela negociação entre vendedor e comprador.</span>
                                </div>

                        </div>
                        <div className='col-md-6'>
                            <IntroSlider />
                        </div>
                    </div>

                </div>
                {/* End Search */}

                {/* <div className="cont specials">
                    <h2>Menufecturers</h2>
                    <div className="row mob-scroll mob-scroll-row-h180">
                        {
                            menufecturers.isLoading &&
                            <div className="d-flex justify-content-center align-items-center mt-8">
                                <Spinner animation="grow" />
                            </div>
                        }
                        {
                            menufecturers.data &&
                            menufecturers.data.map((val, i) => {

                                return i < 18 && <div className="col-lg-2 col-md-2 col-sm-4 mob-scroll-item-h180" onClick={() => { getMenufecturerItems(val.id) }}>
                                    <div className="home-menuf-item d-flex flex-column justify-content-center align-items-center">
                                        <div className="home-menuf-img">
                                            <img className="home-menuf-img" src={PREV_IMG + val.default_icon.img_path} onError={(e) => { e.target.src = "./assets/images/placeholder.jpeg" }} alt="" />
                                        </div>
                                        <p className='home-menuf-name'>{val.name}</p>
                                    </div>
                                </div>
                            })
                        }

                    </div>
                </div> */}


                {/* <!-- Frontpage Filter --> */}


                {/* <!-- Special Deals Items --> */}
                <div className="specials-wrap">
                    <div className="cont specials">
                        <h2>PRODUTOS RECENTES</h2>
                        <p className="specials-count">{props.home_recent_product.data && "TOTAL DE ITENS: " + props.home_recent_product.data.length}</p>
                        <div className="specials-list">
                            <div className="special special-pseudo">
                                <a href="#" className="special-link"></a>
                            </div>
                            {
                                props.home_recent_product &&
                                (props.home_recent_product.isLoading &&
                                    props.home_recent_product.data.length < 1) &&
                                <div className="d-flex justify-content-center align-items-center mt-8">
                                    <Spinner animation="grow" />
                                </div>
                            }
                            <div className="row mob-scroll mob-scroll-row-h330">
                                {
                                    props.home_recent_product &&
                                        props.home_popular_product.data.length > 0 ?
                                        props.home_recent_product.data.map((val, i) => {
                                            return <ProductItem val={val} index={i} type="recent" />
                                        }) : (!props.home_recent_product.isLoading) && <div className='pt-4'>
                                            <EmptyLayout message={NO_ITEM_FOUND} />
                                        </div>
                                }
                            </div>
                        </div>
                        {/* <p className="special-more">
                            <Link to="/products">mostre mais</Link>
                        </p> */}
                        <span className="special-line1"></span>
                        <span className="special-line2"></span>
                    </div>
                </div>
                {/* AD */}
                {
                    props.ads &&
                        props.ads.data.length < 1 ? <NoMotorAd /> :
                        props.ads.data.map((val, i) => {
                            if (i == 0) {
                                return <MotorAd ad={val} />
                            } else {
                                return;
                            }
                        })
                }


                {/* Get Popular Product */}
                <div className="specials-wrap">
                    <div className="cont specials">
                        <h2>PRODUTOS POPULARES</h2>
                        <p className="specials-count">{props.home_popular_product && "Total de Itens: " + props.home_popular_product.data.length}</p>
                        <div className="specials-list">
                            <div className="special special-pseudo">
                                <a href="#" className="special-link"></a>
                            </div>
                            {
                                props.home_popular_product &&
                                (props.home_popular_product.isLoading &&
                                    props.home_popular_product.data.length < 1) &&
                                <div className="d-flex justify-content-center align-items-center mt-8">
                                    <Spinner animation="grow" />
                                </div>
                            }
                            <div className="row mob-scroll mob-scroll-row-h330">
                                {
                                    props.home_popular_product &&
                                        props.home_popular_product.data.length > 0 ?
                                        props.home_popular_product.data.map((val, i) => {
                                            return <ProductItem val={val} index={i} type="popular" />
                                        }) : (!props.home_popular_product.isLoading) && <div className='pt-4'>
                                            <EmptyLayout message={NO_ITEM_FOUND} />
                                        </div>
                                }
                            </div>
                        </div>
                        {/* <p className="special-more">
                            <Link to="/products">mostre mais</Link>
                        </p> */}
                        <span className="special-line1"></span>
                        <span className="special-line2"></span>
                    </div>
                </div>


                {/* <!-- Get a Special Deals --> */}
                {/* First Ad */}
                {
                    props.ads &&
                        props.ads.data.length < 2 ? <NoMotorAd /> :
                        props.ads.data.map((val, i) => {
                            if (i == 1) {
                                return <MotorAd ad={val} />
                            } else {
                                return;
                            }
                        })
                }
                <div className="row m-0 p-0">
                    <BlogSlider blogs={props.blog} />
                </div>

                {/* <!-- Frontpage Article --> */}
                <div className="botarticle-wrap">

                    <div className="cont botarticle">
                        <div className="botarticle-cont">
                            <h3>Reis da sujeira</h3>
                            <p>Melhor equipamento de motocross</p>
                        </div>
                        <a href="blog.html" className="botarticle-img">
                            <img src="/external_assets/img/article1.png" alt="" />
                        </a>
                    </div>
                </div>


            </main>
            <Drawer
                title={"For " + type}
                placement={'bottom'}
                closable={true}
                onClose={onDrawerClose}
                visible={drawer}
                height={500}
                key={'bottom'}
            >
                <div className='cont home-cat'>
                    <div className="row type-cat">
                        {
                            categories.data &&
                            categories.data.map((val, i) => {
                                return <div onClick={() => { getCategoryItemsByType(val) }} className="col-sm-3 col-lg-2 col-md-3 mob-scroll-item-h180 mb-4" key={i} >
                                    <div className="d-flex justify-content-center flex-column align-items-center">
                                        <div className="frontcategs-img">
                                            <img src={BASE_URL + val.category_image} alt="" width={90} onError={(e) => { e.target.src = "./assets/images/placeholder.jpeg" }} />
                                        </div>
                                        <div>
                                            <label>{val.category_name}</label>
                                        </div>

                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </Drawer>
        </div>
    );
}
// REDUX IMPLEMENTATION
const mapRecentProductsAction = (dispatch) => {
    return {
        updateHomeRecentProduct: (_data) => dispatch(updateHomeRecentProducts(_data))
    }
}
const mapRecentProductsWithProps = (state) => {
    return {
        // return {
        home_recent_product: state.home_recent_product,
        // }
    };
}
const mapPopularProductsAction = (dispatch) => {
    return {
        updateHomePopularProduct: (_data) => dispatch(updateHomePopularProducts(_data))
    }
}
const mapPopularProductsWithProps = (state) => {
    return {
        home_popular_product: state.home_popular_product,
    };
}
const mapCategoryAction = (dispatch) => {
    return {
        updateCategory: (_data) => dispatch(updateCategories(_data))
    }
}

const mapCategoriesWithProps = (state) => {
    return {
        category: state.category,
    };
}
const mapBlogsWithProps = (state) => {
    return {
        blog: state.blog,
    };
}
const mapBlogAction = (dispatch) => {
    return {
        updateBlog: (_data) => dispatch(updateBlogs(_data))
    }
}
const mapMenufecturersWithProps = (state) => {
    return {
        menufecturer: state.menufecturer,
    };
}
const mapMenufecturerAction = (dispatch) => {
    return {
        updateMenufecturer: (_data) => dispatch(updateMenufecturers(_data))
    }
}

const mapSearchMenufecturersWithProps = (state) => {
    return {
        search_menufecturer: state.search_menufecturer,
    };
}
const mapSearchMenufecturerAction = (dispatch) => {
    return {
        updateSearchMenufecturer: (_data) => dispatch(updateSearchMenufecturers(_data))
    }
}
const mapLocaitonWithProps = (state) => {
    return {
        location: state.location,
    }
}
const mapLocationAction = (dispatch) => {
    return {
        updateLocation: (_data) => dispatch(updateLocation(_data)),
    }
}
const mapAdsWithProps = (state) => {
    return {
        ads: state.ads,
    }
}
const mapAdsAction = (dispatch) => {
    return {
        updateAds: (_data) => dispatch(updateAds(_data)),
    }
}

const mapSelectedCategoryAction = (dispatch) => {
    return {
        updateSelectedCategory: (_data) => dispatch(updateSelectedCategory(_data)),
    }
}
const mapSelectedCategoryProps = (state) => {
    return {
        mjglobal: state.mjglobal,
    }
}
export default compose(
    connect(mapRecentProductsWithProps, mapRecentProductsAction),
    connect(mapPopularProductsWithProps, mapPopularProductsAction),
    connect(mapCategoriesWithProps, mapCategoryAction),
    connect(mapBlogsWithProps, mapBlogAction),
    connect(mapMenufecturersWithProps, mapMenufecturerAction),
    connect(mapSearchMenufecturersWithProps, mapSearchMenufecturerAction),
    connect(mapLocaitonWithProps, mapLocationAction),
    connect(mapAdsWithProps, mapAdsAction),
    connect(mapSelectedCategoryProps, mapSelectedCategoryAction),
)(Home);

