const initalState = {
    home_recent_product: { isLoading: false, data: [] },
    home_popular_product: { isLoading: false, data: [] },
};
export const homeRecentProductReducer = (state = initalState.home_recent_product, action) => {
    switch (action.type) {
        case 'DATA':
            return {
                ...state,
                ...action.home_recent_product
            };
        case 'ERROR':
            return state;
        default:
            return state;
    }
}
export const homePopularProductReducer = (state = initalState.home_popular_product, action) => {
    switch (action.type) {
        case 'DATA':
            return {
                ...state,
                ...action.home_popular_product
            };
        case 'ERROR':
            return state;
        default:
            return state;
    }
}