import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { postData } from "../api";
import { getLocationsUrl } from "../constants/endPoints";
import { convertToBase64, setLoadingState } from "../constants/helper";
import useLocalStorage, { getFromStorage } from "../Hooks/useLocalStorage";
import { useHistory } from "react-router";

const SelectLocation = () => {
    const [location, setLocation] = useLocalStorage("location");
    const [locations, setLocations] = useState({ data: [], isLoading: false })
    const history = useHistory();
    const getLocations = async () => {
        try {
            const data = { "keyword": "", "order_by": "ordering", "order_type": "desc" };
            const response = await postData(getLocationsUrl("search", 100, 0), data);
            console.log(response.data);
            setLocations((prev) => { return { ...setLoadingState(prev, false, response.data) }; });

        } catch (e) {

        }
    }
    useEffect(() => {
        getLocations();
        // console.log(getFromStorage("location"));
    }, []);
    const setLocationData = async (id) => {
        console.log(id);
        await setLocation(id);
        history.push('/');
    }
    return (
        <div>
            <main>
                <div className="b-crumbs-wrap">
                    <div className="cont b-crumbs">
                        <ul>
                            <li>
                                <Link to="/">Motor</Link>
                            </li>
                            <li>
                                <span></span>
                            </li>
                        </ul>
                        <div className="b-crumbs-menu">
                            <a id="b-crumbs-menu" href="#"></a>
                            <ul className="b-crumbs-menulist">
                                <li><a href="product.html">Product: Standart</a></li>
                                <li><a href="product-vehicle.html">Product: Vehicle</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="b-crumbs-wrap">
                    <div className="cont maincont container">
                        <div className="containerx">
                            <h4>Please select a location first.</h4>
                        </div>
                        <div className="row">
                            <div className="col-3"></div>
                            <div className="col-6 row">
                                {
                                    locations.data && locations.data.map((val,i) => {
                                       return  <div className="col-12" onClick={()=>{setLocationData(val.id)}}>
                                            <h1 className="location-title">{val.name}</h1>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="col-3"></div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default SelectLocation;