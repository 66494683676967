import { faMapMarked } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, message, Modal, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FaGoogle, FaMailBulk, FaPhone } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { postData } from '../api';
import { editProfile, getItemsUrl, PREV_IMG } from '../constants/endPoints';
import { convertFromBase64, convertToBase64, currencyFormat } from '../constants/helper';
import { getCurrentUser, getCurrentUserId, isLogin } from '../constants/sessionHelper';
import { getItemData } from '../Hooks/useItemParams';
import useLocalStorage from '../Hooks/useLocalStorage';
import { SERVICES } from '../constants';
import ProductListServiceItem from './Components/ProductListServiceItem';
import ProductListItem from './Components/ProductListItem'


const Profile = () => {
    const [user, setUser] = useState({});
    const [editUser, setEditUser] = useState({});
    const [listing, setListing] = useState({ data: { listing: [], pending: [] }, isLoading: false });
    const [userDialog, setUserDialog] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [localStorageUser, setLocalStorageUser] = useLocalStorage('user');
    const getUserData = async () => {
    }
    const showProfileModel = () => {
        setUserDialog(true);
    }
    const hideProfileModel = () => {
        setUserDialog(false);
    }
    const getPageData = async () => {
        console.log("get USer", getCurrentUser());
        setUser(getCurrentUser());
        setEditUser(getCurrentUser());
        let itemsData = getItemData();
        try {
            setListing((prev) => { return { ...prev, isLoading: true } });
            itemsData.added_user_id = getCurrentUserId();
            let response = await postData(getItemsUrl("search", 40, 0), itemsData);
            console.log("RESPONSE DATA", response.data);
            if (response.data != null) {
                setListing(prev => { return { data: { ...prev.data, listing: [...response.data] }, isLoading: false } })
            } else {
                setListing(prev => { return { data: { ...prev.data, listing: [] }, isLoading: false } })
            }
        } catch (e) {
            setListing((prev) => { return { ...prev, isLoading: false } });
            console.log(e);

        }
        try {
            itemsData.status = "0";
            setListing((prev) => { return { ...prev, isLoading: true } });
            let responsePending = await postData(getItemsUrl("search", 40, 0), itemsData);
            if (responsePending.data != null) {
                setListing(prev => { return { data: { ...prev.data, pending: [...responsePending.data] }, isLoading: false } })

            } else {
                setListing(prev => { return { data: { ...prev.data, pending: [] }, isLoading: false } })
            }
        } catch (e) {
            setListing((prev) => { return { ...prev, isLoading: false } });
            console.log(e);
        }
    }
    const updateProfile = async () => {
        console.log(editUser);
        hideProfileModel();
        setIsUpdating((state) => true);
        let payload = { ...editUser, "is_show_email": "1", "is_show_phone": "1", "user_address": "", "city": "" }
        let response = await postData(editProfile("search", 40, 0), { ...editUser }, true);
        setIsUpdating(false);
        if (response) {
            console.log(response);
            if (response.data) {
                let user = response.data;
                setLocalStorageUser(user);
                if (isLogin()) {
                    setTimeout(() => {
                        getPageData();
                    }, 1000)
                }

            }
        }

    }

    useEffect(() => {
        getPageData();
    }, []);
    return (
        <div>
            <Modal title="Edit Profile" visible={userDialog} onOk={() => {
                if (!editUser.user_email) {
                    message.error('email cannot be empty');
                    return;
                }
                if (!editUser.user_phone) {
                    message.error('phone number cannot be empty');
                    return;
                }
                if (!editUser.user_name) {
                    message.error('user name cannot be empty');
                    return;
                }
                if (!editUser.user_about_me) {
                    message.error('about you cannot be empty');
                    return;
                }
                if (!editUser.user_address) {
                    message.error('address cannot be empty');
                    return;
                }
                if (!editUser.city) {
                    message.error('city cannot be empty');
                    return;
                }
                updateProfile();
            }} okText={"Update"} onCancel={hideProfileModel}>
                <div>
                    <form onSubmit={(e) => { e.preventDefault() }}>
                        <Input placeholder='UserName' value={editUser.user_name} onChange={(ev) => {
                            setEditUser({ ...editUser, user_name: ev.target.value });
                        }} />

                        {(!user.user_email) && <Input placeholder='Email' className='mt-2' value={editUser.user_email} onChange={(ev) => {
                            setEditUser({ ...editUser, user_email: ev.target.value });
                        }} />}
                        {(!user.user_phone) && <Input placeholder='Phone' className='mt-2' value={editUser.user_phone} onChange={(ev) => {
                            setEditUser({ ...editUser, user_phone: ev.target.value });
                        }} />}
                        <Input placeholder='About You' className='mt-2' value={editUser.user_about_me} onChange={(ev) => {
                            setEditUser({ ...editUser, user_about_me: ev.target.value });
                        }} />
                        <Input placeholder='City' className='mt-2' value={editUser.city} onChange={(ev) => {
                            setEditUser({ ...editUser, city: ev.target.value });
                        }} />
                        <Input placeholder='Address' className='mt-2' value={editUser.user_addess} onChange={(ev) => {
                            setEditUser({ ...editUser, user_address: ev.target.value });
                        }} />
                    </form>
                </div>
            </Modal>
            <main className="profile-page">

                {/* <!-- Breadcrumbs --> */}
                <div className="b-crumbs-wrap">
                    <div className="cont b-crumbs">
                        <ul>
                            <li>
                                <a href="index.html">Motor</a>
                            </li>
                            <li>
                                <span>Contacts</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="cont maincont">

                    <h1><span>Perfil</span></h1>
                    <span className="maincont-line1 maincont-line12"></span>
                    <span className="maincont-line2 maincont-line22"></span>

                    {/* <!-- Contacts - start --> */}
                    <div>
                        <div className="pagecont contacts">
                            <ul className="contacts-col1">
                                <li>
                                    <p><b>Em formação</b></p>
                                    <p>Identidade: {user.user_id}</p>
                                </li>
                                <li>
                                    <p><b>Endereço</b></p>
                                    <p>N/A</p>
                                </li>
                                <li>
                                    <p><b>O email</b></p>
                                    <p><a href={"mailto:" + user.user_email}>{user.user_email}</a></p>
                                </li>
                            </ul>
                            <ul className="contacts-col2">
                                <li>
                                    <p><b>Telefone</b></p>
                                    <p>{user.user_phone}</p>
                                </li>
                                <li>
                                    <p><b>Cerca de</b></p>
                                    <p>{user.user_about_me}</p>
                                    <p>Membro desde: {user.added_date_str}</p>
                                    <p>Dados cadastrais: {user.added_date}</p>
                                    {/* <p>Support 24/7</p> */}
                                </li>
                            </ul>
                            <div className="contacts-form row pb-3">
                                <div>
                                    <h3>Detalhes do usuário</h3>
                                </div>
                                <div className="profile-image">
                                    <img src={PREV_IMG + user.user_profile_photo}
                                        onError={(e) => { e.target.src = "./assets/images/placeholder.jpeg" }} />
                                    <div>
                                        <h5>{user.user_name}</h5>
                                    </div>
                                    <div>
                                        <div style={{ textAlign: "start", marginLeft: 20 }}>
                                            <div>
                                                <label><span><FaGoogle /></span> {user.google_verify == 1 ? "Verificado" : "Não verificado"}</label>
                                            </div>
                                            <div>
                                                <label><span><FaPhone /></span> {user.phone_verify == 1 ? "Verificado" : "Não verificado"}</label>
                                            </div>
                                            <div>
                                                <label><span><FaMailBulk /></span> {user.email_verify == 1 ? "Verificado" : "Não verificado"}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='row'>

                            </div>
                        </div>
                        <div className='d-flex justify-content-center pt-4'>
                            <button disabled={isUpdating} onClick={() => {
                                showProfileModel();
                            }} className='btn btn-danger'>Editar informações pessoais</button>
                        </div>
                        {/* Extending home page css */}
                        <div className="row home-page">
                            <div className="specials-wrap text-align-left">
                                <div className="cont specials">
                                    <h2>Seus anúncios ao vivo</h2>
                                    {/* <p className="specials-count">{listing.data.pending && "Total Items: " + listing.data.pending.length}</p> */}
                                    <div className="specials-list">
                                        <div className="special special-pseudo">
                                            <a href="#" className="special-link"></a>
                                        </div>
                                        {
                                            listing.isLoading &&
                                            <div className="d-flex justify-content-center align-items-center mt-8">
                                                <Spinner animation="grow" />
                                            </div>
                                        }
                                        <div className="row mob-scroll mob-scroll-row-h330">
                                            {
                                                listing.data.listing &&
                                                listing.data.listing.map((val, i) => {
                                                    return <div className="special col-lg-3 col-md-4" key={i + "recent"}>
                                                        <Link to={"/product_details/" + convertToBase64(val.id)} className="special-link">
                                                            <p className="special-img">
                                                                <img className="home-item-img" src={PREV_IMG + val.default_photo.img_path} alt="" onError={(e) => { e.target.src = "./assets/images/placeholder.jpeg" }} />
                                                            </p>
                                                            {
                                                                (val.item_no) &&
                                                                <p className='ps-1 pb-1 m-0 heading-font' style={{ color: 'blue' }}>AD#: <span style={{ color: 'red' }}>{val.item_no}</span></p>

                                                            }
                                                            {
                                                                (val.manufacturer.name) &&
                                                                <h3><span className="title">{val.manufacturer.name + ' ' + val.model.name + ' ' + val.year}</span></h3>
                                                            }
                                                            {
                                                                (!val.manufacturer.name) &&
                                                                <h3><span className="title">{val.title}</span></h3>
                                                            }


                                                        </Link>
                                                        <div className="row home-item-info">
                                                            <div className="col-12">
                                                                <h6 className="special-categ">{"Kz " + currencyFormat(val.price)}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    {/* <p className="special-more">
                                        <Link to="/products">show more</Link>
                                    </p> */}
                                    <span className="special-line1"></span>
                                    <span className="special-line2"></span>
                                </div>
                            </div>
                        </div>
                        <div className="row home-page">
                            <div className="specials-wrap text-align-left">
                                <div className="cont specials">
                                    <h2>Seus anúncios pendentes</h2>
                                    {/* <p className="specials-count">{listing.data.pending && "Total Items: " + listing.data.pending.length}</p> */}
                                    <div className="specials-list">
                                        <div className="special special-pseudo">
                                            <a href="#" className="special-link"></a>
                                        </div>
                                        {
                                            listing.isLoading &&
                                            <div className="d-flex justify-content-center align-items-center mt-8">
                                                <Spinner animation="grow" />
                                            </div>
                                        }
                                        <div className="row mob-scroll mob-scroll-row-h330 ">
                                            {
                                                listing.data.pending &&
                                                listing.data.pending.map((val, i) => {
                                                    return <div className="special col-lg-3 col-md-4" key={i + "recent"}>
                                                        {/* <div className="home-item-profile-container row">
                                                            <div className="col-3">
                                                                <img className="home-item-profile-img " src={PREV_IMG + val.user.user_profile_photo} onError={(e) => { e.target.src = "./assets/images/placeholder.jpeg" }} />
                                                            </div>
                                                            <div className="col-8">
                                                                <h6 className="home-item-profile-name m-0">{val.user.user_name}</h6>
                                                                <h6 className="home-item-post-time m-0">{val.added_date_str}</h6>
                                                            </div>
                                                        </div> */}
                                                        <Link to={"/product_details/" + convertToBase64(val.id)} className="special-link">
                                                            <p className="special-img">
                                                                <img className="home-item-img" src={PREV_IMG + val.default_photo.img_path} alt="" onError={(e) => { e.target.src = "./assets/images/placeholder.jpeg" }} />
                                                            </p>
                                                            {
                                                                (val.item_no) &&
                                                                <p className='ps-1 pb-1 m-0 heading-font' style={{ color: 'blue' }}>AD#: <span style={{ color: 'red' }}>{val.item_no}</span></p>

                                                            }
                                                            {
                                                                (val.manufacturer.name) &&
                                                                <h3><span className="title">{val.manufacturer.name + ' ' + val.model.name + ' ' + val.year}</span></h3>
                                                            }
                                                            {
                                                                (!val.manufacturer.name) &&
                                                                <h3><span className="title">{val.title}</span></h3>
                                                            }
                                                            {/* <h3><span>{val.title}</span></h3> */}

                                                        </Link>

                                                        <div className="row home-item-info">
                                                            <div className="col-12">
                                                                <h6 className="special-categ">{"Kz " + currencyFormat(val.price)}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <span className="special-line1"></span>
                                    <span className="special-line2"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Profile;
