import { Switch } from "react-router";
import AppLayout from "../Components/AppLayout";
import AddProduct from "../Pages/AddProduct";
import Chat from "../Pages/Chat";
import Err404 from "../Pages/Err404";
import Home from "../Pages/Home";
import Login from "../Pages/Login";
import ProductDetail from "../Pages/ProductDetail";
import ProductList from "../Pages/ProductList";
import Profile from "../Pages/Profile";
import SearchProducts from "../Pages/SearchProducts";
import SelectLocation from "../Pages/SelectLocation";
import Signup from "../Pages/Signup";
import LocationRoutes from "./LocationRoutes";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import UpdateProduct from "../Pages/UpdateProduct";

const Routes = () => {
    return (
        <>
            <Switch>
                <LocationRoutes path="/" exact>
                    <AppLayout component={<Home />} />
                </LocationRoutes>
                <LocationRoutes path="/home" exact>
                    <AppLayout component={<Home />} />
                </LocationRoutes>
                <LocationRoutes path="/products" exact>
                    <AppLayout component={<ProductList />} />
                </LocationRoutes>
                <LocationRoutes path="/products/:data">
                    <AppLayout component={<ProductList />} />
                </LocationRoutes>

                <PublicRoute path="/product_details/:id/:name?">
                    <AppLayout component={<ProductDetail />} />
                </PublicRoute>
                <PrivateRoute path="/profile">
                    <AppLayout component={<Profile />} />
                </PrivateRoute>

                <PrivateRoute path="/add_item">
                    <AppLayout component={<AddProduct />} />
                </PrivateRoute>
                <PrivateRoute path="/chats">
                    <AppLayout component={<Chat />} />
                </PrivateRoute>
                <PrivateRoute path="/edit_item/:item_id">
                    <AppLayout component={<UpdateProduct />} />
                </PrivateRoute>

                <LocationRoutes path="/search_item" exact>
                    <AppLayout component={<SearchProducts />} />
                </LocationRoutes>
                <LocationRoutes path="/search_item/:data">
                    <AppLayout component={<SearchProducts />} />
                </LocationRoutes>

                <PublicRoute path="/select_location">
                    <AppLayout component={<SelectLocation />} />
                </PublicRoute>
                <PublicRoute path="/login">
                    <Login />
                </PublicRoute>
                <PublicRoute path="/signup">
                    <Signup />
                </PublicRoute>
                <PublicRoute path="*">
                    <AppLayout component={<Err404 />} />
                </PublicRoute>
            </Switch>
        </>
    );
}

export default Routes;