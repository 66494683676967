import { PREV_IMG } from "../../constants/endPoints";

const NoMotorAd = () => {
    return (<div className="getspec-wrap cursor-pointer">
        <div className="cont getspec">
            <div className="getspec-cont" style={{background:"#d70304"}}>
                <h3 style={{color:"white",fontSize:60}}>{"Publicite Aqui"}</h3>
                {/* <p>{ad.description}</p> */}
            </div>
            <div href="#" className="getspec-img">
                {/* {
                    ad.image && <img src={PREV_IMG + ad.image.img_path} alt="" />

                } */}
            </div>
        </div>
    </div>);
}

export default NoMotorAd;