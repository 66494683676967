import { useEffect, useState } from "react";
import { getCurrentUserId } from "../constants/sessionHelper";
import { getFromStorage } from "./useLocalStorage";
export function getItemData(initialValue = null) {
    return initialValue ? initialValue : {
        searchterm: "", manufacturer_id: "",
        model_id: "", item_type_id: "", item_price_type_id: "",
        item_currency_id: "", item_location_id: "",
        min_year:'',max_year: '', min_price: '',max_price: '',
        condition_of_item_id: "", color_id: "", fuel_type_id: "",
        build_type_id: "", seller_type_id: "",
        transmission_id: "",year: "", max_price: "", min_price: "",
        lat: "", lng: "", miles: "", added_user_id: "",
        is_paid: "paid_item_first", order_by: "added_date",min_price:'',max_price:'',
        order_type: "desc", status: "1", category_id: "", sell_type: "",item_type: "",
        mileage:'',max_milage:''/*Milage = KM*/,
    }
};
export function itemFormData() {
    return {
        manufacturer_id: "",
        model_id: "", item_type_id: "",
        item_price_type_id: "", item_currency_id: "", condition_of_item_id: "",
        item_location_id: "", color_id: "", fuel_type_id: "", build_type_id: "",
        seller_type_id: "", transmission_id: "", description: "", highlight_info: "", price: "100",
        business_mode: "", is_sold_out: "", title: "", address: "",
        lat: "0", lng: "0", plate_number: "", engine_power: "", steering_position: "", no_of_owner: "",
        trim_name: "", vehicle_id: "", price_unit: "", year: "", licence_status: null,
        max_passengers: "", no_of_doors: "", mileage: "", license_expiration_date: "", id: "", added_user_id: getCurrentUserId(),
        sell_type: "", category_id: "",item_type: "",slip_url: "",package_detail:'',phone_number:'',
        manufacturer_name:'',model_name:'',second_phone_number: "",is_driver:'',negotiable:0, hours: ""
    };

}

const useItemParams = (initialValue) => {
    const [data, setData] = useState(() => {
        return getItemData(initialValue);
    });
    useEffect(() => {

    }, [data])
    return ([
        data,
        setData
    ]);
}

export default useItemParams;