// export { NETWORK_ERROR } from './errors';
import { LoadingOutlined } from '@ant-design/icons';


export const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const BOATS = 7;
export const CARS = 1;
export const MOTOR_BIKES = 5;
export const PARTS = 8;
export const OTHERS = 10;
export const TRUCKS = 11;
export const NAUTICAL = 12;
export const CRASHED_CARS = 13;
export const MACHINERY = 14;
export const SERVICES = 15;

export const TEXT_MESSAGE_TYPE = 0;
export const IMAGE_MESSAGE_TYPE = 1;
export const MAKE_OFFER_MESSAGE_TYPE = 2;
export const SEND_OFFER_STATUS = 1;
export const REJECT_OFFER_STATUS = 2;
export const ACCEPT_OFFER_STATUS = 3;
export const NO_ITEM_FOUND = "Nenhum item encontrado";
export const _rentPackage = { 'basic': "15000", 'exc': '40000' };
export const _partsPackage = {'basic': "5000", 'exc': '25000'};
export const _bikePackage = { 'basic': "15000" };
export const _servicePackages = {
    'basic': "15000",
    // 'standard': "35000",
    // 'exc': "50000",
    // 'vip': '200000'
};
export const _packages = {
    'basic': "20000",
    // 'standard': "35000",
    // 'exc': "100000",
    // 'vip': '200000'
};
export const _noOfSeats = [{
    'key': '2',
    'value': '2'
},{
    'key': '5',
    'value': '5'
},{
    'key': '7',
    'value': '7'
},{
    'key': '15',
    'value': '15'
},{
    'key': '25',
    'value': '25'
},{
    'key': '50',
    'value': '50'
}];
export const frontSliderImages = [{
    title: 'Compra,',
    subTitle: 'Aluguer & Venda',
    image: '/external_assets/img/home-slider/truck.png',
    width: '88%',
    height: '100%',
}, {
    title: 'Aluguer,',
    subTitle: 'Compra & Venda',
    image: '/external_assets/img/home-slider/tractor.png',
    width: '88%',
    height: '100%',
}, {
    title: 'Venda,',
    subTitle: 'Aluguer & Compra',
    image: '/external_assets/img/home-slider/motor-cycle.png',
    width: '88%',
    height: '100%',
}, {
    title: 'Compra,',
    subTitle: 'Aluguer & Venda',
    image: '/external_assets/img/home-slider/boat.png',
    width: '88%',
    height: '120%',
}, {
    title: 'Aluguer,',
    subTitle: 'Compra & Venda',
    image: '/external_assets/img/home-slider/parts.png',
    width: '88%',
    height: '88%',
}
];
export const carTypes = [{
    key: 'Jeep',
    value: 'Jeep',
}, {
    key: 'Turismo',//Sadan
    value: 'Turismo',
}];

export const boatTypes = [{
    key: 'Boat',
    value: 'Boat',
}, {
    key: 'Jet Sky',//Sadan
    value: 'Jet Sky',
}];

// export const serviceAreas = ['Automovel', 'Maquinas', 'Aquaticos', 'Outros'];

export const serviceAreas = [{
    key: 'Automovel',
    value: 'Automovel',
}, {
    key: 'Maquinas',
    value: 'Maquinas',
}, {
    key: 'Aquaticos',
    value: 'Aquaticos',
},
{
    key: 'Outros',
    value: 'Outros',
},

];

export const machinaryTypes = [{
    key: 'Agriculture',
    value: 'Agriculture',
},
{
    key: 'Construção',//Sadan
    value: 'Construção',
},
{
    key: 'Generator',
    value: 'Generator',
},
{
    key: 'Other',//Sadan
    value: 'Other',
}];

export const rentPriceList = [
    {
        "val": "50000",
        "text": "Under 50,000"
    },
    {
        "val": "100000",
        "text": "Under 100,000"
    },
    {
        "val": "200000",
        "text": "Under 200,000"
    },
    {
        "val": "500000",
        "text": "Under 500,000"
    },
    {
        "val": "1000000",
        "text": "Under 1,000,000"
    },
    {
        "val": "-1",
        "text": "Above 1,000,000"
    },

];
export const sellStartPriceList1 = [
    {
        "val": "0",
        "text": "0 Kz"
    },
    {
        "val": "250000",
        "text": "250,000 Kz"
    },
    {
        "val": "500000",
        "text": "500,000 Kz"
    },
    {
        "val": "1000000",
        "text": "1,000,000 Kz"
    },
    {
        "val": "2000000",
        "text": "2,000,000 Kz"
    },
    {
        "val": "2500000",
        "text": "2,500,000 Kz"
    },
    {
        "val": "2500000",
        "text": "2,500,000 Kz"
    },
    {
        "val": "30000000",
        "text": "30,000,000 Kz"
    },
];
export const sellEndPriceList1 = [
    {
        "val": "0",
        "text": "0 Kz"
    },
    {
        "val": "500000",
        "text": "500,000 Kz"
    },
    {
        "val": "1000000",
        "text": "1,000,000 Kz"
    },
    {
        "val": "2000000",
        "text": "2,000,000 Kz"
    },
    {
        "val": "2500000",
        "text": "2,500,000 Kz"
    },
    {
        "val": "2500000",
        "text": "2,500,000 Kz"
    },
    {
        "val": "30000000",
        "text": "30,000,000 Kz"
    },
    {
        "val": "50000000",
        "text": "50,000,000 Kz"
    },
];

export const sellStartPriceList2 = [
    {
        "val": "0",
        "text": "0 Kz"
    },
    {
        "val": "1000000",
        "text": "1,000,000 Kz"
    },
    {
        "val": "5000000",
        "text": "5,000,000 Kz"
    },
    {
        "val": "10000000",
        "text": "10,000,000 Kz"
    },
    {
        "val": "15000000",
        "text": "15,000,000 Kz"
    },
    {
        "val": "20000000",
        "text": "20,000,000 Kz"
    },
    {
        "val": "25000000",
        "text": "25,000,000 Kz"
    },
    {
        "val": "30000000",
        "text": "30,000,000 Kz"
    },
    {
        "val": "35000000",
        "text": "35,000,000 Kz"
    },
    {
        "val": "40000000",
        "text": "40,000,000 Kz"
    },
    {
        "val": "45000000",
        "text": "45,000,000 Kz"
    },
    {
        "val": "50000000",
        "text": "50,000,000 Kz"
    },
    {
        "val": "55000000",
        "text": "55,000,000 Kz"
    },
    {
        "val": "60000000",
        "text": "60,000,000 Kz"
    },
    {
        "val": "65000000",
        "text": "65,000,000 Kz"
    },
    {
        "val": "70000000",
        "text": "70,000,000 Kz"
    },
    {
        "val": "75000000",
        "text": "75,000,000 Kz"
    },
    {
        "val": "80000000",
        "text": "80,000,000 Kz"
    },
    {
        "val": "85000000",
        "text": "85,000,000 Kz"
    },
    {
        "val": "90000000",
        "text": "90,000,000 Kz"
    },
    {
        "val": "95000000",
        "text": "95,000,000 Kz"
    },
    {
        "val": "100000000",
        "text": "100,000,000 Kz"
    },

];

export const sellEndPriceList2 = [
    {
        "val": "0",
        "text": "0 Kz"
    },
    {
        "val": "1000000",
        "text": "1,000,000 Kz"
    },
    {
        "val": "5000000",
        "text": "5,000,000 Kz"
    },
    {
        "val": "10000000",
        "text": "10,000,000 Kz"
    },
    {
        "val": "15000000",
        "text": "15,000,000 Kz"
    },
    {
        "val": "20000000",
        "text": "20,000,000 Kz"
    },
    {
        "val": "25000000",
        "text": "25,000,000 Kz"
    },
    {
        "val": "30000000",
        "text": "30,000,000 Kz"
    },
    {
        "val": "35000000",
        "text": "35,000,000 Kz"
    },
    {
        "val": "40000000",
        "text": "40,000,000 Kz"
    },
    {
        "val": "45000000",
        "text": "45,000,000 Kz"
    },
    {
        "val": "50000000",
        "text": "50,000,000 Kz"
    },
    {
        "val": "55000000",
        "text": "55,000,000 Kz"
    },
    {
        "val": "60000000",
        "text": "60,000,000 Kz"
    },
    {
        "val": "65000000",
        "text": "65,000,000 Kz"
    },
    {
        "val": "70000000",
        "text": "70,000,000 Kz"
    },
    {
        "val": "75000000",
        "text": "75,000,000 Kz"
    },
    {
        "val": "80000000",
        "text": "80,000,000 Kz"
    },
    {
        "val": "85000000",
        "text": "85,000,000 Kz"
    },
    {
        "val": "90000000",
        "text": "90,000,000 Kz"
    },
    {
        "val": "95000000",
        "text": "95,000,000 Kz"
    },
    {
        "val": "100000000",
        "text": "100,000,000 Kz"
    },
    {
        "val": "200000000",
        "text": "200,000,000 Kz"
    },
    {
        "val": "300000000",
        "text": "300,000,000 Kz"
    },

];

export const kmStartList = [
    {
        "val": "0",
        "text": "0 Km"
    },
    {
        "val": "1000",
        "text": "1,000 Km"
    },
    {
        "val": "5000",
        "text": "5,000 Km"
    },
    {
        "val": "10000",
        "text": "10,000 Km"
    },
    {
        "val": "20000",
        "text": "20,000 Km"
    },
    {
        "val": "25000",
        "text": "25,000 Km"
    },
    {
        "val": "30000",
        "text": "30,000 Km"
    },
    {
        "val": "50000",
        "text": "50,000 Km"
    },
    {
        "val": "100000",
        "text": "100,000 Km"
    },
];

export const kmEndList = [
    {
        "val": "0",
        "text": "0 Km"
    },
    {
        "val": "1000",
        "text": "1,000 Km"
    },
    {
        "val": "5000",
        "text": "5,000 Km"
    },
    {
        "val": "10000",
        "text": "10,000 Km"
    },
    {
        "val": "20000",
        "text": "20,000 Km"
    },
    {
        "val": "25000",
        "text": "25,000 Km"
    },
    {
        "val": "30000",
        "text": "30,000 Km"
    },
    {
        "val": "50000",
        "text": "50,000 Km"
    },
    {
        "val": "100000",
        "text": "100,000 Km"
    },
    {
        "val": "200000",
        "text": "200,000 Km"
    },
];
