
export const updateBlogs = (data) => {
    return (dispatch, getState) => {
        if (typeof(data) == 'object' || Array.isArray(data)) {
            dispatch({ type: 'DATA', blog: 
            { ...data } })
        } else {
            dispatch({ type: "ERROR", blog: [] });
        }
    }
}