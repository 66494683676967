//type 0 simple text message
//type 2 make offer
//offer status 1 = send offer
//offer status 2 = reject offer
//offer status 3 = accept offer
//when reject offer from seller buyer offer type = 0
//add message offer with type 2
import React, { useEffect, useRef, useState } from 'react';
import { Tabs } from 'antd';
import { TabPane } from 'react-bootstrap';
import ChatTab from '../Components/ChatTab';
import { setLoadingState, sortingUserId } from '../../constants/helper';
import { postData } from '../../api';
import { getChatList, PREV_IMG } from '../../constants/endPoints';
import { getCurrentUserId, getCurrentUser } from '../../constants/sessionHelper';
import { ref, onValue, set, get, remove, child, query, orderByChild, equalTo, push, off } from 'firebase/database';
import { database } from '../../constants/FirebaseConstraints'
import { notify } from "../../Components/AppNotification";
import { IMAGE_MESSAGE_TYPE, TEXT_MESSAGE_TYPE } from '../../constants';

const Chat = () => {
    const [key, setKey] = useState("1");
    const [sellerChats, setSellerChats] = useState({ data: [], isLoading: false });
    const _messagesRef = ref(database, 'Message');
    const _chatRef = ref(database, 'Current_Chat_With');
    const _userPresence = ref(database, 'User_Presence');
    const [messages, setMessages] = useState([]);
    const [messageLoading, setMessageLoading] = useState(false);
    const [currentChat, setCurrentChat] = useState();
    const [textMessage, setTextMessage] = useState('');
    let otherUserId = null;
    let sessionId = null;
    let isFcmFuncInitialized = false;
    // Support
    let listner = null;
    const mref = useRef(null);

    const initFCMFuncs = async () => {
        let data = await currentChat;
        console.log(currentChat);
        if (data == null || data == undefined) {
            return;
        } else {
            console.log(mref, 'init fcm func');

            if (mref.current) {
                console.log("off");
                off(child(_messagesRef, mref.current.sess));
            }
            const { sessionId, otherUserId } = getSessionAndOtherUserId(data);
            mref.current = { sess: sessionId };
            onValue(child(_messagesRef, sessionId), snapshot => {
                console.log(snapshot);
                if (data) {
                    getMessages(sessionId, data.item.id, otherUserId,
                        getCurrentUserId(), getCurrentUser().user_name);
                }
            }, err => {
                console.log(err);
                notify("Error", "Cannot load data", "error");
            });
        }
    }
    useEffect(() => {
        if (currentChat)
            initFCMFuncs();
        return () => {

        };
    }, [currentChat]);
    const uploadImage = async (file) => {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("sender_id", getCurrentUserId());
        formData.append("seller_user_id", currentChat.seller.user_id);
        formData.append("buyer_user_id", currentChat.buyer.user_id);
        formData.append("item_id", currentChat.item.id);
        if (key == 1) {
            formData.append("type", "to_seller");
        } else {
            formData.append('type', 'to_buyer');
        }
        let response = await postData("index.php/rest/images/chat_image_upload/api_key/teampsisthebest", formData);
        notify('Success', 'image uploaded', 'success');
        return response;
    }

    const sendImage = async () => {

        // https://mjcoders.com/vehicles/index.php/rest/images/chat_image_upload/api_key/teampsisthebest
        //     request.fields[senderIdText] = senderId;
        //   request.fields[sellerUserIdText] = sellerUserId;
        //   request.fields[buyerUserIdText] = buyerUserId;
        //   request.fields[itemIdText] = itemId;
        //   request.fields[typeText] = type;
        //   request.files.add(multipartFile);
    }
    const selectImage = (id) => {
        if (currentChat == null || currentChat == undefined) {
            notify('Error', "cannot find chat", 'error');
            return;
        }
        document.getElementById(id).click();
    }
    const onImageChange = async (e, id) => {
        console.log(e.target.files, id);
        if (e.target.files.length > 0) {
            notify('Uploading', 'uploading your image please wait...', 'info');
            let res = await uploadImage(e.target.files[0]);
            console.log(res);
            if (res.data) {
                sendMessage(res.data.img_path, IMAGE_MESSAGE_TYPE);
            }
        }

    }
    const sendMessage = async (message, type = TEXT_MESSAGE_TYPE) => {
        if (currentChat == null || currentChat == undefined || !currentChat) {
            notify('Error', "cannot find chat", 'error');
            return;
        }
        if (messages.length < 1) {
            notify('Error', "cannot send an empty message", 'error');
            return;
        }
        const { sessionId, otherUserId } = getSessionAndOtherUserId(currentChat);
        let newref = push(_messagesRef, sessionId).then(res => {
            console.log(res.key);
            let dd = {
                addedDate: new Date().getTime(),
                id: res.key,
                isSold: false,
                isUserBought: false,
                itemId: currentChat.item.id,
                message: message,
                offerStatus: 0,
                sendByUserId: getCurrentUserId(),
                sessionId: sessionId,
                type: type

            };
            console.log(JSON.parse(JSON.stringify(dd)));
            set(child(_messagesRef, sessionId + "/" + res.key), JSON.parse(JSON.stringify(dd))).then(() => {
                notify('Success', 'Message Sent Successfuly', 'success');
                setTextMessage('');
            }, reason => {
                notify('Error', 'Cannot Send Message', 'error');

            });
        });
        // console.log(newref.key)

    }
    // onValue(_messagesRef,(snapshot)=>{
    //     const data = snapshot.val();
    //     console.log("snapshot",data);
    // });
    // _chatRef = database.reference().child('Current_Chat_With');
    // _userPresence = database.reference().child('User_Presence');
    const getSellerChats = async (type) => {
        try {
            setSellerChats((prev) => { return { ...setLoadingState(prev, true) } });
            let data = { user_id: getCurrentUserId(), return_type: type };
            console.log(data);
            let res = await postData(getChatList(), data);
            setSellerChats((prev) => { return { ...setLoadingState(prev, false, res.data) } });

            console.log(res);
        } catch (e) {
            console.log(e);
            setSellerChats((prev) => { prev.data = []; return { ...setLoadingState(prev, false) } });
        }
    }

    function callback(key) {
        console.log(key);
        setKey(key);
        setMessages([]);
        setCurrentChat(null);
        setSellerChats((prev) => { prev.data = []; return { ...setLoadingState(prev, false) } });
        if (key == 1) {
            getSellerChats("seller");
        } else {
            getSellerChats("buyer");
        }
    }
    const clearChat = async () => {
        if (getCurrentUserId()) {
            await remove(child(_chatRef, getCurrentUserId()));
            await remove(child(_userPresence, getCurrentUserId()));
        }
        return true;
    }
    const onChatItemClick = async (val) => {
        setMessages([]);
        setCurrentChat((prev) => { return { ...val } });
        // currentChat = val;
        // initFCMFuncs();
        await clearChat();
        onChatLoad(val);
    }
    const _insertSenderAndReceiverToFireBase = async (
        sessionId,
        itemId,
        receiverId,
        senderId,
        userName) => {
        const chat =
            { itemId: itemId, receiverId: receiverId, senderId: senderId };
        // chat.itemId = itemId;
        // chat.receiverId = receiverId;
        // chat.senderId = senderId;

        // _chatRef.child(senderId).child(sessionId).set(chat.toMap(chat));
        //   _chatRef.child(senderId).set(chat.toMap(chat));
        set(child(_chatRef, getCurrentUserId()), chat);
        const chatUserPresence =
            { userId: senderId, userName: userName };
        //   _userPresence.child(senderId).set(chatUserPresence.toMap(chatUserPresence));
        await set(child(_userPresence, getCurrentUserId()), chatUserPresence);
        getMessages(sessionId,
            itemId,
            receiverId,
            senderId,
            userName);
    }
    const getMessages = async (
        sessionId,
        itemId,
        receiverId,
        senderId,
        userName) => {
        setMessageLoading(true);
        let q = query(child(_messagesRef, sessionId), orderByChild('itemId'), equalTo(itemId));
        onValue(q, (snapshot) => {
            // console.log(snapshot.val());
            let messagesData = Array();
            let keys = Object.keys(snapshot.val());
            keys.forEach(key => {
                let message = snapshot.val()[key];
                messagesData.push(message);
            });
            console.log(messagesData);
            setMessages(messagesData);
            setTimeout(function () {
                let id = "chat_message_history";
                var div = document.getElementById(id);
                if (div)
                    div.scrollTop = div.scrollHeight - div.clientHeight;
            }, 100);
            // snapshot.forEach(element => {

            // });
            setMessageLoading(false);
        }, error => {
            console.log(error);
            setMessageLoading(false);
        });
    }
    const getSessionAndOtherUserId = (data) => {
        if (getCurrentUserId() == data.buyer.user_id) {
            sessionId =
                sortingUserId(data.seller.user_id, data.buyer.user_id);
            otherUserId = data.seller.user_id;
        } else if (getCurrentUserId() == data.seller.user_id) {
            sessionId =
                sortingUserId(data.buyer.user_id, data.seller.user_id);
            otherUserId = data.buyer.user_id;
        }
        return { sessionId, otherUserId };
    }
    const onChatLoad = (data) => {
        if (data) {
            const { sessionId, otherUserId } = getSessionAndOtherUserId(data);
            _insertSenderAndReceiverToFireBase(sessionId, data.item.id, otherUserId,
                getCurrentUserId(), getCurrentUser().user_name);
        }
        // set(_userPresence , )
    }

    useEffect(() => {
        console.log("chat");
        getSellerChats("seller");
        return function clean() {
            clearChat();
            console.log("clean");
        }
    }, []);
    return (

        <div className="chat_page">
            {/* <h1>CHATS</h1> */}
            <Tabs defaultActiveKey="1" onChange={callback} centered>
                <TabPane key="1" style={{ width: "100%" }} tab={
                    <ChatTab name="For Seller" className={key == "1" ? "j-tab-active" : "j-tab"} />
                }>
                </TabPane>
                <TabPane key="2" style={{ width: "100%" }} tab={
                    <ChatTab name="For Buyer" className={key == "2" ? "j-tab-active" : "j-tab"} />
                } style={{ borderRadius: 20 }}>

                </TabPane>
            </Tabs>
            {/* TAB 1 */}
            {key == "1" && <div className="container">
                {/* <h3 className=" text-center">Messaging</h3> */}
                <div className="messaging">
                    <div className="inbox_msg">
                        <div className="inbox_people">
                            <div className="headind_srch">
                                <div className="recent_heading">
                                    <h4>From Seller</h4>
                                </div>
                                <div className="srch_bar">
                                    <div className="stylish-input-group">
                                        <input type="text" className="search-bar" placeholder="Search" />
                                        <span className="input-group-addon">
                                            <button type="button"> <i className="fa fa-search" aria-hidden="true"></i> </button>
                                        </span> </div>
                                </div>
                            </div>
                            <div className="inbox_chat">
                                {/* <div className="chat_list active_chat">
                                            <div className="chat_people">
                                                <div className="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
                                                <div className="chat_ib">
                                                    <h5>Sunil Rajput <span className="chat_date">Dec 25</span></h5>
                                                    <p>Test, which is a new approach to have all solutions
                                                        astrology under one roof.</p>
                                                </div>
                                            </div>
                                        </div> */}
                                {sellerChats.data.map((val, i) => {
                                    return <div key={val.id} className={currentChat ?
                                        currentChat.id == val.id ? "chat_list active_chat" : "chat_list"
                                        : "chat_list"} onClick={() => { onChatItemClick(val); }}>
                                        <div className="chat_people">
                                            <div className="chat_img"> <img src={PREV_IMG + val.seller.user_profile_photo} alt="" /> </div>
                                            <div className="chat_ib">
                                                <h5>{val.seller.user_name} <span className="chat_date">{val.added_date_str}</span></h5>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-3">
                                                        <img src={PREV_IMG + val.item.default_photo.img_path} className="chat-product-img" />
                                                    </div>
                                                    <div className="col-8">
                                                        <div>
                                                            <label>{val.item.title}</label>
                                                        </div>
                                                        <div>
                                                            <label> {val.item.item_currency.currency_symbol} {val.item.price} ({val.item.condition_of_item.name})</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}

                            </div>
                        </div>
                        <div className="mesgs">
                            <div className="msg_history" id="chat_message_history" style={{ paddingRight: 30 }}>
                                {messages.map((val, i) => {
                                    return val.sendByUserId == getCurrentUserId() ?
                                        <div key={val.id} className="outgoing_msg">
                                            <div className="sent_msg">
                                                {val.type == IMAGE_MESSAGE_TYPE ?
                                                    <div>
                                                        <img src={PREV_IMG + val.message} />
                                                        <span className="time_date"> {new Date(val.addedDate).toLocaleDateString()} {new Date(val.addedDate).toLocaleTimeString()}</span>

                                                    </div>
                                                    : <div>
                                                        <p>{val.message}</p>
                                                        <span className="time_date"> {new Date(val.addedDate).toLocaleDateString()} {new Date(val.addedDate).toLocaleTimeString()}</span>
                                                    </div>}
                                            </div>
                                        </div>
                                        : <div className="incoming_msg" key={val.id}>
                                            <div className="incoming_msg_img"> <img src={PREV_IMG + currentChat.seller.user_profile_photo} alt="sunil" /> </div>
                                            <div className="received_msg">
                                                {<div className="received_withd_msg">
                                                    {val.type == IMAGE_MESSAGE_TYPE ?
                                                        <div>
                                                            <img src={PREV_IMG + val.message} />
                                                        </div>
                                                        : <div>
                                                            <p>{val.message}</p>
                                                            <span className="time_date">{new Date(val.addedDate).toLocaleDateString()} {new Date(val.addedDate).toLocaleTimeString()}</span>
                                                        </div>}
                                                </div>}
                                            </div>
                                        </div>
                                })}
                                {/* <div className="outgoing_msg">
                                    <div className="sent_msg">
                                        <p>Test which is a new approach to have all
                                            solutions</p>
                                        <span className="time_date"> 11:01 AM    |    June 9</span> </div>
                                </div> */}
                            </div>
                            <div className="type_msg">
                                <div className="input_msg_write">
                                    <button className="msg_send_btn col-2" style={{ zIndex: 1, padding: 4, top: 12, right: 40 }} type="button" onClick={() => {
                                        selectImage("add_chat_img");
                                    }}><i className="fa fa-camera" aria-hidden="true"></i></button>

                                    <div>
                                        <input type="text" value={textMessage} className="write_msg" onChange={(event) => { setTextMessage(event.target.value) }} placeholder="Type a message" />
                                    </div>
                                    <button className="msg_send_btn col-3" type="button" onClick={() => {
                                        sendMessage(textMessage);
                                    }}><i className="fa fa-paper-plane-o" aria-hidden="true"></i></button>

                                    <input type="file" id={"add_chat_img"}
                                        style={{ display: "none" }} onChange={(e) => { onImageChange(e, 'add_chat_img'); }} />


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {
                key == "2" && <div className="container">
                    <div className="messaging">
                        <div className="inbox_msg">
                            <div className="inbox_people">
                                <div className="headind_srch">
                                    <div className="recent_heading">
                                        <h4>From Seller</h4>
                                    </div>
                                    <div className="srch_bar">
                                        <div className="stylish-input-group">
                                            <input type="text" className="search-bar" placeholder="Search" />
                                            <span className="input-group-addon">
                                                <button type="button"> <i className="fa fa-search" aria-hidden="true"></i> </button>
                                            </span> </div>
                                    </div>
                                </div>
                                <div className="inbox_chat">
                                    {/* <div className="chat_list active_chat">
                                            <div className="chat_people">
                                                <div className="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
                                                <div className="chat_ib">
                                                    <h5>Sunil Rajput <span className="chat_date">Dec 25</span></h5>
                                                    <p>Test, which is a new approach to have all solutions
                                                        astrology under one roof.</p>
                                                </div>
                                            </div>
                                        </div> */}
                                    {sellerChats.data.map((val, i) => {
                                        return <div key={val.id} className={currentChat ?
                                            currentChat.id == val.id ? "chat_list active_chat" : "chat_list"
                                            : "chat_list"} onClick={() => { onChatItemClick(val); }}>
                                            <div className="chat_people">
                                                <div className="chat_img"> <img src={PREV_IMG + val.buyer.user_profile_photo} alt="" /> </div>
                                                <div className="chat_ib">
                                                    <h5>{val.buyer.user_name} <span className="chat_date">{val.added_date_str}</span></h5>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <img src={PREV_IMG + val.item.default_photo.img_path} className="chat-product-img" />
                                                        </div>
                                                        <div className="col-8">
                                                            <div>
                                                                <label>{val.item.title}</label>
                                                            </div>
                                                            <div>
                                                                <label> {val.item.item_currency.currency_symbol} {val.item.price} ({val.item.condition_of_item.name})</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}

                                </div>
                            </div>
                            <div className="mesgs">
                                <div className="msg_history" id="chat_message_history" style={{ paddingRight: 30 }}>
                                    {messages.map((val, i) => {
                                        return val.sendByUserId == getCurrentUserId() ?
                                            <div key={val.id} className="outgoing_msg">
                                                <div className="sent_msg">
                                                    {val.type == IMAGE_MESSAGE_TYPE ?
                                                        <div>
                                                            <img src={PREV_IMG + val.message} />
                                                            <span className="time_date"> {new Date(val.addedDate).toLocaleDateString()} {new Date(val.addedDate).toLocaleTimeString()}</span>

                                                        </div>
                                                        : <div>
                                                            <p>{val.message}</p>
                                                            <span className="time_date"> {new Date(val.addedDate).toLocaleDateString()} {new Date(val.addedDate).toLocaleTimeString()}</span>
                                                        </div>}
                                                </div>
                                            </div>
                                            : <div className="incoming_msg" key={val.id}>
                                                <div className="incoming_msg_img"> <img src={PREV_IMG + currentChat.buyer.user_profile_photo} alt="" /> </div>
                                                <div className="received_msg">
                                                    {<div className="received_withd_msg">
                                                        {val.type == IMAGE_MESSAGE_TYPE ?
                                                            <div>
                                                                <img src={PREV_IMG + val.message} />
                                                            </div>
                                                            : <div>
                                                                <p>{val.message}</p>
                                                                <span className="time_date">{new Date(val.addedDate).toLocaleDateString()} {new Date(val.addedDate).toLocaleTimeString()}</span>
                                                            </div>}
                                                    </div>}
                                                </div>
                                            </div>
                                    })}
                                    {/* <div className="outgoing_msg">
                                    <div className="sent_msg">
                                        <p>Test which is a new approach to have all
                                            solutions</p>
                                        <span className="time_date"> 11:01 AM    |    June 9</span> </div>
                                </div> */}
                                </div>
                                <div className="type_msg">
                                    <div className="input_msg_write">
                                        <button className="msg_send_btn col-2" style={{ zIndex: 1, padding: 4, top: 12, right: 40 }} type="button" onClick={() => {
                                            selectImage("add_chat_img");
                                        }}><i className="fa fa-camera" aria-hidden="true"></i></button>

                                        <div>
                                            <input type="text" value={textMessage} className="write_msg" onChange={(event) => { setTextMessage(event.target.value) }} placeholder="Type a message" />
                                        </div>
                                        <button className="msg_send_btn col-3" type="button" onClick={() => {
                                            sendMessage(textMessage);
                                        }}><i className="fa fa-paper-plane-o" aria-hidden="true"></i></button>

                                        <input type="file" id={"add_chat_img"}
                                            style={{ display: "none" }} onChange={(e) => { onImageChange(e, 'add_chat_img'); }} />


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div >
    );
}

export default Chat;

