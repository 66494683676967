/* global grecaptcha */
import { getAuth, signInWithPhoneNumber, GoogleAuthProvider, signInWithPopup, RecaptchaVerifier } from "@firebase/auth";
import { FaBeer, FaFacebook, FaFacebookF, FaGooglePlusG, FaMobile, FaPhone } from 'react-icons/fa';
// import { toast, ToastContainer } from "react-toastify";
import { postData } from "../api";
import { AddUserUrl, EmailLoginUrl, GoogleLoginUrl, PhoneNumberLoginUrl } from "../constants/endPoints";
import { firebase, auth } from "../constants/FirebaseConstraints";
// import useVechaiuiNotification from "../Hooks/useVechaiuiNotification";
import { Modal, notification } from "antd";
import useLocalStorage from "../Hooks/useLocalStorage";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { notify } from "../Components/AppNotification";
// import 'firebaseu'
// const auth = getAuth();
// const provider = new GoogleAuthProvider();
// provider.setCustomParameters({ prompt: 'select_account' });
// const [user,setUser] = u
const Signup = () => {
    const googleAuthProvider = new GoogleAuthProvider();
    const [user, setUser] = useLocalStorage("user");
    const history = useHistory();
    const [isOtpOpen, setOtpOpen] = useState(false);
    const [openConfirmOtp, setOpenConfirmOtp] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('');
    const [recaptcha, setRecaptcha] = useState()
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUserName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    // global grecaptcha;
    // let appVerifier = null;
    const showOTPModal = () => {
        setOtpOpen(true);
    }
    const hideOTPModal = () => {
        setOtpOpen(false);
    }
    const showConfirmModal = () => {
        setOpenConfirmOtp(true);
    }
    const hideConfirmModal = () => {
        setOpenConfirmOtp(false);
    }
    const signInWithGoogle = () => {
        signInWithPopup(auth, googleAuthProvider).then(async (res) => {
            console.log(res)
            if (!res.user) {
                return;
            }
            try {
                let data = {
                    google_id: res.user.uid,
                    user_name: res.user.displayName,
                    user_email: res.user.email,
                    profile_photo_url: res.user.photoURL,
                };
                console.log(data);
                let response = await postData(GoogleLoginUrl, data);
                if (response.data.user_id) {
                    setUser(response.data);
                    notification.success({
                        message: "Success",
                        description: "Successfully Logged In"
                    });
                    history.push('/');
                }
                // notificationHandler("Success","Logged In Successfully",'success');
                // messageHandler("Logged In Successfully",'success');
                console.log(response);
            } catch (e) {
                console.log(e);
            }
        }).catch(e => {
            console.log(e)
        });
    }

    // =++=============== MOBILE VERIFICATION
    const sendOtp = () => {
        console.log(phoneNumber);
        if (phoneNumber.length < 8) {
            notification.error({
                message: "Error",
                description: "Phone length is less then 8"
            });
            return;
        }
        // configureRecaptha();
        const appVerifier = recaptcha;
        console.log(recaptcha);
        if (appVerifier == null) {
            notification.error({
                message: "Error",
                description: "Cannot send message right now"
            });
            return;
        }
        // notification.success({
        //     message: "Success",
        //     description: "Successfully Logged In"
        // });
        signInWithPhoneNumber(auth, phoneNumber, recaptcha)
            .then((confirmationResult) => {
                console.log("Otp sent");
                notify('Success', "OTP has been sent to your number.", 'success');
                hideOTPModal();
                showConfirmModal();
                window.confirmationResult = confirmationResult;
            }).catch((error) => {
                console.log("otp error", JSON.stringify(error));
                notify("Error", error.code + " try again later", 'error');
            });
    }
    const saveUser = async (user) => {
        try {
            const data = {
                phone_id: user.uid,
                user_name: "",
                user_phone: user.phoneNumber,
                device_token: "Web"
            }
            let response = await postData(PhoneNumberLoginUrl, data);
            if (response.data.user_id) {
                setUser(response.data);
                notification.success({
                    message: "Success",
                    description: "Successfully Logged In"
                });
                history.push('/');
            }
        } catch (e) {

        }

    }
    const signupUser = async () => {
        try {
            if (email.length < 1) {
                notification.error({
                    message: "Error",
                    description: "Please enter valid email address"
                });
                return;
            }
            if (password.length < 1) {
                notification.error({
                    message: "Error",
                    description: "Please enter valid password"
                });
                return;
            }
            if (username.length < 1) {
                notification.error({
                    message: "Error",
                    description: "Please enter valid username"
                });
                return;
            }

            const data = {
                user_email: email,
                user_password: password,
                user_name: username,
                device_token: "Web"
            }
            setIsLoading(true);
            let response = await postData(AddUserUrl, data);
            setIsLoading(false);
            if (!response.data) {
                notification.error({
                    message: "Error",
                    description: "No account exist"
                });
                return
            }
            if (response.data.user_id) {
                setUser(response.data);
                notification.success({
                    message: "Success",
                    description: "Successfully Signed up"
                });
                history.push('login');
            } else {
            }
        } catch (e) {
            console.log("login error: ", e);
        }

    }

    const confirmOtp = () => {
        // console.log(code, window.confirmationResult);
        // // return;
        // if (code.length < 5) {
        //     notification.error({
        //         message: "Error",
        //         description: "Invalid Code"
        //     });
        //     return;
        // }
        // window.confirmationResult.confirm(code).then((result) => {
        //     const user = result.user;
        //     notify("Success", "Successfully Verified", 'success');
        //     saveUser(user);
        //     console.log(user);
        // }).catch((error) => {
        //     console.log(error);
        //     notify('Error', JSON.stringify(error) + ' try again later', 'error');
        // });
    }

    const signInWithPhone = () => {
        showOTPModal();
    }
    const configureRecaptha = () => {
        // const auth = getAuth();
        // try {
        //     window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
        //         size: 'invisible',
        //         defaultCountry: 'PK',
        //         callback: (response) => { }
        //     }, auth);
        //     window.recaptchaVerifier.render().then((widgetId) => {
        //         window.recaptchaWidgetId = widgetId;
        //     });

        // } catch (e) {
        //     console.log("error", e);
        // }
        // console.log(window.recaptchaVerifier);
    }
    useEffect(() => {
        // window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
        //     size: 'invisible',
        //     defaultCountry: 'PK',
        //     callback: (response) => { }
        // }, auth);
        // if (!recaptcha) {
        //     window.recaptchaVerifier.verify().then(() => setRecaptcha(window.recaptchaVerifier));
        // }
        // return () => {
        //     window.recaptchaVerifier.clear()
        // }
    }, [])
    return (
        <div className="login-page mb-4">
            <div className="login-page-container  mt-3">
                <Modal visible={isOtpOpen} onCancel={() => { hideOTPModal() }} onOk={sendOtp} >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                    }} className="mt-4">
                        <label>Phone</label>
                        <input type="phone" className="form-control" placeholder="+92XXXXXXXXXXXX" value={phoneNumber}
                            onChange={(event) => { setPhoneNumber(event.target.value) }} />
                    </form>
                </Modal>
                <Modal visible={openConfirmOtp} onCancel={hideConfirmModal} onOk={confirmOtp} okText="Submit" >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                    }} className="form mt-4">
                        <label>Code</label>
                        <input className="form-control" type="number" placeholder="XXXXXX" value={code}
                            onChange={(event) => { console.log(event); setCode(event.target.value) }} />
                    </form>
                </Modal>
                <main>
                    <section className="h-100">
                        <div className="container py-5 h-100">
                            <div className="row d-flex align-items-center justify-content-center h-100">
                                <div className="col-md-8 col-lg-7 col-xl-6">
                                    <img src="https://mdbootstrap.com/img/Photos/new-templates/bootstrap-login-form/draw2.svg" className="img-fluid" alt="Phone image" />
                                </div>
                                <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
                                    <div className="col-12 specials">
                                        <h2>Create New Account?</h2>
                                        <h2>Signup</h2>
                                    </div>
                                    <form>
                                        {/* User name */}
                                        <div className="form-outline mb-4">
                                            <input
                                                type="text"
                                                id="username"
                                                className="form-control form-control-lg"
                                                value={username}
                                                onChange={(e) => setUserName(e.target.value)}
                                            />
                                            <label
                                                className="form-label"
                                                htmlFor="username"


                                            >User Name</label>
                                        </div>
                                        {/* <!-- Email input --> */}
                                        <div className="form-outline mb-4">
                                            <input
                                                type="email"
                                                id="email"
                                                className="form-control form-control-lg"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <label
                                                className="form-label"
                                                htmlFor="email"


                                            >Email address</label>
                                        </div>

                                        {/* <!-- Password input --> */}
                                        <div className="form-outline mb-4">
                                            <input
                                                type="password"
                                                id="password"
                                                className="form-control form-control-lg"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <label className="form-label" htmlFor="password">Password</label>
                                        </div>

                                        

                                        {/* <!-- Submit button --> */}
                                        <button type="button" onClick={signupUser} className="btn btn-secondary btn-lg btn-block" disabled={isLoading}>
                                           Create Account
                                        </button>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    );
}

export default Signup;