import sampleReducer from "./sampleReducer";
import { combineReducers } from "redux";
import { homePopularProductReducer, homeRecentProductReducer } from "./productReducer";
import { categoryReducer } from "./categoryReducer";
import { blogReducer } from "./blogReducer";
import { menufecturerReducer, searchMenufecturerReducer } from "./menufecturerReducer";
import { locationReducer } from "./locationReducer";
import { adsReducer } from "./adsReducer";
import { generalReducer } from "./generalReducer";

const rootReducer = combineReducers({
    sample: sampleReducer,
    home_recent_product: homeRecentProductReducer,
    home_popular_product :homePopularProductReducer,
    category :categoryReducer,
    blog: blogReducer,
    menufecturer: menufecturerReducer,
    search_menufecturer: searchMenufecturerReducer,
    location: locationReducer,
    ads: adsReducer,
    mjglobal: generalReducer,
});

export default rootReducer;