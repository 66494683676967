const FooterContactUsForm = () => {
    return (
        <div>
            <div id="modal-form" className="modal-form">

                <p className="modal-form-ttl">Contact Us</p>
                <form action="#" className="form-validate">
                    <input data-required="text" type="text" placeholder="Name" name="name2" />
                    <input data-required="text" type="text" placeholder="Phone" name="phone2" />
                    <input data-required="text" data-required-email="email" type="text" placeholder="Email" name="email2" />
                    <textarea placeholder="Your message" name="mess2"></textarea>
                    <input type="submit" value="Send" />
                </form>
            </div>
        </div>
    );
}

export default FooterContactUsForm;