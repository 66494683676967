import { message, notification } from "antd";
import axios from "axios";
// import jwtDecode from "jwt-decode"; already installed
import {
    LOGIN,
    APPLICANTS_LIST_STATUS,
    APPLICANTS_BY_ID,
    APPROVE_OR_DECLINE_APPLICANTS,
    SIGNUP
} from "../constants";

const {
    REACT_APP_AXIOS_RETRY,
    REACT_APP_API_PREFIX,
    REACT_APP_CONTENT_TYPE,
    REACT_APP_APPLICATION_X_WWW_FORM_URLENCODED,
} = process.env;

// Constants
const AXIOS_RETRY = REACT_APP_AXIOS_RETRY;
const API_PREFIX = REACT_APP_API_PREFIX;
const CONTENT_TYPE = REACT_APP_CONTENT_TYPE;
const APPLICATION_X_WWW_FORM_URLENCODED =
    REACT_APP_APPLICATION_X_WWW_FORM_URLENCODED;

// export const login = ({ email, password }) => {
//     return axios.post(
//         `${API_PREFIX}${LOGIN}`,
//         { email, password },
//         {
//             [AXIOS_RETRY]: {
//                 retries: 3,
//             },
//             errorHandling: {
//                 global: true,
//             },
//         }
//     );
// };

// export const signup = (data) => {
//     return axios.post(
//         `${API_PREFIX}${SIGNUP}`,
//         data,
//         {
//             [AXIOS_RETRY]: {
//                 retries: 3,
//             },
//             errorHandling: {
//                 global: true,
//             },
//         }
//     );
// };

// Get Applicant's List Api
export const getData = async (url) => {
    try {
        let response = axios.get(
            `${API_PREFIX}${url}`,
            {
                [AXIOS_RETRY]: {
                    retries: 2,
                },
                errorHandling: {
                    global: true,
                },
            }
        );
        if (typeof response.data == 'string') {
            message.error('Unfortunate error');
            return { data: null }
        }
        return response;
    } catch (e) {
        console.log(e);
        return { data: null }
    }
}
export const postData = async (url, data, isShowError = false) => {
    // console.log("data", data);
    try {
        axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        // axios.defaults.headers['Authorization'] = '';
        // axios.defaults.headers['origin'] = '*';
        let response = await axios.post(
            `${API_PREFIX}${url}`,
            // `https://jsonplaceholder.typicode.com/users`,
            data,
            {
                // headers:{"Content-Type" : "application/json"},
                [AXIOS_RETRY]: {
                    retries: 3,
                },
                errorHandling: {
                    global: true,
                },
            }
        ).catch(e=>{
            
            if(isShowError && e.response){
                let message = e.response.data.message;
                console.log(message);
                notification.error({ message: "error", description: message });   

            }
        });
        
        // console.log(typeof response.data);
        if (typeof response.data == 'string') {
            message.error('Unfortunate error');
            return { data: null }
        }
        return response;
    } catch (e) {
    
        console.log("error message",e.message);
        return { data: null }
    }
}


// export const getApplicantsListWithRecord = (status) => {
//     return axios.get(
//         `${API_PREFIX}${APPLICANTS_LIST_STATUS}${status}`,
//         {
//             [AXIOS_RETRY]: {
//                 retries: 2,
//             },
//             errorHandling: {
//                 global: true,
//             },
//         }
//     );
// }

// // Get Applicant By Id Api
// export const getApplicantDetails = (id) => {
//     return axios.get(
//         `${API_PREFIX}${APPLICANTS_BY_ID}${id}`,
//         {
//             [AXIOS_RETRY]: {
//                 retries: 2,
//             },
//             errorHandling: {
//                 global: true,
//             },
//         }
//     );
// }

// // Approve Decline Applicant By Id Api
// export const applicantApprovedOrDeclined = (data) => {
//     return axios.put(
//         `${API_PREFIX}${APPROVE_OR_DECLINE_APPLICANTS}`,
//         data,
//         {
//             [AXIOS_RETRY]: {
//                 retries: 2,
//             },
//             errorHandling: {
//                 global: true,
//             },
//         }
//     );
// }