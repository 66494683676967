
export const updateAds = (data) => {
    return (dispatch, getState) => {
        if (typeof(data) == 'object' || Array.isArray(data)) {
            dispatch({ type: 'DATA', ads: 
            { ...data } })
        } else {
            dispatch({ type: "ERROR", ads: [] });
        }
    }
}