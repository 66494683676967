
export const updateMenufecturers = (data) => {
    return (dispatch, getState) => {
        if (typeof(data) == 'object' || Array.isArray(data)) {
            dispatch({ type: 'DATA', menufecturer: 
            { ...data } })
        } else {
            dispatch({ type: "ERROR", menufecturer: [] });
        }
    }
}
export const updateSearchMenufecturers = (data) => {
    return (dispatch, getState) => {
        if (typeof(data) == 'object' || Array.isArray(data)) {
            dispatch({ type: 'DATA', search_menufecturer: 
            { ...data } })
        } else {
            dispatch({ type: "ERROR", search_menufecturer: [] });
        }
    }
}