import { Redirect, Route } from "react-router";
import { getFromStorage } from "../Hooks/useLocalStorage";
// Router
const LocationRoutes = ({children , ...rest}) => {
    // console.log(children);
    // console.log("res",rest);
    return ( 
        <Route
        {...rest}
        render={({ location }) =>
        //    getFromStorage("location") ? (
                children
        //     ) :(
                // <Redirect
                //     to={{
                //         pathname: "/select_location",
                //         state: { from: location }
                //     }}
                // />
            // )
        }
    />
     );
}
 
export default LocationRoutes;