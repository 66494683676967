const initalState = {
    location: { isLoading: false, data: [] },
};
export const locationReducer = (state = initalState.location, action) => {
    switch (action.type) {
        case 'DATA':
            return {
                ...state,
                ...action.location
            };
        case 'ERROR':
            return state;
        default:
            return state;
    }
}