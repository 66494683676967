import { faMapMarked } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { PREV_IMG } from "../../constants/endPoints";
import { convertNumber, convertToBase64, createItemUrl, currencyFormat } from "../../constants/helper";

const ProductItem = ({ val, index, type, ...props }) => {
    return <div className="product-item special col-lg-3 col-md-4" key={index + type}>
        <Link to={() => {
            return "/product_details/" + convertToBase64(val.id) + createItemUrl(val);
        }} className="special-link" style={{ position: "relative" }}>
            <div className="product-image">
                <img className="logo-image" src="/external_assets/img/motor_logo.png" width={80} height={40} />
                <p className="special-img">
                    <img className="home-item-img" src={PREV_IMG + val.default_photo.img_path} alt="" onError={(e) => { e.target.src = "./assets/images/placeholder.jpeg" }} />
                </p>
            </div>
            {/*  */}
            {
                (val.manufacturer.name || val.manufacturer_id == -1) &&
                <h3><span className="title">
                    {val.manufacturer_id == -1 ? val.manufacturer_name : val.manufacturer.name}
                    &nbsp;{val.model_id == -1 ? val.model_name : val.model.name}
                    &nbsp;{val.year}</span></h3>
            }
            {
                (!val.manufacturer.name && val.manufacturer_id != -1) &&
                <h3><span className="title">{val.title}</span></h3>
            }
            {/*  */}
        </Link>
        <div className="row home-item-info">
            <div className="col-12">
                <ul className="information ps-0">
                    {val.year && <li><span>{val.year} </span></li>}
                    {val.mileage && convertNumber(val.mileage) > 0 && <li><span>{val.mileage} km</span></li>}
                    {val.fuel_type.fuel_name && <li><span>{val.fuel_type.fuel_name}</span></li>}
                    {val.transmission.name && <li><span>{val.transmission.name}</span></li>}
                    {val.engine_power && <li><span>{val.engine_power}</span></li>}
                    {val.condition_of_item.name && <li><span>{val.condition_of_item.name}</span></li>}
                    {val.sell_type && <li><span>{val.sell_type == "Rent" ? "Aluguel" : "Venda"}</span></li>}
                </ul>
                <section aria-label="Price" class="price-heading">
                    <div data-testid="ad-card-price" class="price heading-font">
                        <span>{currencyFormat(val.price)}</span><sup class="ooa-od9pi4 e4bdfv60">kz</sup>
                    </div>
                </section>

            </div>
        </div>
    </div>;
}

export default ProductItem;