
export const updateHomeRecentProducts = (data) => {
    return (dispatch, getState) => {
        if (typeof(data) == 'object' || Array.isArray(data)) {
            dispatch({ type: 'DATA', home_recent_product: 
            { ...data } })
        } else {
            dispatch({ type: "ERROR", home_recent_product: [] });
        }
    }
}
export const updateHomePopularProducts = (data) => {
    return (dispatch, getState) => {
        if (typeof(data) == 'object' || Array.isArray(data)) {
            dispatch({ type: 'DATA', home_popular_product: 
            { ...data } })
        } else {
            dispatch({ type: "ERROR", home_popular_product: [] });
        }
    }
}