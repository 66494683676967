import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { getData, postData } from "../api";
import { getChatHistory, getItemDetailUrl, getItemImages, PREV_IMG, deleteItemUrl, getLocationsUrl } from "../constants/endPoints";
import { convertFromBase64, convertNumber, convertToBase64, currencyFormat, setLoadingState } from "../constants/helper";
import { getItemData } from "../Hooks/useItemParams";
import DataNotFound from "./DataNotFound";
import useConsole from "../Hooks/useConsole";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEnvelopeOpen, faInbox, faMailBulk, faPhone, faStar } from "@fortawesome/free-solid-svg-icons";
import { FaAd, FaArrowAltCircleLeft, FaEdit, FaEnvelopeOpen, FaInbox, FaPhone, FaRegEdit, FaSellsy, FaTrash, FaTypo3 } from "react-icons/fa";
import { MdEmail, MdOutlineFormatTextdirectionLToR } from "react-icons/md";
import { getCurrentUserId } from "../constants/sessionHelper";
import { notify } from "../Components/AppNotification";
import { Modal, Button, Space } from 'antd';
import { PARTS, SERVICES } from "../constants";
import { compose } from 'redux';
import { updateLocation } from '../ReduxStore/actions/locationAction';
import { connect } from 'react-redux';


const ProductDetail = ({ ...props }) => {
    const { id } = useParams();
    // console.log(id);
    const [productId, setProductId] = useState((prev) => convertFromBase64(id));
    const [productDetails, setProductDetail] = useState({ data: null, isLoading: true });
    const [images, setImages] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const history = useHistory();
    useConsole("productDetails", productDetails);
    const showModal = () => {
        setConfirm(true);
    }

    const hideModal = () => {
        setConfirm(false);
    }

    const getProductData = async () => {
        try {
            setProductDetail((prev) => { return { ...setLoadingState(prev, true) } });
            let res = await getData(getItemDetailUrl("get", 10, 0, productId));
            setProductDetail((prev) => { return { ...setLoadingState(prev, false, res.data) } });
            let imagesRes = await getData(getItemImages(productId));
            console.log(imagesRes);
            setImages((prev) => { return [...imagesRes.data]; })


            setTimeout(() => {
                window.onProductDetailLoaded();
            }, 100);
            this.forceUpdate()
        } catch (e) {
            console.log(e);
            setProductDetail((prev) => { return { ...setLoadingState(prev, false) } });
        }
    }
    const startChat = async () => {
        try {
            let data = {
                item_id: productDetails.data.id,
                buyer_user_id: getCurrentUserId(),
                seller_user_id: productDetails.data.added_user_id
            }
            notify("Please Wait", "starting chat....", 'info');
            let res = await postData(getChatHistory(), data);
            console.log(res.data);
            history.push({ pathname: '/chats', chat: res.data });
        } catch (e) {
            notify('Error', 'unable to start chat', 'error');
        }
    }
    const getLocation = async () => {
        try {
            props.updateLocation({ isLoading: true });
            const res2 = await getData(getLocationsUrl("get", 100, 0));
            if (!res2.data) {
                props.updateLocation({ isLoading: false });
                return;
            }
            props.updateLocation({ isLoading: false, data: res2.data });
            console.log("Props location data", props);

        } catch (e) {
            console.log(e);
            props.updateLocation({ isLoading: false });
        }
    }
    const deleteItem = async () => {
        // items/item_delete/api_key/teampsisthebest
        const data = { item_id: productDetails.data.id };
        let res = await postData(deleteItemUrl(), data);
        console.log(res.data);
        history.push('/');
    }
    const editAd = () => {
        const data = { item_id: productDetails.data.id };
        history.push('/edit_item/' + convertToBase64(data.item_id));
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        setProductId(convertFromBase64(id));
        getLocation();

    }, [])
    useEffect(() => {
        if (productId != null) {
            getProductData();

        }
    }, [productId])
    let loc;
    if (productId && productDetails.data != null) {

        props.location.data.forEach(data => {
            if (data.id == productDetails.data.item_location_id) {
                loc = data;
            }
        })
        console.log("location data: ", loc);
    }


    return (
        <div className="product-detail-page">
            {/* {console.log(productId && productDetail.data)} */}
            <Modal
                title="Confirme?"
                visible={confirm}
                onOk={deleteItem}
                onCancel={hideModal}
                okText="Sim"
                cancelText="Cancelar"
            >
                <p>Tem certeza de que deseja excluir esta postagem?</p>
            </Modal>
            {(productId && productDetails.data != null) ? <main>


                {productDetails.data.added_user_id == getCurrentUserId() && <div style={
                    {
                        position: 'fixed',
                        bottom: 10,
                        right: 10,
                        display: 'flex',
                        cursor: 'pointer',
                        zIndex: 3,
                        border: "none"
                    }
                }>
                    <div>
                        <button style={{
                            padding: '1em',
                            backgroundColor: '#930002',
                            color: 'white',
                            borderColor: 'black',
                            borderRadius: 100,
                            zIndex: 1,
                            boxShadow: '#8080803d 0px 4px 20px 6px',
                        }} onClick={showModal}><FaTrash size={30} /></button>
                        <button style={{
                            padding: '1em',
                            backgroundColor: '#930002',
                            color: 'white',
                            borderColor: 'black',
                            borderRadius: 100,
                            zIndex: 1,
                            boxShadow: '#8080803d 0px 4px 20px 6px',
                        }} onClick={editAd}><FaEdit size={30} /></button>
                    </div>
                </div>}
                {/* <!-- Breadcrumbs --> */}
                <div className="b-crumbs-wrap">
                    <div className="cont b-crumbs">
                        <ul>
                            <li>
                                <FaArrowAltCircleLeft size={20} onClick={() => {
                                    window.history.back();
                                }} />
                            </li>
                            <li>
                                <Link to="/">Motor</Link>
                            </li>
                            {
                                productDetails.data.manufacturer && productDetails.data.manufacturer.name && <li>
                                    <a href="#" onClick={(e) => {
                                        console.log("here");
                                        let itemForm = getItemData();
                                        itemForm.manufacturer_id = productDetails.data.manufacturer.id;
                                        console.log("products/" + convertToBase64(JSON.stringify(itemForm)));
                                        history.push({ pathname: "/products/" + convertToBase64(JSON.stringify(itemForm)) });
                                    }}>{productDetails.data.manufacturer.name}</a>
                                </li>
                            }
                            {
                                productDetails.data.model && productDetails.data.model.name && <li>
                                    <a href="#" onClick={(e) => {
                                        // e.preventDefault();
                                        console.log("here");
                                        let itemForm = getItemData();
                                        if (productDetails.data.manufacturer)
                                            itemForm.manufacturer_id = productDetails.data.manufacturer.id;
                                        itemForm.model_id = productDetails.data.model.id;
                                        console.log("products/" + convertToBase64(JSON.stringify(itemForm)));
                                        history.push({ pathname: "/products/" + convertToBase64(JSON.stringify(itemForm)) });
                                    }}>{productDetails.data.model.name}</a>
                                </li>
                            }
                            {/* {
                                (productDetails.data.manufacturer.name || productDetails.data.manufacturer_id == -1) &&
                                <li>
                                    <span>{productDetails.data.manufacturer_id == -1 ? productDetails.data.manufacturer_name : productDetails.data.manufacturer.name}
                                        &nbsp;{productDetails.data.model_id == -1 ? productDetails.data.model_name : productDetails.data.model.name}
                                        &nbsp;{productDetails.data.year}</span>
                                </li>
                            }
                            {
                                (!productDetails.data.manufacturer.name && productDetails.data.manufacturer_id != -1) &&
                                <li>
                                    <span>{productDetails.data.title}</span>
                                </li>
                            } */}
                        </ul>
                        {/* <div className="b-crumbs-menu">
                            <a id="b-crumbs-menu" href="#"></a>
                            <ul className="b-crumbs-menulist">
                                <li><a href="product.html">Product: Standart</a></li>
                                <li><a href="product-vehicle.html">Product: Vehicle</a></li>
                            </ul>
                        </div> */}
                    </div>
                </div>

                <div className="cont maincont">
                    <span className="maincont-line1"></span>
                    <span className="maincont-line2"></span>

                    {/* <!-- Product - start --> */}
                    <div className="prod">

                        {/* <!-- Product Slider - start --> */}
                        <div className="prod-slider-wrap">
                            <div className="flexslider prod-slider" id="prod-slider">
                                <ul className="slides">
                                    {images.map((val1, i) => {
                                        return <li>
                                            <a data-fancybox-group="prod" className="fancy-img" href={PREV_IMG + val1.img_path}>
                                                <img src={PREV_IMG + val1.img_path} alt="" /></a>
                                        </li>
                                    })}

                                </ul>
                                <div>
                                    <span className='payment-note'>Atenção: Informamos que não devem ser feitos pagamento sem antes confirmarem a veracidade do produto bem como a documentação do mesmo. Não nos responsabilizamos pela negociação entre vendedor e comprador.</span>
                                </div>
                            </div>

                            <div className="flexslider prod-thumbs" id="prod-thumbs">
                                <ul className="slides">
                                    {images.map((val, i) => {
                                        return <li>
                                            <img src={PREV_IMG + val.img_path} alt="" />
                                        </li>
                                    })}

                                </ul>
                            </div>
                        </div>
                        {/* <!-- Product Slider - end --> */}

                        {/* <!-- Product Content - start --> */}

                        <div className="row home-item-info">
                            <h5 className="heading-font pt-2">{
                                (productDetails.data.manufacturer.name || productDetails.data.manufacturer_id == -1) &&
                                <span> {productDetails.data.manufacturer_id == -1 ? productDetails.data.manufacturer_name : productDetails.data.manufacturer.name}
                                    &nbsp;{productDetails.data.model_id == -1 ? productDetails.data.model_name : productDetails.data.model.name}
                                    &nbsp;{productDetails.data.year}</span>
                            }
                                {
                                    (!productDetails.data.manufacturer.name && productDetails.data.manufacturer_id != -1) &&
                                    <span>{productDetails.data.title}</span>
                                }</h5>
                            <div className="col-12">
                                {productDetails.data.category_id != SERVICES && <ul className="information ps-0">
                                    {productDetails.data.year && <li><span>{productDetails.data.year} </span></li>}
                                    {productDetails.data.mileage && convertNumber(productDetails.data.mileage) > 0 && <li><span>{productDetails.data.mileage} km</span></li>}
                                    {productDetails.data.fuel_type.fuel_name && <li><span>{productDetails.data.fuel_type.fuel_name}</span></li>}
                                    {productDetails.data.transmission.name && <li><span>{productDetails.data.transmission.name}</span></li>}
                                    {productDetails.data.engine_power && <li><span>{productDetails.data.engine_power}</span></li>}
                                    {productDetails.data.condition_of_item.name && <li><span>{productDetails.data.condition_of_item.name}</span></li>}
                                    {productDetails.data.sell_type && <li><span>{productDetails.data.sell_type == "Rent" ? "Aluguel" : "Venda"}</span></li>}
                                </ul>}
                                {productDetails.data.category_id == SERVICES &&
                                    <ul className="information ps-0">
                                        {productDetails.data.service_area && <li><span>Área de serviço: &nbsp;{productDetails.data.service_area} </span></li>}
                                        {loc && loc.name && <li><span>Província: &nbsp;{loc.name} </span></li>}
                                    </ul>
                                }
                                {productDetails.data.category_id != SERVICES && <section aria-label="Price" class="price-heading pt-1">
                                    <div data-testid="ad-card-price" class="price heading-font product-detail-price">
                                        <span>{currencyFormat(productDetails.data.price)}</span><sup class="ooa-od9pi4 e4bdfv60">kz</sup>
                                    </div>
                                </section>}
                                <div className="pt-1">
                                    <div className="row">
                                        <h5 className="product-detail-phone app-color">
                                            <span className="heading-font">
                                                <FaPhone />
                                                <a href={"tel:" + productDetails.data.phone_number} className='app-color'>
                                                    &nbsp;{productDetails.data.phone_number}
                                                </a>
                                            </span>
                                        </h5>
                                    </div>
                                    {productDetails.data.second_phone_number &&
                                        <div className="row">
                                            <h5 className="product-detail-phone app-color">
                                                <span className="heading-font">
                                                    <FaPhone />
                                                    <a href={"tel:" + productDetails.data.second_phone_number} className='app-color'>
                                                        &nbsp;{productDetails.data.second_phone_number}
                                                    </a>
                                                </span>
                                            </h5>
                                        </div>}
                                </div>
                                {productDetails.data.email && <div className="pt-1">
                                    <div className="row">
                                        <h5 className="product-detail-email app-color">
                                            <div class="contact-box">
                                                <a class="om-button contact-button" href={"mailto:" + productDetails.data.email} >
                                                    <MdEmail size={23} />
                                                    &nbsp; &nbsp;
                                                    <span class="contact-box__label">Contactar Vendedor</span></a>
                                            </div>
                                        </h5>
                                    </div>
                                </div>}
                                {productDetails.data.category_id != SERVICES && <div className="pt-0">
                                    <div className="row">
                                        <h6 className="product-detail-phone"><span className="heading-font"><FaSellsy /> {productDetails.data.sell_type == "Rent" ? "Alugar" : "Venda"}</span></h6>
                                    </div>
                                </div>}

                                {
                                    productDetails.data.category_id == SERVICES && productDetails.data.description && <div>
                                        <h5>
                                            Descrição:
                                        </h5>
                                        <p>
                                            {productDetails.data.description}
                                        </p>
                                    </div>
                                }
                                {
                                    productDetails.data.highlight_info && <div>
                                        <h5>
                                            Informação:
                                        </h5>
                                        <p>
                                            {productDetails.data.highlight_info}
                                        </p>
                                    </div>
                                }
                                {
                                    productDetails.data.address && <div>
                                        <h5>
                                            address
                                        </h5>
                                        <p>
                                            {productDetails.data.address}
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>

                        {/* <!-- Product Content - end --> */}

                    </div>
                    {/* <!-- Product - end --> */}



                    <div className="prod-desc">
                        {productDetails.data.description && <div>
                            <h5>Descrição</h5>
                            <p style={{ color: '#868ca7' }}>{productDetails.data.description}</p>
                        </div>}
                        <h5 className="pt-4">Característicos</h5>
                        <table className="charac-table table-responsive">
                            <thead>
                                <tr>
                                    <td width="10%"></td>
                                    <td width="20%"></td>
                                    <td width="20%"></td>
                                    <td width="20%"></td>
                                    <td width="20%"></td>
                                </tr>
                            </thead>
                            <tbody>
                                {productDetails.data.category_id != SERVICES && <tr>
                                    <td>{productDetails.data.category_id == PARTS ? "Categoria"
                                        : "Marca"}</td>
                                    <td className="prod-desc-item-c">{
                                        (productDetails.data.manufacturer ||
                                            productDetails.data.manufacturer_id == -1) &&
                                            (productDetails.data.manufacturer_id == -1) ?
                                            productDetails.data.manufacturer_name :
                                            productDetails.data.manufacturer.name}</td>
                                </tr>}
                                {productDetails.data.category_id != SERVICES && <tr>
                                    <td>{productDetails.data.category_id == PARTS ? "Sub Categoria"
                                        : "Modelo"}</td>
                                    <td className="prod-desc-item-c">{
                                        (productDetails.data.model ||
                                            productDetails.data.model_id == -1) &&
                                            productDetails.data.model_id == -1 ? productDetails.data.model_name :
                                            productDetails.data.model.name}</td>
                                </tr>}
                                {productDetails.data.build_type && (productDetails.data.build_type.car_type) && <tr>
                                    <td>Tipo de veículo</td>
                                    <td className="prod-desc-item-c">{productDetails.data.build_type.car_type}</td>
                                </tr>}
                                {productDetails.data.category_id != SERVICES && productDetails.data.item_type && <tr>
                                    <td>Tipo</td>
                                    <td className="prod-desc-item-c">{productDetails.data.item_type}</td>
                                </tr>}
                                {productDetails.data.category_id != SERVICES && <tr>
                                    <td>Ano</td>
                                    <td className="prod-desc-item-c">{productDetails.data.year}</td>
                                </tr>}
                                {productDetails.data.category_id != SERVICES && productDetails.data.mileage &&
                                    (productDetails.data.mileage > 0) && <tr>
                                        <td>Quilômetros</td>
                                        <td className="prod-desc-item-c">{currencyFormat(productDetails.data.mileage)} Km</td>
                                    </tr>}
                                {productDetails.data.category_id != SERVICES && productDetails.data.color.color_value && <tr>
                                    <td>Cor</td>
                                    <td className="prod-desc-item-c">{productDetails.data.color.color_value}</td>
                                </tr>}
                                {productDetails.data.category_id != SERVICES && productDetails.data.fuel_type &&
                                    productDetails.data.fuel_type.name && <tr>
                                        <td>Tipo de combustível</td>
                                        <td className="prod-desc-item-c">{productDetails.data.fuel_type.name}</td>
                                    </tr>}
                                {productDetails.data.category_id != SERVICES && productDetails.data.transmission.name && <tr>
                                    <td>Caixa</td>
                                    <td className="prod-desc-item-c">{productDetails.data.transmission.name}</td>
                                </tr>}
                                {productDetails.data.category_id != SERVICES && productDetails.data.plate_number && <tr>
                                    <td>Matricula</td>
                                    <td className="prod-desc-item-c">{productDetails.data.plate_number}</td>
                                </tr>}
                                {productDetails.data.category_id != SERVICES && <tr>
                                    <td>Preço</td>
                                    <td className="prod-desc-item-c">{currencyFormat(productDetails.data.price)}
                                        {productDetails.data.item_currency && productDetails.data.item_currency.currency_symbol}</td></tr>

                                }
                                {productDetails.data.category_id != SERVICES && productDetails.data.engine_power && <tr>
                                    <td>Poder do motor</td>
                                    <td className="prod-desc-item-c">{productDetails.data.engine_power}</td>
                                </tr>}
                                {productDetails.data.category_id != SERVICES && <tr>
                                    <td>Estado</td>
                                    <td className="prod-desc-item-c">{productDetails.data.condition_of_item.name}</td>
                                </tr>}
                                {productDetails.data.category_id != SERVICES && productDetails.data.item_location.name && <tr>
                                    <td>Provincia</td>
                                    <td className="prod-desc-item-c">{productDetails.data.item_location.name}</td>
                                </tr>}
                                <tr>
                                    <td>Carregada</td>
                                    <td className="prod-desc-item-c">{productDetails.data.added_date_str}</td>
                                </tr>
                                {/* ========= */}
                            </tbody>
                        </table>
                        {/* <h5 className="pt-4">Informação <span className="prod-desc-profile">do usuário</span></h5>
                        <div className="row pt-2">
                            <div className="col-12 user-info">
                                <div className="row">
                                    {productDetails.data.user.user_profile_photo && <div className="col-2">
                                        <img className="img-oc-w100" src={PREV_IMG + productDetails.data.user.user_profile_photo} />
                                    </div>}
                                    <div className="col-8">
                                        {productDetails.data.user.user_name && <div>
                                            <label><h6>{productDetails.data.user.user_name}</h6></label>
                                        </div>}
                                        {productDetails.data.user.user_phone && <div>
                                            <label><FontAwesomeIcon icon={faPhone} size={10} className="ic-rm4" />{productDetails.data.user.user_phone}</label>
                                        </div>}
                                        {productDetails.data.user.user_email && <div>
                                            <label><FontAwesomeIcon icon={faEnvelope} size={10} className="ic-rm4" />{productDetails.data.user.user_email}</label>
                                        </div>}
                                    </div>
                                </div>
                                <div className="row rating-container">


                                </div>

                            </div>
                        </div> */}

                    </div>

                </div>

            </main>
                : productDetails.isLoading ? <div className="d-flex justify-content-center align-items-center mt-8" style={{ height: 400 }}>
                    <Spinner animation="grow" />
                </div> : <DataNotFound />}
        </div>
    );
}

const mapLocationWithProps = (state) => {
    return {
        location: state.location
    }
}
const mapLocationActionWithProps = (dispatch) => {
    return {
        updateLocation: (_data) => dispatch(updateLocation(_data)),
    }
}

export default compose(
    connect(mapLocationWithProps, mapLocationActionWithProps),
)(ProductDetail);