const BankAccount = () => {
    return (<>
        <div className="row ps-3">
            <div className="card col-lg-6 col-md-6 col-sm-12 pt-3">
                <div className="card-body">
                    <h5 className="heading-font col-12">Detalhes da conta bancária</h5>
                    <hr></hr>
                    <h6 className="card-title heading-font mb-1 ps-3">BANCO BIC</h6>
                    <ol>
                        <li className="heading-font">IBAN:&nbsp;
                            <span className="normal-font">AO06.0051.0000.9194.8147.1011.9</span></li>
                        <li className="heading-font">Conta:&nbsp;
                            <span className="normal-font">1919.4814.7100.01</span></li>
                    </ol>
                    <hr></hr>
                    <h6 className="card-title heading-font mb-1 ps-3">BANCO BAI</h6>
                    <ol>
                        <li className="heading-font">IBAN:&nbsp;
                            <span className="normal-font">AO06.0040.0000.1618.4022.1013.2</span></li>
                        <li className="heading-font">Conta:&nbsp;
                            <span className="normal-font">216184022.10.001</span></li>
                    </ol>
                    <hr></hr>
                </div>
            </div>
        </div>
    </>);
}

export default BankAccount;