import { useState } from "react";
import { Carousel } from "react-bootstrap";
import { PREV_IMG } from "../../constants/endPoints";

const BlogSlider = ({ blogs: blogs }) => {
    // console.log(blogs);
    const [index, setIndex] = useState(0);
    // console.log("inside blogs");
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <Carousel activeIndex={index} onSelect={handleSelect} style={{padding:0}} >
            {
                blogs ? blogs.data.map((val, i) => {
                    return <Carousel.Item key={i} >
                        <img
                            className="d-block w-100"
                            style={{height:500,objectFit:'contain'}}
                            src={PREV_IMG + val.default_photo.img_path}
                            alt=""
                            onError={(e) => { e.target.src = "./assets/images/placeholder.jpeg" }}
                        />
                        <Carousel.Caption>
                            <div className="row">
                                <h3 className="heading-font app-color">{val.name}</h3>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                }) : <div></div>
            }
        </Carousel>
    );
}

export default BlogSlider;