const initalState = {
    menufecturer: { isLoading: false, data: [] },
    search_menufecturer: { isLoading: false, data: [] },
};
export const menufecturerReducer = (state = initalState.menufecturer, action) => {
    switch (action.type) {
        case 'DATA':
            return {
                ...state,
                ...action.menufecturer
            };
        case 'ERROR':
            return state;
        default:
            return state;
    }
}
export const searchMenufecturerReducer = (state = initalState.search_menufecturer, action) => {
    switch (action.type) {
        case 'DATA':
            return {
                ...state,
                ...action.search_menufecturer
            };
        case 'ERROR':
            return state;
        default:
            return state;
    }
}

