import { Carousel } from "react-bootstrap";

const IntroSlider = () => {
    return ( 
        <Carousel indicators={false} controls={false}  fade variant="dark">
            <Carousel.Item className="w-100 h-60" >
                <div style={{height:400}} className="intro-slide">
                    <img 
                        
                        src="/external_assets/img/intro_slide1.jpg"
                        // className="intro-slider-img"
                        // style={{objectFit:'contain',width:"100%",height:"100%"}}
                    />
                </div>
            </Carousel.Item>
            <Carousel.Item className="w-100 h-60">
                <div style={{height:400}} className="intro-slide">
                    <img 
                        
                        src="/external_assets/img/intro_slide2.jpg"
                        className="intro-slider-img"
                        // style={{objectFit:'contain',width:"100%",height:"100%"}}
                    />
                </div>
            </Carousel.Item>
            <Carousel.Item className="w-100 h-60">
                <div style={{height:400}} className="intro-slide">
                    <img 
                        
                        src="/external_assets/img/intro_slide3.jpg"
                        className="intro-slider-img"
                        // style={{objectFit:'contain',width:"100%",height:"100%"}}
                    />
                </div>
            </Carousel.Item>
            <Carousel.Item className="w-100 h-60">
                <div style={{height:400}} className="intro-slide">
                    <img 
                        src="/external_assets/img/intro_slide4.jpg"
                        className="intro-slider-img"
                    />
                </div>
            </Carousel.Item>
        </Carousel>
     );
}
 
export default IntroSlider;