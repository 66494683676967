import { Link } from "react-router-dom";
import { useHistory, useLocation } from 'react-router';
import { isLogin, logout } from "../../constants/sessionHelper";
import { message, notification } from "antd";
import { useEffect, useState } from "react";
import { getItemData } from "../../Hooks/useItemParams";
import Chat from "../../Pages/Chat";
import { connect, useSelector } from "react-redux";
import { createSample } from "../../ReduxStore/actions/sampleActions";

const AppHeader = (props) => {
    const location = useLocation();
    const history = useHistory();
    console.log(location);
    const [query, setquery] = useState('');
    useEffect(() => {
        window.genralPageLoad();
        // props.createSample(999);
        
        // createSample(99);
        // if (window.performance) {
        //     if (performance.navigation.type == 1) {            
        //     } else {
        //       window.genralPageLoad();
        //rea     }
        // }
    }, []);
    useEffect(()=>{
        // console.log("sample",props.sample);
    },[props.sample])
    // console.log('home page',props);

    return (
        <div>
            <div className="header app-header app-header-fixed">

                <a href="#" className="header-menutoggle" id="header-menutoggle">Menu</a>

                <div className="header-info">

                    <div className="header-personal">
                        <a href="#" className="header-gopersonal"></a>
                        <ul>
                            {/* <li>
                                <Link to="/chats">Chats <span></span></Link>
                            </li> */}
                            {/* <li>
                                <a href="#">Bookmarks <span>6</span></a>
                            </li>
                            <li>
                                <a href="cart.html">Shopping Cart <span>5</span></a>
                            </li>
                            <li className="header-order">
                                <a href="orders.html">Order Status</a>
                            </li> */}
                            <li>
                                <Link to="/profile">Perfil</Link>
                            </li>
                            {isLogin()&&<li>
                                <a onClick={() => {
                                    let res = logout();
                                    if (res) {
                                        notification.info({ message: "success", description: "Desconectado com sucesso" })
                                        history.replace('/');
                                    }

                                }} >Sair</a>
                            </li>}
                            {!isLogin()&&<li>
                                <Link to="login">Conecte-se</Link>
                            </li>}
                        </ul>
                    </div>



                    {/* <a href="#" className="header"></a> */}
                    {/* <a href="#" className="header-chat"></a>
                    <a href="#" className="header-chat"></a> */}
                    {/* <a href="#" onClick={()=>{window.location.reload()}} className="header-compare"></a> */}
                    {/* <a href="#" className="header-favorite" ></a> */}

                    {/* <!-- Search Form --> */}
                    {/* <a href="#" className="header-searchbtn" id="header-searchbtn"></a>
                    <form onSubmit={(e) => { 
                        e.preventDefault();
                        let itemForm = getItemData();
                        itemForm.searchterm =  query;
                        history.push({pathname:"products",itemData:itemForm});
                    }} className="header-search" id="header-search">
                        <input type="text" onChange={(e)=>{setquery(e.target.value)}} placeholder="Pesquisar peças ou veículos" />
                        <button type="submit"><i className="fa fa-search"></i></button>
                    </form> */}

                </div>

                {/* <!-- Logotype --> */}
                <p className="header-logo">
                    <a href="/"><img className="bg_logo" src="/external_assets/img/app_logo.png" alt="" style={{objectFit: 'contain'}} /></a>
                </p>

                {/* <!-- Navmenu - start --> */}
                <nav id="top-menu">
                    <ul>
                        <li className={location.pathname == "/" ? "active" : ""}>
                            <Link to="/">Pagina inicial</Link>
                        </li>
                        <li className={location.pathname == "/products" ? "active" : ""}>
                            <Link to="/products">Produtos</Link>
                        </li>
                        <li className={location.pathname == "/add_item" ? "active" : ""}>
                            <Link to="/add_item">Envie um anúncio</Link>
                        </li>
                        <li className={location.pathname == "/search_item" ? "active" : ""}>
                            <Link to="/search_item">Procurar</Link>
                        </li>
                        <li className={location.pathname == "/profile" ? "active" : ""}>
                            <Link to="/profile">Perfil</Link>
                        </li>
                        {/* <li className="has-child">
                            <a href="#">Pages</a>
                            <i className="fa fa-angle-down"></i>
                            <ul>
                                <li><a href="about.html">About Us</a></li>
                                <li><a href="contacts.html">Contacts</a></li>
                                <li><a href="gallery.html">Gallery</a></li>
                                <li><a href="err404.html">Error 404</a></li>
                            </ul>
                        </li> */}
                        {/* <li className="has-child">
                            <a href="blog.html">Blog</a>
                            <i className="fa fa-angle-down"></i>
                            <ul>
                                <li><a href="blog.html">Blog: Standart</a></li>
                                <li><a href="blog-full.html">Blog: Full Width</a></li>
                                <li><a href="blog-left.html">Blog: Left Sidebar</a></li>
                                <li><a href="post.html">Single Post: Standart</a></li>
                                <li><a href="post-map.html">Single Post: With a Map</a></li>
                                <li><a href="post-video.html">Single Post: Video</a></li>
                                <li><a href="post-slider.html">Single Post: Slider</a></li>
                                <li><a href="post-sidebar.html">Single Post: Sidebar</a></li>
                            </ul>
                        </li> */}
                        {/* <li>
                            <a href="contacts.html">Contacts</a>
                        </li> */}
                        {/* <li className="has-child">
                            <a href="orders.html">Orders</a>
                            <i className="fa fa-angle-down"></i>
                            <ul>
                                <li><a href="orders.html">Orders</a></li>
                                <li><a href="message.html">Messages</a></li>
                                <li><a href="cart.html">Shopping Cart</a></li>
                            </ul>
                        </li> */}
                    </ul>
                </nav>
                {/* <!-- Navmenu - end --> */}

            </div>

        </div>
    );
}
const mapActionDispatch = (dispatch) =>{
    return {
        createSample: (sample) => dispatch(createSample(sample))
    }
}
const mapStateToProps = (state) => {
    return {
        sample: state.sample,
    }
}
export default connect(mapStateToProps,mapActionDispatch)(AppHeader);