import React, { useEffect, useState } from 'react';
import { Input, Select, Tooltip, Button, message, Progress, Spin, Modal, notification, Radio } from 'antd';
import { Option } from 'antd/lib/mentions';
import { FaBorderAll, FaCalendar, FaMeteor, FaPeopleCarry, FaPlus, FaSave, FaUserAlt, FaMap, FaPhone, FaCross, FaCubes, FaInfo, FaBolt, FaTrash } from 'react-icons/fa';
import { MdEmail } from "react-icons/md";
import { itemFormData } from '../../Hooks/useItemParams';
import { getData, postData } from '../../api';
import { getCategoriesUrl, getAddItemBuildTypes, getAddItemColors, getAddItemConditions, getAddItemCurrencies, getAddItemFuelTypes, getAddItemItemTypes, getAddItemMenufecturer, getAddItemModel, getAddItemPriceTypes, getAddItemSellerTypes, getAddItemTransmissions, BASE_URL, addItemUrl, PREV_IMG, getLocationsUrl, sendEmail, getItemDetailUrl, getItemImages, deleteImages } from '../../constants/endPoints';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { antIcon, BOATS, boatTypes, CARS, carTypes, CRASHED_CARS, machinaryTypes, MACHINERY, NAUTICAL, OTHERS, PARTS, SERVICES, serviceAreas, _packages, _partsPackage, _rentPackage, _servicePackages, _noOfSeats, MOTOR_BIKES } from '../../constants';
import { chkBOPN, chkOPN, convertFromBase64, convertNumber } from '../../constants/helper';
// import MapPicker from 'react-google-map-picker';
// import PackageComponent from './PackageComponent';
import AndtLoader from '../Components/AndtLoader';
import { compose } from 'redux';
import { updateLocation } from '../../ReduxStore/actions/locationAction';
import { connect } from 'react-redux';
import KMInput from '../Components/KMInput';
import CurrencyInput from '../Components/CurrencyInput';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import AppLoader from '../Components/AppLoader';
import { Spinner } from 'react-bootstrap';
// import BankAccount from './BankAccounts';


const UpdateProduct = ({ ...props }) => {
    const formData = itemFormData();
    const [itemForm, setItemForm] = useState({ ...formData, category_id: CARS, is_driver: 'No' });
    const [images, setImages] = useState([/*{ src: "", file: {}, type: "file", image: {} }*/]);
    const [removeImages, setRemoveImages] = useState([]);

    const [percentage, setPercentage] = useState(0);
    const [selectedType, setSelectedType] = useState(CARS);
    const [categories, setCategories] = useState({ data: [], isLoading: false });

    // const [isInitMap, setInitMap] = useState(false);
    // Page Data
    const [menufecturers, setMenufecturers] = useState({ isLoading: false, data: [] });
    const [models, setModels] = useState({ isLoading: false, data: [] });
    const [colors, setColors] = useState([]);
    const [fueltypes, setFuleTypes] = useState([]);
    const [buildtypes, setBuildTypes] = useState([]);

    const [conditions, setConditions] = useState([]);

    const [transmissions, setTransmissions] = useState([]);

    const [packageAmounts, setPackageAmounts] = useState({ ..._packages });
    const history = useHistory();
    const [loadingText, setLoadingText] = useState('');
    const [isAddingImage, setIsAddingImage] = useState(false);

    const [fromYear, setFromYear] = useState([]);
    const params = useParams();
    const [error, setErros] = useState('');


    const setValue = (key, val) => {
        // console.log(key , val);
        setItemForm((prev) => {
            return {
                ...prev,
                [key]: val
            }
        }
        );
    }
    const addImage = () => {
        if (images.length > 4) {
            message.info({
                content: "cannot add more then 5 images"
            });
            return;
        }
        setImages(() => { return [...images, { src: "", file: {} }] })
    }
    const handleImageClick = (id) => {
        // console.log(id);
        document.getElementById(id).click();
    }


    const getLocation = async () => {
        try {
            props.updateLocation({ isLoading: true });
            const res2 = await getData(getLocationsUrl("get", 100, 0));
            if (!res2.data) {
                notification.error({
                    message: 'Error',
                    description: 'cannot get location data'
                });
                props.updateLocation({ isLoading: false });
                return;
            }
            props.updateLocation({ isLoading: false, data: res2.data });
        } catch (e) {
            console.log(e);
            props.updateLocation({ isLoading: false });
        }
    }
    const handleImageChange = (event, id, index) => {
        // console.log(event);
        // console.log(id);
        try {
            if (event.target.files.length > 0) {
                let file = event.target.files[0];
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    // console.log(reader.result);
                    const img = new Image();
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        const watermarkText = "INFOMOTORES";
                        canvas.width = img.width;
                        canvas.height = img.height;
                        ctx.drawImage(img, 0, 0);
                        ctx.font = `bold ${canvas.width / 10}px Rowdies`;
                        ctx.fillStyle = 'rgba(227, 227, 227, 0.58)';
                        const textWidth = ctx.measureText(watermarkText).width;
                        const pixalWidth = canvas.width - textWidth;
                        ctx.fillText(`${watermarkText}`, pixalWidth / 2, canvas.height / 2);
                        const watermarkImage = canvas.toDataURL('image/png');
                        canvas.toBlob((blob) => {
                            let randNumber = Math.floor(Math.random() * 100000000);
                            const watermarkFile = new File([blob], `${randNumber}.png`, {
                                type: 'image/png',
                                lastModified: Date.now(),
                            });
                            let image = { src: watermarkImage, file: watermarkFile, type: "file" }
                            // console.log(image)
                            let imgs = images;
                            imgs[index] = image;
                            setImages((prev) => [...imgs]);
                        })
                        // const watermarkFile = canvas.toBlob();
                    };
                    img.src = reader.result;
                    // let image = { src: reader.result, file: file, type: "file" }
                    // let imgs = images;
                    // imgs[index] = image;
                    // // console.log(images);
                    // setImages((prev) => [...imgs]);
                    // uploadImage(file);

                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
            } else {
                message.info({
                    content: "cannot select image"
                });
            }
        } catch (e) {
            message.info({
                content: "cannot select image"
            });
        }
    }
    // const handleSlipImageChange = (event) => {
    //     try {
    //         if (event.target.files.length > 0) {
    //             let file = event.target.files[0];
    //             var reader = new FileReader();
    //             reader.readAsDataURL(file);
    //             reader.onload = function () {
    //                 let image = { src: reader.result, file: file }
    //                 setSlipImages(image);
    //                 uploadSlipImage(image);
    //             };
    //             reader.onerror = function (error) {
    //                 console.log('Error: ', error);
    //             };
    //         } else {
    //             message.info({
    //                 content: "cannot select image"
    //             });
    //         }
    //     } catch (e) {
    //         message.info({
    //             content: "cannot select image"
    //         });
    //     }
    // }
    // const uploadSlipImage = async (image) => {
    //     let baseImage = image.src.split('base64,');
    //     if (baseImage.length < 2) {
    //         message.info('Cannot upload image');
    //         return;
    //     }
    //     let mimage = baseImage[1];
    //     var formData = new FormData();
    //     formData.append('image', mimage);

    //     let response = await postData("index.php/apis/uploadSlip", formData).catch(e => {
    //         document.getElementById('slip_image').value = null;
    //     });
    //     if (response) {
    //         message.success('Image Uploaded Successfully');
    //         setValue('slip_url', response.data.response.url)
    //         setSlipImages({ ...slipImage, src: BASE_URL + response.data.response.url });
    //     } else {
    //         setSlipImages({});
    //         message.info('Cannot upload image');
    //         document.getElementById('slip_image').value = null;
    //     }
    // }
    const submitForm = async () => {

        itemForm.category_id = selectedType;

        if (selectedType == SERVICES) {
            itemForm.manufacturer_id = "-2";
            if (!itemForm.title) {
                message.error({
                    content: "Company cannot be empty"
                });
                return;
            }
            if (!itemForm.highlight_info || itemForm.highlight_info.length < 1) {
                message.error({
                    content: "Highlight Information cannot be empty"
                });
                return;
            }

            if (!itemForm.description || itemForm.description.length < 1) {
                message.error({
                    content: "Description cannot be empty"
                });
                return;
            }

            if (!itemForm.service_area) {
                message.error({
                    content: "Please Select Service Area"
                });
                return;
            }

        } else {

            if (itemForm.sell_type.length < 1) {
                itemForm.sell_type = "Sell";
            }
            if (itemForm.manufacturer_id.length < 1) {
                message.error({
                    content: "Please enter valid manufacturer information"
                });
                return;
            }
            if (itemForm.sell_type.length < 1) {
                message.error({
                    content: "Please enter valid sell type For* information"
                });
                return;
            }
            if (convertNumber(itemForm.price) < 1) {
                message.error({
                    content: "Please enter valid price"
                });
                return;
            }

        }

        if (itemForm.item_location_id.length < 1) {
            message.error({
                content: "Invalid Location Please select Provencia"
            });
            return;
        }

        if (itemForm.phone_number.length < 6) {
            message.error({
                content: "Invalid Phone Number"
            });
            return;
        }

        if (images.length < 1) {
            message.error({
                content: "Please add atleast one image"
            });
            return;
        }

        // if (itemForm.package_detail.length < 1) {
        //     // changeSelectedPackage('basic');
        //     itemForm.package_detail = JSON.stringify({
        //         package_id: 'basic',
        //         amount: getPackageAmount('basic'),
        //         month: packageMonths
        //     });
        // }
        if (itemForm.package_detail.length < 1) {
            message.error({
                content: "Package Cannot be empty"
            });
            return;
        }
        try {
            setIsAddingImage(true);
            let response = await postData(addItemUrl(), itemForm, true);
            if (!response.data) {
                message.error({
                    content: "cannot submit your request try again"
                });
                setIsAddingImage(false);
                return;
            }
            let item = response.data;

            if (item) {
                let response = getData(sendEmail('new_item', item.id), true);

            }
            let response_images = postData(deleteImages(), { "images": JSON.stringify(removeImages) });
            for (var i = 0; i < images.length; i++) {
                if (images[i].file instanceof File) {
                    setLoadingText("Uploading Image " + (i + 1) + " ..");
                    let imgResponse = await uploadImage(images[i].file, item.id);
                }
            }
            setLoadingText("Adding Item ..");
            setIsAddingImage(false);
            history.push('/');
        } catch (e) {
            message.error('Cannot add item missing information');
            console.log(e);
            setIsAddingImage(false);
        }
    }
    const uploadImage = async (file, itemId) => {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("item_id", itemId);
        formData.append("img_id", "");
        let response = await postData("index.php/rest/images/upload_item/api_key/teampsisthebest", formData);
        return response;
    }




    const selectCategory = (id) => {

        setValue("category_id", id);
        // if (id == PARTS) {
        //     if (itemForm.sell_type == "Rent") {
        //         setPackageAmounts((state) => { return { ...state, ..._rentPackage } });
        //     } else {
        //         setPackageAmounts((state) => { return { ..._partsPackage } });
        //     }
        // } else if (id == SERVICES) {
        //     setPackageAmounts((state) => { return { ..._servicePackages } });
        // } else {
        //     if (itemForm.sell_type == "Rent") {
        //         setPackageAmounts((state) => { return { ..._rentPackage } });
        //     } else {
        //         setPackageAmounts((state) => { return { ..._packages } });
        //     }
        //     // setSelectedPackage('basic');
        // }
        setItemForm({ ...itemForm, sell_type: "Sell", item_type: '', category_id: id });
        setSelectedType(id);
        getMenufecturers(id);
    }

    const getCategories = async () => {
        try {
            setCategories({ isLoading: true });
            const res = await getData(getCategoriesUrl("get", 30, 0));
            setCategories({ data: res.data, isLoading: false });
        } catch (e) {
            setCategories({ data: [], isLoading: false });
            console.log(e);
        }
    }

    const getMenufecturers = async (id) => {
        try {
            if (id == CRASHED_CARS) {
                id = CARS;
            }
            // 1
            // setValue("manufacturer_id", '');
            setMenufecturers({ isLoading: true, data: [] });
            let res = await getData(getAddItemMenufecturer("get", 500, 0, id));
            if (res.data) {
                setMenufecturers({ isLoading: false, data: [...res.data] });
            }


        } catch (e) {
            setMenufecturers({ isLoading: false, data: [] });
            console.log(e);
        }
    }
    const updatePercentage = () => {
        setPercentage((prev) => prev + 20.5);
    }
    const getPageData = async () => {
        setPercentage(0);
        try {
            let res = await getData(getAddItemBuildTypes("get", 500, 0));
            if (res.data) {
                setBuildTypes(res.data);
                updatePercentage();
            }
        } catch (e) {
            console.log(e);
        }
        try {
            // 3
            let res = await getData(getAddItemColors("get", 500, 0));
            if (res.data) {
                setColors(res.data);
                updatePercentage();
            }
        } catch (e) {
            console.log(e);
        }
        try {
            // 4
            let res = await getData(getAddItemConditions("get", 500, 0));
            if (res.data) {
                setConditions(res.data);
                updatePercentage();
            }
        } catch (e) {
            console.log(e);
        }
        try {
            // 6
            let res = await getData(getAddItemFuelTypes("get", 500, 0));
            if (res.data) {
                setFuleTypes(res.data);
                updatePercentage();
            }
        } catch (e) {
            console.log(e);
        }
        try {
            // 10
            let res = await getData(getAddItemTransmissions("get", 500, 0));
            if (res.data) {
                setTransmissions(res.data);
                updatePercentage();
            }
        } catch (e) {
            console.log(e);
        }

        console.log("params", params)
        if (params.item_id) {
            getItem(convertFromBase64(params.item_id));
        }

    }
    const getItem = async (productId) => {
        let res = await getData(getItemDetailUrl("get", 10, 0, productId));
        console.log("data", res.data);
        if (res.data) {
            setItemForm({ ...itemForm, ...res.data });
            setSelectedType(res.data.category_id);
            let imagesRes = await getData(getItemImages(productId));
            setImages([]);
            if (imagesRes.data) {
                console.log(imagesRes.data);
                imagesRes.data.map(e => {
                    // setImages([...images, { src: `${PREV_IMG + e.img_path}`, file: {}, type: "url", image: e }]);
                    images.push({ src: `${PREV_IMG + e.img_path}`, file: {}, type: "url", image: e });
                })
                setImages([...images]);
            }
        }
    }
    const getModels = async (id) => {
        if (!id) {
            return;
        }
        if (id.toString().length < 1) {
            return;
        }
        setModels({ isLoading: true, data: [] });
        try {
            // 10
            let res = await getData(getAddItemModel("get", 500, 0, id));
            if (res.data) {
                setModels({ isLoading: false, data: [...res.data] });
                // updatePercentage();
            }
        } catch (e) {
            setModels({ isLoading: false, data: [] });
            console.log(e);
        }
    }


    /* const getPackageAmount = (type) => {
         try {
             var formatter = new Intl.NumberFormat('en-US', {
                 style: 'currency',
                 currency: 'USD',
                 maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
             });
             if (type == 'basic') { }
             let amount = Number(packageAmounts[type]) * packageMonths;
             return formatter.format(amount).toString().replace('$', '');
         } catch (e) {
             console.log(e);
             return 'Pending';
         }
     }*/
    const getYears = () => {
        let year = new Date().getFullYear();
        let years = []
        for (var i = 1990; i <= year; i++) {
            years.push(i);
        }
        setFromYear([...years]);
        setTimeout(() => {
            setValue("year", year);
        }, 1000)
    }

    useEffect(() => {
        console.log(itemForm.manufacturer_id);
        getModels(itemForm.manufacturer_id);
    }, [itemForm.manufacturer_id])
    useEffect(() => {
        getMenufecturers(itemForm.category_id);
    }, [itemForm.category_id])
    useEffect(() => {
        getPageData();
        getCategories();
        // getMenufecturers(selectedType);
        getLocation();
        getYears();
        setTimeout(() => {
            // setInitMap(true);
        }, 3000)
        // window.genralPageLoad();
    }, []);
    return (
        <div className="add-product">
            <main>
                <Modal visible={isAddingImage} footer={null} width={200} closable={false} okButtonProps={{ style: { display: 'none' } }} cancelButtonProps={{ style: { display: 'none' } }}>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <Spinner className="mb-1" size={30} animation="border" />
                        <label>{loadingText}</label>
                    </div>
                </Modal>
                {/* <!-- Breadcrumbs --> */}
                <div className="b-crumbs-wrap">
                    <div className="cont b-crumbs">
                        <ul>
                            <li>
                                <Link to="/">Motor</Link>
                            </li>
                            <li>
                                <span>Inserir um anúncio</span>
                            </li>
                        </ul>
                    </div>
                </div>

                {itemForm.id ? <div className="cont maincont">

                    <h1><span>Anúncio</span></h1>
                    {/* <span className="maincont-line1 maincont-line12"></span>
                    <span className="maincont-line2 maincont-line22"></span> */}

                    {/* <!-- Contacts - start --> */}
                    <div className="card p-4">

                        <div>
                            <h3 className='heading-font'>Inserir um anúncio</h3>
                            {percentage < 100 && <Progress percent={percentage} />}

                            {<form action="#" className="row">
                                <div>
                                    <div className="col-12 d-flex align-items-center category-img-container">
                                        {categories.isLoading ? <Spin indicator={antIcon} /> : categories.data.map((val, i) => {
                                            return selectedType != val.category_id ? <span></span> : <div className="d-flex  flex-column justify-content-center align-items-center " onClick={() => { selectCategory(val.category_id) }} style={selectedType == val.category_id ? { background: "#ffb6a5", paddingTop: 10, borderRadius: 10 } : { color: "#212121" }}>
                                                <div className="frontcategs-img">
                                                    <img src={BASE_URL + val.category_image} style={selectedType == val.category_id ? { filter: "grayscale(100%)" } : { filter: "grayscale(0%)" }} alt="" onError={(e) => { e.target.src = "./assets/images/placeholder.jpeg" }} />
                                                </div>
                                                <div className="text-center">
                                                    <label className='heading-font'>{val.category_name}</label>
                                                </div>

                                            </div>
                                        })}


                                    </div>
                                </div>
                                <div className="col-12">
                                    <h6 style={{ color: "#d70304" }}>Nota: Por favor, selecione a categoria primeiro. E selecione os campos relacionados ao seu produto</h6>
                                </div>

                                <div className='col-12 row'>

                                    <div className='col-6'>
                                        {selectedType != NAUTICAL && selectedType != PARTS && selectedType != CRASHED_CARS && selectedType != SERVICES && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Para*</label>
                                            <div className="col-12">
                                                <Select
                                                    showSearch
                                                    placeholder="alugar/venda"
                                                    optionFilterProp="children"
                                                    style={{ width: "100%" }}
                                                    defaultValue="Sell"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                    value={itemForm.sell_type}
                                                    onChange={(e) => {
                                                        // Update Package
                                                        if (e == "Rent") {

                                                            // setPackageAmounts((state) => { return { ..._rentPackage } });
                                                            // setSelectedPackage('basic');
                                                        } else {
                                                            if (selectedType == PARTS) {
                                                                // setPackageAmounts((state) => { return { ..._partsPackage } });
                                                                // setSelectedPackage('basic');
                                                            } else {
                                                                // setPackageAmounts((state) => {
                                                                //     return {
                                                                //         ..._packages
                                                                //     }
                                                                // });
                                                            }

                                                        }
                                                        setValue("sell_type", e);
                                                    }}
                                                >
                                                    <Option value="Rent" disabled={itemForm.category_id == PARTS}>Alugar</Option>
                                                    <Option value="Sell">Venda</Option>
                                                </Select>
                                            </div>
                                        </div>}
                                        {selectedType == MACHINERY && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Tipo de máquinas*</label>

                                            {<div className="col-12">
                                                <Select
                                                    showSearch
                                                    placeholder="Selecione o tipo de item"
                                                    optionFilterProp="children"
                                                    style={{ width: "100%" }}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                    value={itemForm.item_type}
                                                    onChange={(e) => {
                                                        setValue("item_type", e);
                                                    }}
                                                >
                                                    <Option value="">Não Identificado</Option>
                                                    {/* <Option value="Other">Other</Option> */}
                                                    {
                                                        machinaryTypes.map((e, i) => {
                                                            return <Option value={e.key} key={"item_type___" + i}>{e.value}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                            }
                                        </div>
                                        }
                                        {/* ITEM INFORMATION */}
                                        {selectedType != SERVICES && (selectedType == NAUTICAL || selectedType == OTHERS || selectedType == PARTS) &&
                                            <div className="form-group col-12">
                                                <label className="mb-1 mt-1 heading">Título do item</label>
                                                <Input size="medium" placeholder="Nome" prefix={<FaBolt />} value={itemForm.title} onChange={(e) => { setValue('title', e.target.value) }} />
                                            </div>
                                        }

                                        {selectedType != SERVICES &&
                                            <div className="form-group col-12">
                                                <label className="mb-1 mt-1 heading">{selectedType == PARTS ? "Categoria" : "Marca"}</label>
                                                {itemForm.manufacturer_id == '-1' && <div className='col-12'>
                                                    <Input size="medium" placeholder="Escrever"
                                                        prefix={<FaCubes />}
                                                        suffix={<i className='fa fa-close' style={{ color: '#b50100', fontSize: 20 }} onClick={() => {
                                                            setValue('manufacturer_id', '');
                                                            setValue('manufacturer_name', '');
                                                        }}></i>}
                                                        value={itemForm.manufacturer_name}
                                                        onChange={(e) => { setValue('manufacturer_name', e.target.value) }} />
                                                </div>}
                                                {itemForm.manufacturer_id != '-1' && <div className="col-12">
                                                    {menufecturers.isLoading ?
                                                        <div style={{ textAlign: 'center', width: "100%" }}>
                                                            <AndtLoader />
                                                        </div>
                                                        : <Select
                                                            showSearch
                                                            size="middle"
                                                            placeholder={selectedType == PARTS ? "Selecione o Categoria"
                                                                : "Selecione o Marca"}
                                                            optionFilterProp="children"
                                                            style={{ width: "100%" }}
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                            value={itemForm.manufacturer_id}
                                                            onChange={(e) => {
                                                                setValue("manufacturer_id", e);
                                                                getModels(e);
                                                            }}
                                                        >

                                                            <Option value="">Não identificado</Option>
                                                            <Option value="-1">Outros</Option>
                                                            {
                                                                menufecturers.data.map((val, i) => {
                                                                    return <Option value={val.id} key={"manufec" + i}>{val.name}</Option>
                                                                })
                                                            }
                                                        </Select>}
                                                </div>}
                                            </div>}
                                        {/* MODEL */}
                                        {selectedType != SERVICES && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">{selectedType == PARTS ? "Sub Categoria"
                                                : "Modelo"}</label>
                                            {(itemForm.model_id == '-1') && <div className='col-12'>
                                                <Input size="medium" placeholder="Escrever"
                                                    prefix={<FaCubes />}
                                                    suffix={<i className='fa fa-close' style={{ color: '#b50100', fontSize: 20 }} onClick={() => {
                                                        setValue('model_id', '');
                                                        setValue('model_name', '');
                                                    }}></i>}
                                                    value={itemForm.model_name}
                                                    onChange={(e) => { setValue('model_name', e.target.value) }} />
                                            </div>}
                                            {itemForm.model_id != '-1' && <div className="col-12">
                                                {models.isLoading ?
                                                    <div style={{ textAlign: 'center', width: "100%" }}>
                                                        <AndtLoader />
                                                    </div>
                                                    : <Select
                                                        showSearch
                                                        placeholder={selectedType == PARTS ? "Selecionar Sub Categoria"
                                                            : "Selecionar modelo"}
                                                        optionFilterProp="children"
                                                        style={{ width: "100%" }}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                        value={itemForm.model_id}
                                                        onChange={(e) => {
                                                            setValue("model_id", e);
                                                        }}
                                                    >
                                                        <Option value="">Não Identificado</Option>
                                                        <Option value="-1">Outros</Option>
                                                        {
                                                            models.data.map((val, i) => {
                                                                return <Option value={val.id} key={"model" + i}>{val.name}</Option>
                                                            })
                                                        }
                                                    </Select>}
                                            </div>}
                                        </div>}
                                        {selectedType == PARTS && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Tipo de veículo</label>
                                            <div className="col-12">
                                                <Select
                                                    showSearch
                                                    placeholder="Tipo de veículo"
                                                    optionFilterProp="children"
                                                    style={{ width: "100%" }}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                    value={itemForm.build_type_id}
                                                    onChange={(e) => {
                                                        setValue("build_type_id", e);
                                                    }}
                                                >
                                                    <Option value="">Não Identificado</Option>
                                                    {
                                                        buildtypes.map((val, i) => {
                                                            return <Option value={val.id} key={"model" + i}>{val.car_type}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </div>}
                                        {/* CAR Type */}
                                        {selectedType == CARS && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Tipo de carro*</label>
                                            <div className="col-12">
                                                <Select
                                                    showSearch
                                                    placeholder="Selecione o tipo de item"
                                                    optionFilterProp="children"
                                                    style={{ width: "100%" }}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                    value={itemForm.item_type}
                                                    onChange={(e) => {
                                                        setValue("item_type", e);
                                                    }}
                                                >
                                                    <Option value="">Não Identificado</Option>
                                                    {
                                                        carTypes.map((e, i) => {
                                                            return <Option value={e.key} key={"item_type__" + i}>{e.value}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                        }

                                        {/* NAUTICAL TYPEs */}
                                        {selectedType == BOATS && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Tipo de barco*</label>
                                            <div className="col-12">
                                                <Select
                                                    showSearch
                                                    placeholder="Selecione o tipo de item"
                                                    optionFilterProp="children"
                                                    style={{ width: "100%" }}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                    value={itemForm.item_type}
                                                    onChange={(e) => {
                                                        setValue("item_type", e);
                                                    }}
                                                >
                                                    <Option value="">Não Identificado</Option>
                                                    {
                                                        boatTypes.map((e, i) => {
                                                            return <Option value={e.key} key={"item_type_car_" + i}>{e.value}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                        }
                                       
                                        {/* END TYPES  */}
                                        {/* No Of Seats */}
                                        {(selectedType == CARS && itemForm.sell_type == 'Rent') && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Lugares</label>
                                            <div className="col-12">
                                                <Select
                                                    showSearch
                                                    placeholder="Selecione Lugares"
                                                    optionFilterProp="children"
                                                    style={{ width: "100%" }}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                    value={itemForm.max_passengers}
                                                    onChange={(e) => {
                                                        setValue("max_passengers", e);
                                                    }}
                                                >
                                                    <Option value=''>Selecione Lugares</Option>
                                                    {
                                                        _noOfSeats.map((e, key) => {
                                                            return (<Option value={e.key}>{e.value}</Option>);
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                        }
                                        {/* End No Of Seats */}
                                        {/* IS DRIVER */}
                                        {(selectedType == CARS && itemForm.sell_type == 'Rent') && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Motorista</label>
                                            <div className="col-12">
                                                <Select
                                                    showSearch
                                                    placeholder="Selecione Motorista"
                                                    optionFilterProp="children"
                                                    style={{ width: "100%" }}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                    value={itemForm.is_driver}
                                                    onChange={(e) => {
                                                        setValue("is_driver", e);
                                                    }}
                                                >
                                                    <Option value="Yes">Sim</Option>
                                                    <Option value="No">Não</Option>
                                                </Select>
                                            </div>
                                        </div>
                                        }
                                        {/* IS DRIVER END */}

                                        {/* Year */}
                                        {selectedType != SERVICES &&
                                            itemForm.sell_type != 'Rent' && <div className="form-group col-12">
                                                <label className="mb-1 mt-1 heading">Ano</label>
                                                <Select
                                                    showSearch
                                                    // size="middle"
                                                    placeholder="Ano"
                                                    optionFilterProp="children"
                                                    style={{ width: "100%" }}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                    value={itemForm.year}

                                                    onChange={(e) => {
                                                        // if (e) setValue('year', e + 1);
                                                        // else setValue('max_year', '');
                                                        // setValue('max_year', e+1);
                                                        setValue('year', e);
                                                        //getFilterCount();
                                                    }}
                                                >
                                                    <Option value="">Ano mínimo</Option>
                                                    {
                                                        [...fromYear].map((val, i) => {
                                                            return <Option value={val} key={"Year" + i}>
                                                                {val}
                                                            </Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>}
                                        {/*  */}
                                        {selectedType == SERVICES && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Nome da Empressa</label>
                                            <Input type={'text'} size="medium" placeholder="Nome da Empressa" value={itemForm.title} onChange={(e) => { setValue('title', e.target.value) }} />
                                        </div>}

                                        {selectedType == SERVICES && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Pequena descrição do Serviço</label>
                                            <Input type={'text'} size="medium" placeholder="Pequena descrição do Serviço" value={itemForm.description} onChange={(e) => { setValue('description', e.target.value) }} />
                                        </div>}

                                        {selectedType == SERVICES && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Área de serviço</label>
                                            {<div className="col-12">
                                                {false ?
                                                    <div style={{ textAlign: 'center', width: "100%" }}>
                                                        <AndtLoader />
                                                    </div>
                                                    : <Select
                                                        showSearch
                                                        size="middle"
                                                        placeholder={"Selecione a área de serviço"}
                                                        optionFilterProp="children"
                                                        style={{ width: "100%" }}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                        value={itemForm.service_area}
                                                        onChange={(e) => {
                                                            setValue("service_area", e);
                                                        }}
                                                    >
                                                        {
                                                            serviceAreas.map((val) => {
                                                                return <Option value={val.key}>{val.value}</Option>
                                                            })
                                                        }
                                                    </Select>}
                                            </div>}
                                        </div>}


                                        {/* Kilo Meter */}
                                        {(!chkOPN(selectedType) && selectedType != BOATS && itemForm.sell_type != 'Rent'
                                        && itemForm.item_type != "Generator" ) && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Quilômetros</label>
                                            <KMInput style={{ width: "100%" }} className='p-1' placeholder="Km"
                                                value={itemForm.mileage} onChange={(e) => {
                                                    var val = e.target.value.replace(/KM/g, '').replace(/,/g, "");
                                                    setValue('mileage', val)
                                                }} />
                                            {/* <Input size="medium" placeholder="Km" value={itemForm.mileage} onChange={(e) => { setValue('mileage', e.target.value) }} /> */}
                                        </div>}
                                        {
                                            (selectedType == BOATS || itemForm.item_type == "Generator") && <div className="form-group col-12">
                                                <label className="mb-1 mt-1 heading">Horas</label>
                                                <Input type='number' size="medium" placeholder="Horas" prefix={<FaBorderAll />} value={itemForm.hours} onChange={(e) => { setValue('hours', e.target.value) }} />
                                            </div>
                                        }
                                        {/* COLOR */}
                                        {(!chkOPN(selectedType) && selectedType != MACHINERY && itemForm.sell_type != "Rent") && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Cor</label>
                                            <div className="col-12">
                                                <Select
                                                    showSearch
                                                    placeholder="Selecione a cor"
                                                    optionFilterProp="children"
                                                    style={{ width: "100%" }}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                    value={itemForm.color_id}
                                                    onChange={(e) => {
                                                        setValue("color_id", e);
                                                    }}
                                                >
                                                    <Option value="">Não identificado</Option>
                                                    {
                                                        colors.map((val, i) => {
                                                            return <Option value={val.id} key={"color" + i}>{val.color_value}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </div>}
                                        {/* Fuel Type */}
                                        {!chkOPN(selectedType) && itemForm.sell_type != 'Rent' && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Tipo de combustível</label>
                                            <div className="col-12">
                                                <Select
                                                    showSearch
                                                    placeholder="Selecione o tipo de combustível"
                                                    optionFilterProp="children"
                                                    style={{ width: "100%" }}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                    value={itemForm.fuel_type_id}
                                                    onChange={(e) => {
                                                        setValue("fuel_type_id", e);
                                                    }}
                                                >
                                                    <Option value="">Não identificado</Option>
                                                    {
                                                        fueltypes.map((val, i) => {
                                                            return <Option value={val.id} key={"fueltype" + i}>{val.fuel_name}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </div>}
                                        {/*  */}
                                        {/* Tramsmission */}
                                        {!chkOPN(selectedType) && selectedType != BOATS && selectedType != MOTOR_BIKES && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Caixa</label>
                                            {/* Select */}
                                            <div className="col-12">
                                                <Select
                                                    showSearch
                                                    placeholder="Selecionar Caixa"
                                                    optionFilterProp="children"
                                                    style={{ width: "100%" }}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                    value={itemForm.transmission_id}
                                                    onChange={(e) => {
                                                        setValue("transmission_id", e);
                                                    }}
                                                >
                                                    <Option value="">Não identificado</Option>
                                                    {
                                                        transmissions.map((val, i) => {
                                                            return <Option value={val.id} key={"transmission" + i}>{val.name}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </div>}
                                        {/* Licence Plate */}
                                        {(!chkBOPN(selectedType) && itemForm.sell_type != 'Rent') && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Matricula</label>
                                            <Input size="medium" placeholder="Número da placa" prefix={<FaBorderAll />} value={itemForm.plate_number} onChange={(e) => { setValue('plate_number', e.target.value) }} />
                                        </div>}

                                        {/* Price */}
                                        {selectedType != SERVICES && <div className="form-group col-12">
                                            {/* <div className="col-12 row"> */}
                                            <label className="mb-1 mt-1 heading">Preço {itemForm.sell_type == 'Rent' ? "(por dia)" : ""}</label>
                                            {/* <div className="col-4">
                                                <div className="col-12">
                                                    <Select
                                                        showSearch
                                                        placeholder="eg: Kz"
                                                        optionFilterProp="children"
                                                        style={{ width: "100%" }}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                        value={itemForm.item_currency_id}
                                                        onChange={(e) => {
                                                            setValue("item_currency_id", e);
                                                        }}
                                                    >
                                                        <Option value="">Not Set</Option>
                                                        {
                                                            currencies.map((val, i) => {
                                                                return <Option value={val.id} key={"currencies" + i}>{val.currency_symbol}</Option>
                                                            })
                                                        }
                                                    </Select>
                                                </div>
                                            </div> */}
                                            <div className="col-12">
                                                {/* <Input size="medium" placeholder="Price" value={itemForm.price} onChange={(e) => { setValue('price', e.target.value) }} /> */}
                                                <CurrencyInput style={{ width: "100%" }} className='p-1'
                                                    placeholder="Price" value={itemForm.price}
                                                    onChange={(e) => {
                                                        var val = e.target.value.replace(/Kz/g, '').replace(/,/g, "");
                                                        setValue('price', val)
                                                    }} />
                                            </div>
                                            <div className="form-group col-12 pt-2">
                                                <label className="search-label">Negociável</label>
                                                <div className='pt-1'>
                                                    <Radio.Group onChange={(e) => {
                                                        console.log(e);
                                                        setValue("negotiable", e.target.value);
                                                    }} value={convertNumber(itemForm.negotiable.toString())}
                                                    >
                                                        <Radio value={0}>Não</Radio>
                                                        <Radio value={1}>Sim</Radio>
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                            {/* </div> */}

                                        </div>}
                                    </div>
                                    <div className='col-6'>
                                        {!chkOPN(selectedType) && (itemForm.category_id == CARS &&
                                            itemForm.sell_type != 'Rent') && <div className="form-group col-12">
                                                <label className="mb-1 mt-1 heading">Poder do motor</label>
                                                <Input size="medium" placeholder="Poder do motor" prefix={<FaMeteor />} value={itemForm.engine_power} onChange={(e) => { setValue('engine_power', e.target.value) }} />
                                            </div>}
                                        {/* Condition */}
                                        {selectedType != SERVICES && itemForm.sell_type != 'Rent' && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Estado</label>
                                            <div className="col-12">
                                                <Select
                                                    showSearch
                                                    placeholder="Selecionar Estado"
                                                    optionFilterProp="children"
                                                    style={{ width: "100%" }}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                    value={itemForm.condition_of_item_id}
                                                    onChange={(e) => {
                                                        setValue("condition_of_item_id", e);
                                                    }}
                                                >
                                                    <Option value="">Não identificado</Option>
                                                    {
                                                        conditions.map((val, i) => {
                                                            return <Option value={val.id} key={"conditions" + i}>{val.name}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </div>}

                                        <div className="form-group col-12 pt-2">
                                            {/* <label className="mb-1 mt-1 heading">Manufecturer</label> */}
                                            <div className="col-12">
                                                <label className="search-label">Provincia</label>
                                                <Select
                                                    showSearch
                                                    // size="middle"
                                                    placeholder="Selecionar Província"
                                                    optionFilterProp="children"
                                                    style={{ width: "100%" }}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                    value={itemForm.item_location_id}

                                                    onChange={(e) => {
                                                        setValue('item_location_id', e);
                                                        //getFilterCount();
                                                    }}
                                                >
                                                    <Option value="">Selecionar Província</Option>
                                                    {

                                                        props.location.data && props.location.data.map((val, i) => {
                                                            return <Option value={val.id} key={"item_location_" + i}>
                                                                {val.name}
                                                            </Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                        {<div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Número de telefone</label>
                                            <Input type={'tel'} size="medium" placeholder="Número de telefone" prefix={<FaPhone />} value={itemForm.phone_number} onChange={(e) => { setValue('phone_number', e.target.value) }} />
                                        </div>}
                                        {<div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Segundo número de telefone (opcional)</label>
                                            <Input type={'tel'} size="medium" placeholder="Número de telefone" prefix={<FaPhone />} value={itemForm.second_phone_number} onChange={(e) => { setValue('second_phone_number', e.target.value) }} />
                                        </div>}
                                        {<div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Email</label>
                                            <Input type={'tel'} size="medium" placeholder="Endereço de email" prefix={<MdEmail />} value={itemForm.email} onChange={(e) => { setValue('email', e.target.value) }} />
                                        </div>}


                                        <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Informações de destaque</label>
                                            <textarea className="form-control" size="medium" placeholder="Informações de destaque" value={itemForm.highlight_info} onChange={(e) => { setValue('highlight_info', e.target.value) }} ></textarea>
                                        </div>
                                        {selectedType != SERVICES && <div className="form-group col-12">
                                            <label className="mb-1 mt-1 heading">Descrição</label>
                                            <textarea className="form-control" size="medium" placeholder="Descrição" value={itemForm.description} onChange={(e) => { setValue('description', e.target.value) }} ></textarea>
                                        </div>
                                            &&
                                            <div className="form-group col-12">
                                                <label className="mb-1 mt-1 heading">Endereço</label>
                                                <textarea className="form-control" size="medium" placeholder="Endereço" value={itemForm.address} onChange={(e) => { setValue('address', e.target.value) }} ></textarea>
                                            </div>}
                                    </div>
                                    {/* Engin Power */}
                                </div>
                                <div>
                                    <h4 className='heading-font mb-0 pt-1'>Fotografias</h4>
                                    <h6>A última fotografia adicionada  será a fotografia na capa do anúncio</h6>
                                    <div className="add-img-container d-flex">
                                        {
                                            images && images.map((val, i) => {
                                                return <div className="add-img" onClick={() => {

                                                }}>
                                                    <input type="file" id={"add_product_img_" + i}
                                                        style={{ display: "none" }} onChange={(e) => {
                                                            handleImageChange(e, 'add_product_img_' + i, i);
                                                        }}

                                                    />
                                                    <div style={{ position: "relative" }}>
                                                        {<div className='img-del-btn' onClick={() => {
                                                            if (images[i])
                                                                if (images[i].type == 'url')
                                                                    setRemoveImages(images[i].image.img_id)
                                                            images.splice(i, 1);
                                                            setImages([...images]);
                                                        }}><FaTrash /></div>}
                                                        <img src={val.src}
                                                            onError={(e) => { e.target.src = "/assets/images/placeholder.jpeg" }}
                                                            className="img"
                                                            onClick={() => {
                                                                if (images[i].type == 'url') {
                                                                    message.warning("delete and add new");
                                                                    return;
                                                                }
                                                                handleImageClick("add_product_img_" + i)
                                                            }} />
                                                    </div>
                                                </div>
                                            })
                                        }

                                        <div>
                                            <Button
                                                // type="dashed" 
                                                type="primary" size="large" shape="circle" icon={<FaPlus />} onClick={() => { addImage() }} />
                                        </div>
                                    </div>
                                </div>

                                {/* PACKAGE START */}
                                {/* <PackageComponent
                                    packageMonths={packageMonths}
                                    packageAmounts={packageAmounts}
                                    getPackageAmount={getPackageAmount}
                                    selectedType={selectedType}
                                    changeSelectedPackage={changeSelectedPackage}
                                    selectedPackage={selectedPackage}
                                    setPackageMonths={setPackageMonths}
                                    itemForm={itemForm}
                                // packageAmounts={packageAmounts}
                                /> */}
                                {/* PACKAGE END */}
                                {/*  Bank Detail */}
                                {/* <BankAccount /> */}

                                {/* {<div className='add-img-container'>
                                    <h4 className='heading-font'>Por favor, anexe a imagem do boleto bancário</h4>
                                    <div className='add-img'>
                                        <input type="file" id="slip_image"
                                            style={{ display: "none" }} onChange={(e) => { handleSlipImageChange(e); }} />

                                        <img src={slipImage.src + ""}
                                            onError={(event) => { console.log("src error", event); event.target.src = "./assets/images/placeholder.jpeg" }}
                                            className="img"
                                            width={200}
                                            onClick={() => { handleImageClick('slip_image') }} />
                                    </div>
                                </div>} */}
                                <div className="form-group col-12 mt-4">
                                    <Tooltip title="Submit" >
                                        <Button
                                            // type="dashed"
                                            onClick={() => { submitForm() }} type="primary" size="large" shape="round" icon={<FaSave />}>Salvar</Button>
                                    </Tooltip>
                                </div>

                            </form>}

                        </div>
                    </div>
                    {/* <!-- Contacts - end --> */}

                </div> : <AppLoader />}


                {/* <!-- Map --> */}
                {/* <div className="contacts-map" id="contacts-map"></div> */}

            </main>
        </div>
    );
}

const mapLocationWithProps = (state) => {
    return {
        location: state.location
    }
}
const mapLocationActionWithProps = (dispatch) => {
    return {
        updateLocation: (_data) => dispatch(updateLocation(_data)),
    }
}

export default compose(
    connect(mapLocationWithProps, mapLocationActionWithProps),
)(UpdateProduct);
