const initalState = [1,2,3,4,5];
const sampleReducer = (state = initalState, action) =>{
    switch (action.type) {
        case 'ADD_SAMPLE':
            // console.log("Create Sample ", action);
            return [...state, action.sample];
            // break;
    
        default:
            return state;
    }
   
}
export default sampleReducer;