import React, { useEffect, useState } from 'react';
import { Input, Select, Tooltip, Button, message, Progress, Spin, Modal } from 'antd';
import { Option } from 'antd/lib/mentions';
import { FaBorderAll, FaCalendar, FaMeteor, FaPeopleCarry, FaPlus, FaSave, FaSearch, FaUserAlt } from 'react-icons/fa';
import { getItemData, itemFormData } from '../Hooks/useItemParams';
import { getData, postData } from '../api';
import { getCategoriesUrl, getAddItemBuildTypes, getAddItemColors, getAddItemConditions, getAddItemCurrencies, getAddItemFuelTypes, getAddItemItemTypes, getAddItemMenufecturer, getAddItemModel, getAddItemPriceTypes, getAddItemSellerTypes, getAddItemTransmissions, BASE_URL, addItemUrl, getLocationsUrl} from '../constants/endPoints';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { antIcon, BOATS, CARS, CRASHED_CARS, NAUTICAL, SERVICES, serviceAreas } from '../constants';
import { chkBOPN, chkOPN, convertToBase64 } from '../constants/helper';
import { useHistory, useParams } from "react-router";
import { compose } from 'redux';
import { updateLocation } from '../ReduxStore/actions/locationAction';
import { connect } from 'react-redux';

const SearchProducts = ({...props}) => {
    
    const formData = getItemData();
    const [itemForm, setItemForm] = useState({...formData, category_id: 1});
    const [images, setImages] = useState([{ src: "", file: {} }]);
    const [percentage, setPercentage] = useState(0);
    const [selectedType, setSelectedType] = useState("1");
    const [categories, setCategories] = useState({ data: [], isLoading: false });
    const [isAddingImage, setIsAddingImage] = useState(false);
    const [loadingText, setLoadingText] = useState("Adding Item");

    // Page Data
    const [menufecturers, setMenufecturers] = useState([]);
    const [models, setModels] = useState([]);
    const [colors, setColors] = useState([]);
    const [fueltypes, setFuleTypes] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [transmissions, setTransmissions] = useState([]);
    // Page Data end
    const history = useHistory();
    const {data} = useParams();
    console.log("MJ: params", data);
    console.log("MJ: params", history);
    


    const setValue = (key, val) => {
        console.log(key, val);
        setItemForm(
            {
                ...itemForm,
                [key]: val
            }
        );
    }
   

    const getLocation = async () => {
        try {
            props.updateLocation({ isLoading: true });
            const res2 = await getData(getLocationsUrl("get", 100, 0));
            if (!res2.data) {
                props.updateLocation({ isLoading: false });
                return;
            }
            props.updateLocation({ isLoading: false, data: res2.data });
        console.log("Props location data", props);

        } catch (e) {
            console.log(e);
            props.updateLocation({ isLoading: false });
        }
    }

    const submitForm = async () => {
        
        // history.push({pathname:"products",itemData:itemForm});
        history.push({ pathname: "/products/"+convertToBase64(JSON.stringify(itemForm)) });
      
    }
    const uploadImage = async (file, itemId) => {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("item_id", itemId);
        formData.append("img_id", "");
        let response = await postData("index.php/rest/images/upload_item/api_key/teampsisthebest", formData);
        return response;
    }

    const selectCategory = (id) => {
        setSelectedType(id);
        setValue("category_id", id);
        getMenufecturers(id);
    }
    const updatePercentage = () => {
        console.log(percentage);
        setPercentage((prev) => prev + 30);
    }
    const getCategories = async () => {
        try {
            setCategories({ isLoading: true });
            const res = await getData(getCategoriesUrl("get", 30, 0));
            setCategories({ data: res.data, isLoading: false });
        } catch (e) {
            setCategories({ data: [], isLoading: false });
            console.log(e);
        }
    }

   
    const getMenufecturers = async (id) => {
        try {
            if(id == CRASHED_CARS){
                id = CARS;
            }
            // 1
            setMenufecturers([]);
            let res = await getData(getAddItemMenufecturer("get", 500, 0, id));
            if (res.data) {
                setMenufecturers([...res.data]);
            }


        } catch (e) {
            console.log(e);
        }
    }
    const getPageData = async () => {
        setSelectedType(data);
        setPercentage(0);
        try {
            // 3
            let res = await getData(getAddItemColors("get", 500, 0));
            console.log("color", res.data);
            if (res.data) {
                setColors(res.data);
                updatePercentage();
            }
        } catch (e) {
            console.log(e);
        }
        try {
            // 4
            let res = await getData(getAddItemConditions("get", 500, 0));
            console.log("condition", res.data);
            if (res.data) {
                setConditions(res.data);
                updatePercentage();
            }
        } catch (e) {
            console.log(e);
        }
        try {
            // 6
            let res = await getData(getAddItemFuelTypes("get", 500, 0));
            console.log("fuel", res.data);
            if (res.data) {
                setFuleTypes(res.data);
                updatePercentage();
            }
        } catch (e) {
            console.log(e);
        }
        try {
            // 10
            let res = await getData(getAddItemTransmissions("get", 500, 0));
            console.log("transmission", res.data);
            if (res.data) {
                setTransmissions(res.data);
                updatePercentage();
            }
        } catch (e) {
            console.log(e);
        }

    }
    const getModels = async (id) => {
        setModels([]);
        try {
            // 10
            let res = await getData(getAddItemModel("get", 500, 0, id));
            console.log("transmission", res.data);
            if (res.data) {
                setModels(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getPageData();
        getCategories();
        getMenufecturers(selectedType);
        getLocation();
        // window.genralPageLoad();
    }, []);
    return (
        <div className="add-product">
            <main>
                {/* <!-- Breadcrumbs --> */}
                <div className="b-crumbs-wrap">
                    <div className="cont b-crumbs">
                        <ul>
                            <li>
                                <Link to="/">Motor</Link>
                            </li>
                            <li>
                                <span>Pesquisar item</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="cont maincont">

                    <h1><span>{selectedType == SERVICES ? "Serviços" : "ANÚNCIO"}</span></h1>
                    {/* <span className="maincont-line1 maincont-line12"></span>
                    <span className="maincont-line2 maincont-line22"></span> */}
                    <Modal visible={isAddingImage} footer={null} width={200} closable={false} okButtonProps={{ style: { display: 'none' } }} cancelButtonProps={{ style: { display: 'none' } }}>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <Spinner className="mb-1" size={30} animation="border" />
                            {/* <hr/> */}
                            <label>{loadingText}</label>
                        </div>
                    </Modal>
                    {/* <!-- Contacts - start --> */}
                    <div className="card p-4">

                        <div>
                            <h3>Pesquisar {selectedType == SERVICES ? "service" : "item"}</h3>
                            {percentage != 100 && <Progress percent={percentage} />}

                            {<form action="#" className="row">
                                <div>
                                    <div className="col-12 d-flex align-items-center justify-content-center category-img-container">
                                        {categories.isLoading ? <Spin indicator={antIcon} /> : categories.data.map((val, i) => {
                                            return <div className="d-flex  flex-column justify-content-center align-items-center " onClick={() => { selectCategory(val.category_id) }} style={selectedType == val.category_id ? { background: "#ffb6a5", paddingTop: 10, borderRadius: 10 } : { color: "#212121" }}>
                                                <div className="frontcategs-img">
                                                    <img src={BASE_URL + val.category_image} style={selectedType == val.category_id ? { filter: "grayscale(100%)" } : { filter: "grayscale(0%)" }} alt="" onError={(e) => { e.target.src = "./assets/images/placeholder.jpeg" }} />
                                                </div>
                                                <div className="text-center">
                                                    <label>{val.category_name}</label>
                                                </div>

                                            </div>
                                        })}


                                    </div>
                                </div>

                                <div className="col-12">
                                    <h6 style={{color:"#d70304"}}>Nota: Por favor, selecione a categoria primeiro. E os produtos de campo selecionados serão filtrados de acordo</h6>
                                </div>
                               { selectedType != SERVICES && <div className="form-group col-6">
                                    <label className="mb-1 mt-1 heading">Marca</label>
                                    <div className="col-12">
                                        <Select
                                            showSearch
                                            size="middle"
                                            placeholder="Selecione o fabricante"
                                            optionFilterProp="children"
                                            style={{ width: "100%" }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            value={itemForm.manufacturer_id}
                                            onChange={(e) => {
                                                setValue("manufacturer_id", e);
                                                getModels(e);
                                            }}
                                        >

                                            <Option value="1">Não identificado</Option>
                                            {
                                                menufecturers.map((val, i) => {
                                                    return <Option value={val.id} key={"manufec" + i}>{val.name}</Option>
                                                })
                                            }
                                            <Option value="-1">Outros</Option>
                                        </Select>
                                    </div>
                                </div>}
                                {selectedType != NAUTICAL && selectedType != BOATS && selectedType != SERVICES && <div className="form-group col-6">
                                    <label className="mb-1 mt-1 heading">Para*</label>
                                    <div className="col-12">
                                        <Select
                                            showSearch
                                            placeholder="Vender/Alugar"
                                            optionFilterProp="children"
                                            style={{ width: "100%" }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            value={itemForm.sell_type}
                                            onChange={(e) => {
                                                setValue("sell_type", e);
                                            }}
                                        >
                                            <Option value="Rent">Aluguel</Option>
                                            <Option value="Sell">Sell</Option>
                                        </Select>
                                    </div>
                                </div>}
                               { selectedType != SERVICES && <div className="form-group col-6">
                                    <label className="mb-1 mt-1 heading">Modelo</label>
                                    <div className="col-12">
                                        <Select
                                            showSearch
                                            placeholder="Selecionar modelo"
                                            optionFilterProp="children"
                                            style={{ width: "100%" }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            value={itemForm.model_id}
                                            onChange={(e) => {
                                                setValue("model_id", e);
                                            }}
                                        >
                                            <Option value="">Não Identificado</Option>
                                            {
                                                models.map((val, i) => {
                                                    return <Option value={val.id} key={"model" + i}>{val.name}</Option>
                                                })
                                            }
                                            <Option value="-1">Outros</Option>
                                        </Select>
                                    </div>
                                </div>}
                                {!chkBOPN(selectedType)&&<div className="form-group col-6">
                                    <label className="mb-1 mt-1 heading">Número da placa</label>
                                    <Input size="medium" placeholder="Número da placa" prefix={<FaBorderAll />} value={itemForm.plate_number} onChange={(e) => { setValue('plate_number', e.target.value) }} />
                                </div>}
                                {!chkOPN(selectedType)&&<div className="form-group col-6">
                                    <label className="mb-1 mt-1 heading">Poder do motor</label> 
                                    <Input size="medium" placeholder="Poder do motor" prefix={<FaMeteor />} value={itemForm.engine_power} onChange={(e) => { setValue('engine_power', e.target.value) }} />
                                </div>}
                                {!chkOPN(selectedType)&&<div className="form-group col-6">
                                    <label className="mb-1 mt-1 heading">Caixa</label>
                                    {/* Select */}
                                    <div className="col-12">
                                        <Select
                                            showSearch
                                            placeholder="Selecionar Caixa"
                                            optionFilterProp="children"
                                            style={{ width: "100%" }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            value={itemForm.transmission_id}
                                            onChange={(e) => {
                                                setValue("transmission_id", e);
                                            }}
                                        >
                                            <Option value="">Não Identificado</Option>
                                            {
                                                transmissions.map((val, i) => {
                                                    return <Option value={val.id} key={"transmission" + i}>{val.name}</Option>
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>}
                               
                                { selectedType != SERVICES && <div className="form-group col-6">
                                    <label className="mb-1 mt-1 heading">Ano</label>
                                    <Input size="medium" placeholder="Ano" value={itemForm.year} onChange={(e) => { setValue('year', e.target.value) }} />
                                </div>}
                                {!chkOPN(selectedType)&&<div className="form-group col-6">
                                    <label className="mb-1 mt-1 heading">Cor</label>
                                    <div className="col-12">
                                        <Select
                                            showSearch
                                            placeholder="Selecione a cor"
                                            optionFilterProp="children"
                                            style={{ width: "100%" }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            value={itemForm.color_id}
                                            onChange={(e) => {
                                                setValue("color_id", e);
                                            }}
                                        >
                                            <Option value="">Não Identificado</Option>
                                            {
                                                colors.map((val, i) => {
                                                    return <Option value={val.id} key={"color" + i}>{val.color_value}</Option>
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>}
                               {!chkOPN(selectedType)&& <div className="form-group col-6">
                                    <label className="mb-1 mt-1 heading">Tipo de combustíve</label>
                                    <div className="col-12">
                                        <Select
                                            showSearch
                                            placeholder="Selecione o tipo de combustível"
                                            optionFilterProp="children"
                                            style={{ width: "100%" }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            value={itemForm.fuel_type_id}
                                            onChange={(e) => {
                                                setValue("fuel_type_id", e);
                                            }}
                                        >
                                            <Option value="">Não identificado</Option>
                                            {
                                                fueltypes.map((val, i) => {
                                                    return <Option value={val.id} key={"fueltype" + i}>{val.fuel_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>}

                                {itemForm.category_id == SERVICES && <div className="form-group col-6 pt-2">
                                            
                                            <div className="col-12">
                                            <label className="search-label">Área de serviço</label>
                                            <Select
                                                        showSearch
                                                        size="middle"
                                                        placeholder={"Selecione a área de serviço"}
                                                        optionFilterProp="children"
                                                        style={{ width: "100%" }}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                        value={itemForm.service_area}
                                                        onChange={(e) => {
                                                            setValue("service_area", e);
                                                        }}
                                                    >
                                                        {
                                                            serviceAreas.map((val) => {
                                                                return <Option value={val.key}>{val.value}</Option>
                                                            })
                                                        }
                                                    </Select>
                                            </div>
                                        </div>}

                                {<div className="form-group col-6 pt-2">
                        {/* <label className="mb-1 mt-1 heading">Manufecturer</label> */}
                        <div className="col-12">
                            <label className="search-label">Estado</label>
                            <Select
                                showSearch
                                // size="middle"
                                placeholder="Selecionar Estado"
                                optionFilterProp="children"
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                value={itemForm.item_location_id}

                                onChange={(e) => {
                                    setValue('item_location_id', e);
                                    //getFilterCount();
                                }}
                            >
                                <Option value="">Selecionar Estado</Option>
                                {
                                    props.location.data.map((val, i) => {
                                        return <Option value={val.id} key={"item_location_" + i}>
                                            {val.name}
                                        </Option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    }

                   
                              
                                {selectedType != SERVICES &&<div className="form-group col-6">
                                    <label className="mb-1 mt-1 heading">Estado</label>
                                    <div className="col-12">
                                        <Select
                                            showSearch
                                            placeholder="Selecionar condições"
                                            optionFilterProp="children"
                                            style={{ width: "100%" }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            value={itemForm.condition_of_item_id}
                                            onChange={(e) => {
                                                setValue("condition_of_item_id", e);
                                            }}
                                        >
                                            <Option value="">Não identificado</Option>
                                            {
                                                conditions.map((val, i) => {
                                                    return <Option value={val.id} key={"conditions" + i}>{val.name}</Option>
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>}
                                <div className="form-group col-12 row">
                                
                                </div>
                               
                                <div className="form-group col-12 mt-4">
                                    <Tooltip title="Submit" >
                                        <Button type="primary" onClick={() => { submitForm() }} size="large" shape="round" icon={<FaSearch style={{marginRight:10}} />}>Procurar</Button>
                                    </Tooltip>
                                </div>

                            </form>}

                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

const mapLocationWithProps = (state) => {
    return {
        location: state.location
    }
}
const mapLocationActionWithProps = (dispatch) => {
    return {
        updateLocation: (_data) => dispatch(updateLocation(_data)),
    }
}

export default compose(
    connect(mapLocationWithProps, mapLocationActionWithProps),
)(SearchProducts);
